.dashboard-container {
  margin-left: 200px;
  margin-top: 130px;
  display: flex;
  /* width: calc(100vw - 215px);
  max-width: 1400px; */
}
.dashboard-left {
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 430px);

  @media screen and (max-width: 980px) {    
    width: calc(100vw - 200px);
  }
}
.coupons-offers-dashboard-left {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%
}

.rightsidebar-container {
  display: flex;
  /* height: calc(100vh - 130px); */
  /* z-index: 9; */
  background-color: transparent;
  /* top: 130px; */
  /* right: 0; */
  /* position: fixed; */
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  /* scrollbar-width: none; */
  /* padding-left: 10px; */

  @media screen and (max-width: 980px) {    
    display: none;
  }
}

.tabs-container {
  display: flex;
  flex-direction: row;
  gap: 3px;
  overflow-x: scroll;
  scrollbar-width: none;
}
.coupons-offers-tabs-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 7px;
  position: fixed;
  top: 130px;
  left: 200px;
  overflow-x: scroll;
  scrollbar-width: none;
  width: calc(100% - 200px); 
  background-color: #ffffff;
  z-index: 9;
  padding: 20px 0;
  /* max-width: 1400px; */

  @media screen and (max-width:600px) {
    width: 100%;
    top: 80px;
    left: 0;
    justify-content: flex-start;
    padding: 10px 0;
  }
}

.tab-button {
  padding: 20px;
  cursor: pointer;
  border: none;
  text-align: center;
  align-items: center;
  background-color: #e5e8f1;
  color: #464b59;
  display: flex;
  gap: 20px;
  justify-content: center;
  outline: none;
  border: none;
}
.tabs-text {
  font-weight: 500;
  font-size: 14px;
  width: max-content;
}

.tab-button.active {
  background-color: #e13535;
  color: #ffffff;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}

.content {
  background-color: #ffffff;
  scrollbar-width: none;
  align-items: center;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .dashboard-container {
    margin-left: 0px;
    margin-top: 95px;
    display: flex;
    width: 100vw;
    height: 100%;
  }
  .dashboard-left {
    padding: 5px;
    width: 100vw;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-bottom: 100px;
  }
  .coupons-offers-dashboard-left {
    padding: 5px;
    width: 100vw;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-bottom: 100px;

    @media screen and (max-width : 600px) {
      padding: 0 15px;
    }
  }
  .rightsidebar-container {
    display: none;
  }
}
