.verify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/web\ \ login\ background.jpg') no-repeat center
    center fixed;
  background-size: cover;
  background-position: center;
}

.back-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(44, 49, 66, 0.89) 25.52%,
    rgba(44, 49, 66, 0.5963) 100%
  );
  z-index: 100;
}

.verify-card {
  max-width: 425px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  z-index: 101;
}

.verify-card .form-group .error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -5px; 
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 4px;
}

.verify-card h2 {
  margin: 0 0 10px;
}

.verify-card p {
  color: #555;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 49px;
  margin-top: 30px;
}

.verify-card .label-text {
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 1%;
  margin-bottom: 6px;
}

.verify-card .form-group {
  position: relative;
  margin: 0 0 20px;
}

.form-group .input-error {
  border: 1px solid red;
}

.verify-card input {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  border-radius: 8px;
  outline: none;
}
.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #d32f2f;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #c62828;
}

.resend-section {
  font-size: 14px;
  margin-top: 10px;
}

.resend-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.resend-button:hover {
  color: #0056b3;
}

.mobile-verify-account-header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
  min-height: 25vh;
}
.mobile-verify-account-header .verify-account-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding-inline: 25px;
}

.verify-account-title .left-side-arrow {
  width: 14.71px;
  height: 25px;
}

.verify-account-text {
  margin-top: 20px;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  color: #a4abc4;
}

.verify-account-text p {
  padding-inline: 25px;
}

.verify-account-title h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.5%;
  text-align: center;
  color: #fff;
  margin: 0;
}

@media (max-width: 576px) {
  .verify-container {
    display: flex;
    flex-direction: column;
    height: 30%;
  }
  .verify-container .verify-card {
    width: 100%;
    max-width: 100%;
    border-radius: 18px 18px 0px 0px;
    position: sticky;
    top: 50%;
    min-height: 75vh;
    height: 100%;
  }
}
