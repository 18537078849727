.order-details-container .order-detail-model{
  width: calc(100% - 450px);
  margin: 0 250px 0 200px;  
}

.order-detail-model .container {
    max-width: 800px;
    width: 100%;
    margin: 100px auto 20px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
}

  
.container .card {
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card .order-id {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }

  .card .order-basic-information{
    display: flex;
    justify-content: space-between;
  }

  .order-basic-information .left-part, .order-basic-information .right-part{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .right-part .order-date{
    font-size: 14px;
    color: #555;
  }
  
  .restaurant, .restaurant-details, .status {
    font-size: 14px;
    color: #555;
  }

  .customer-details-container .customer, .customer-details-container .customer-details{
    font-size: 14px;
    color: #555;
  }
  
  .container .items {
    margin-bottom: 15px;
  }
  
  .items .item-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    gap: 10px;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .item-card .item-image-name-container{
    display: flex;
    gap: 15px;
  }
  
  .item-image-name-container .menu-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
  }
  
  .item-image-name-container .item-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .item-image-name-container .item-quantity, .item-image-name-container .item-price {
    font-size: 14px;
    color: #777;
  }

  .item-image-name-container .item-description{
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .item-card .item-total-price, .item-description .description-heading{
    font-size: 14px;
    color: #777;
  }

 .item-description .description-value{
    font-size: 12px;
    color: #777;
  }

  .container .summary-card {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-card .summary-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.summary-card .summary-item:last-child {
    border-bottom: none;
}

.summary-item .heading {
    font-weight: bold;
    color: #333;
}

.summary-item .value {
    color: #555;
}

.total .heading {
    font-size: 18px;
    font-weight: bold;
}

.total .value {
    font-size: 18px;
    font-weight: bold;
    color: #d9534f;
}

  .invoice-content {
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .container .download-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background: #ff5722;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
  }
  
  .container .download-btn:hover {
    background: #e64a19;
  }

  @media screen and (max-width: 980px) {
    .order-details-container .order-detail-model{
      width: calc(100% - 200px);
      margin: 0 0 0 200px;  
    }

    .order-detail-model .container {
      margin: 100px auto 20px;
    }

  }

  @media screen and (max-width: 600px) {
    .order-details-container .order-detail-model{
      width: 100%;
      margin: 0;
    }

    .order-detail-model .container {
      margin: 20px auto 80px;
    }

    .order-mobile-topmenubar{
      height: 0;
      margin-top: 55px;
    }

  }
  