.popup-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  overflow: hidden;
  position: relative;
}

.coupen-offer-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  margin-bottom: 10px;

  @media screen and (max-width : 600px) {
    margin-bottom: 5px;
  }
}
.coupon-form-container {
  overflow-y: auto;
}

.vendor-coupon-offer-button{
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-coupon-offer-field-txt{
  font-family: Poppins;
  position: sticky;
  bottom: 0;
  background-color: white;
  text-align: left;
  color: #444444;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.coupen-offer-heading-text{
  font-size: 24px;
  font-weight: 600;
  color: #2C3142;

  @media screen and (max-width : 600px) {
    font-size: 20px;
  }
}

.coupon-form-group {
  display: flex;  
  flex-direction: column;
  min-height: 70px;
}

.coupon-form-group label {
  display: block;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 14px;
}

.coupon-form-group input {
  width: 100%;
  height: 35px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  outline: none;
  display: flex;
}
.generate-coupon-code {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 25px;
  font-weight: 600;
  font-size: 14px;
}

.coupon-form-group .generate-coupon-code input {
  margin-left: 10px;
}

.coupon-form-group .css-k4qjio-MuiFormHelperText-root{
  margin: 0;
  font-size: 12px;
  line-height: normal;
}

.generate-coupon-code .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root,
.no-boundries-label-and-checkbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
}

.generate-coupon-code{
  gap: 50px;
}

.checkbox-auto-generate {
  width: 20px;
  height: 30px;
}

.generate-coupon-code .css-j204z7-MuiFormControlLabel-root,
.no-boundries-label-and-checkbox .css-j204z7-MuiFormControlLabel-root{
margin: 0;
}

.no-boundries-label-and-checkbox .css-ahj2mt-MuiTypography-root,
.generate-coupon-code .css-ahj2mt-MuiTypography-root{
  width: 100%;
  font-size: 14px;
}


.date-mis-align-coupon {
  text-align: center;
}