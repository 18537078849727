.right-sidebar-container {
  /* width: 100%; */
  width: 250px;
  background: var(--color2);
}
#line {
  width: 1px;
  height: 790px;
  /* height:100vw; */
  background-color: var(--right-sidebar-verticle-line);

  z-index: 1;
}

.right-item-container {
  display: flex;
  z-index: 1;
  position: fixed;
  top: 90px;
  right: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 90%;
  background-color: var(--color2);
}

.right-content {
  flex-direction: column;
}
.customer-interest-container {
  width: 197px;
  /* height: 52px; */
  margin-left: 30px;
}

.customer-interest-title {
  font-family: var(--font-family);
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
}

.customer-items-select {
  display: flex;
  flex-direction: column;
  /* gap: 6px; */
}

.customer-interest-items {
  display: flex;
  gap: 10px;
  margin-left: 30px;
}

.customer-interest-input {
  width: fixed (22px);
  height: fixed (22px);
  border-radius: 6px;
  border: 1px;
  color: var(--color2);
}

.item-name {
  font-family: var(--font-family-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--item-name-color);
}

#horizantal-line {
  width: 230px;
  height: 1px;
  border-bottom: 1px dashed var(--border-color);
  /* background-color: var(--border-color); */
  margin-left: 10px;
}

.best-rated-container {
  width: 228px;
  /* height: 50.29px; */
  border-radius: 12px, 12px, 0px, 0px;
}

.rated-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.view-all {
  font-family: var(--font-family);
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--view-all-color);
}

.restaurants-list {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 100px;
}

.restaurant-card {
  width: 100px;
  height: 120.57px;
  border-radius: 12px;
  border: 1px;
  border: 1px solid var(--restaurant-card-border-color);
  margin-left: 20px;
}

.restaurant-image {
  width: 100%;
}
.restaruant-name {
  font-family: var(--font-family);
  font-size: 9px;
  font-weight: 700;
  line-height: 9px;
  color: var(--restaruant-name-color);
  text-align: center;
}

.restaruant-count {
  font-family: var(--font-family);
  font-size: 8px;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 8px;
}

#horizantal-line2 {
  width: 230px;
  height: 1px;
  border-bottom: 1px dashed var(--border-color);
  /* background-color: var(--border-color); */
  margin-left: 10px;
  margin-top: 10px;
}

.app-download-container {
  width: 229px;
  border-radius: 12px;
  background-color: var(--border-and-bg-color);
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.download-app-title {
  width: 205px;
  /* height: 52px; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.06em;
  text-align: center;
  margin-left: 15px;
  margin-top: 10px;
}

.app-badge-icon {
  display: flex;
  justify-content: center;
}

.laughing-image {
  width: 188.65px;
  /* height: 148.23px; */
  margin-left: 25px;
}

@media (max-width: 980px) {
  .right-sidebar-container {
    display: none;
  }

  .right-item-container {
    display: none;
  }
}
