.main-container {
  gap: 20px;
  margin-top: 80px;
  width: calc(100% - 450px);
  margin-left: 200px;
  margin-right: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  width: 400px;
  height: 100%;
  object-fit: contain;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
}

.video-container::-webkit-scrollbar {
  display: none;
}

.video-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.reels-dashboard {
  display: flex;
  flex-direction: column;
}

.topmenubar {
  display: flex;
  /* flex-direction: row; */
}

.top-bar-mobile {
  width: 100%;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .main-container {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  .video-container {
    height: 488px;
    width: 370px;
  }
}

@media screen and (max-width: 981px) {
  .main-container {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .reels-dashboard {
    height: 90vh; 
    display: flex;
    flex-direction: column-reverse;

  }

  .side-bar-reel {
    position: absolute;
    top: 0;
  }

  .main-container {
    margin: 0;
    width: 100%;
  }
  .video-container {
    position: relative;
    width: 100vw; 
    height: 70vh; 
    object-fit: contain; 
    overflow: scroll;
    scroll-snap-type: y mandatory;
    margin-top: 70px;
    margin-left: 0;

    @media (max-width: 600px) {
      overflow: visible;
    }
  }

  .top-bar-mobile {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
  }

  .topmenubar {
    display: flex;
    height: 100vh;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

.default-main-container {
  display: flex;
}

.block-main-container {
  display: block !important;
}
