.loader-div {
    display: inline !important;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    cursor: pointer;
}