body {
  font-family: "Poppins", sans-serif;
}

.coupon-container {
  max-width: 100%;
  overflow-x: hidden;
}

.VendorCouponsOffers{
  margin-top: 85px;

  @media screen and (max-width:600px) {
    margin-top: 65px;
  }
}

.coupons-heading {
  text-align: center;
  color: #2c3142;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 110px;

  @media screen and (max-width:600px) {
    margin-left: 0;
  }
}

.add-button-coupen-offer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width:600px) {
    margin-bottom: 10px;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 15px;
}

.table-container .css-11952q5-MuiTableCell-root{
  padding: 7px;
}

.table-container .css-1ex1afd-MuiTableCell-root{
  padding: 10px;
}

.coupon-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #d1d5db; 
}

.coupon-table th {
  background-color: #e5e8f1;
  font-weight: 600;
  padding: 12px;
  text-align: left;
  font-size: 15px;
  border: 1px solid #d1d5db; 
  color: #464b59; 
}

.coupon-table td {
  padding: 12px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #464b59; 
  border: 1px solid #d1d5db;
}

.coupon-table tbody tr:hover {
  background-color: #fcfcfcdd;
}

.expired {
  background-color: #fef2f2 !important;
  color: #dc2626 !important;
  font-weight: 600;
}

@media (max-width: 768px) {
  .coupon-table td, .coupon-table th {
    padding: 8px;
    font-size: 13px;
  }

  .coupon-table {
    border: none;
    overflow-x: auto;
    display: block;
  }

  .coupon-container .css-1tf1a8c-MuiPaper-root-MuiTableContainer-root {
    height: auto;
    overflow-x: auto;
  }

  .coupon-container .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 120px;
    margin-top: 5px;
  }
}

.coupon-button,
.coupon-edit-button,
.coupon-delete-button {
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
}

.coupon-edit-button {
  background-color: #218838;
}

.coupon-edit-button:hover {
  background-color: #218838;
}

.coupon-delete-button {
  background-color: #e13535;
  margin-left: 3px;
}

.coupon-delete-button:hover {
  background-color: #e13535;
}

.coupon-item.expired {
  color: grey;
}

/* Modal styles */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #2c3142cc;
}

.modal-content {
  position: absolute;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  width: 70%;
  max-width: 400px;
  top: 20%;
  left: 35%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-container-coupons {
  height: 35px;
  width: 125px;
  border: 1.5px solid #E2E8F0;
  border-radius: 5px;
  text-indent: 10px;
  outline: none;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  color: #333;
}

.input-container-coupons::placeholder {
  color: #4A4A4A; 
  opacity: 1; 
}

.input-container-coupons:focus {
  border-color: #4299E1; 
  box-shadow: 0 0 5px rgba(66, 153, 225, 0.5);
}

.coupons-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
}
.filters-container-1 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.filters-container-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  
}

.search-icon-coupons {
  background-color: #e13535;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    font-size: 14px;
}

.clear-btn-coupons {
  width: 80px;
  height: 35px;
  padding: 5px;
  background-color: #e13535;
  color: #ffffff;
  border-radius: 6px;
  border: none;
}

.no-coupons {
  text-align: center;
  margin-top: 10px;
}

.edit-and-delete-icons-container {
  display: flex;
  gap: 10px;
}

.edit-icon-coupon {
  cursor: pointer;
}

.delete-icon-coupon {
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .modal-content {
    top: 25%;
    left: 5%;
    width: 90%;
  }
}


.coupon-container .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 150px;
  margin-top: 5px;
}

.coupon-container .css-1tf1a8c-MuiPaper-root-MuiTableContainer-root {
  height: auto;
}