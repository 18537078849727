.business-hours-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

}


.vertical-line {
  position: fixed;
  margin-left: 200px;
  width: 1px;
  height: 100%;
  background-color: #eaeaea;
  z-index: 3;
}
.rightsidebar-main-cont {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.container1 {
  width: 190px;
  height: 140px;
  background-color: #2c3142;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 0px 12px 0px #00000029;
}
.container1-text-1 {
  padding-top: 15px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 32px;
}
.container1-text-2 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}
.container2 {
  display: flex;
  flex-direction: column;
  width: 190px;
  padding: 10px;
  gap: 10px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px #00000040;
}
.business-hours-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: #444444;
}
.days-container {
  display: flex;
  gap: 10px;
  /* width: 200px; */
  color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
}
.day {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background-color: #2C3142; */
  background-color: #f0f0f0;
  color: #2c3142;
  text-align: center;
  padding-top: 6px;
}
.day.selected {
  background-color: #2c3142;
  color: #ffffff;
}
.day-txt {
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
}
.time-range-cont {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  gap: 5px;
}

.from-cont,
.to-cont {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 10px;
}

.from-text,
.to-text {
  font-family: Poppins;
  color: #e13535;
  font-size: 12px;
  font-weight: 600;
}

.custom-select {
  width: 84px;
  padding: 6px;
  font-size: 11px;
  border-radius: 5px;
  outline: none;
  border: 2.5px solid var(--Neutral-n200, #e2e8f0);
  background: #ffffff;
  background-repeat: 8px;
  color: #2c3142;

  /* box-shadow: 0px 0px 5px 0px #00000040; */
}
.vendor-business-hours-submit-btn {
  width: 170px;
  height: 30px;
  border-radius: 6px;
  border: none;
  background-color: #e13535;
  color: #ffffff;
  font-size: 12px;
  margin-top: 10px;
  font-weight: 400;
}
.timings-main-container {
  display: flex;
  flex-direction: column;
}
.neworders-right-dotted-line {
  border: 1px #cbd5e1 dotted;
}
.timings-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.timings-left {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.day-name {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  color: #2c3142;
}
.time-text {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  color: #2c3142;
}

@media screen and (max-width: 600px) {
  .vertical-line {
    display: none;
  }
  .rightsidebar-main-cont {
    display: none;
  }
}
