.restaurant-page-container {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restaurant-main-container {
  width: 400px;
}

.restaurant-logo-cover-img {
  position: relative;
}

.restaurant-cover-image {
  width: 400px;
  height: 207px;
  border-radius: 0px 0px 20px 20px;
}
.restaurant-logo {
  position: absolute;
  top: 0;
  left: 20px;
}

.restaurant-name-in-rest-page {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: var(--color1);
}
.star-img-in-res-page {
  width: 17.41px;
  height: 17.41px;
}

.restaurant-name-star-rating-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}
.restaurant-star-rating-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.restaurant-rating-count {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: var(--color1);
}

.restaurant-reviews {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: var(--color1);
}

.restaurant-description {
  width: 100%;
  margin-top: 10px;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 400;
  color: #6d7383;
  padding: 0 30px;
}

.restaurant-page-horizantal-line {
  width: 100%;
  height: 1px;
  background-color: #fafcff;
  border: 1px solid #e5e8f1;
  margin-top: 20px;
}
.res-page-offers-for-heading {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  color: var(--blk-color5);
  margin-top: 20px;
}

.offers-main-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  gap: 25px;
  overflow-x: auto;
}

.offers-main-container::-webkit-scrollbar {
  display: none;
}

.res-page-offer-container {
  display: flex;
  flex-direction: column;
}
.offers-img-container {
  width: 84.52px;
  height: 84.52px;
}

.res-page-offer-img {
  width: 84.52px;
  height: 84.52px;
  border-radius: 12px;
  object-fit: cover;
}

.res-page-offer-percentage-container {
  position: relative;
  top: -88.5px;
  left: 34px;
  width: 40px;
  height: 28px;
  background-color: var(--color4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
}
.res-page-offer-percentage {
  color: var(--color2);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
}
.res-page-offer-name {
  font-family: Inter;
  font-size: 10.51px;
  font-weight: 600;
  color: var(--restaurant-name-color);
}

.filter-text-in-res-page {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  color: #50555c;
}

.filters {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  white-space: nowrap;
  height: 54px;
  background-color: #fafcff;
  border-top: 1px solid #e5e8f1;
  border-bottom: 1px solid #e5e8f1;
  margin-top: 20px;
  min-width: max-content;
  width: 100%;
  flex-wrap: nowrap;

  @media (max-width: 400px) {
    width: 100vw;
    justify-content: normal;
  }
}

.bg-css-res-page-container {
  width: 98px;
  height: 29px;
  background-color: var(--color2);
  border-radius: 22px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #50555c;
}

.bg-css-res-page {
  width: 98px;
  height: 29px;
  background-color: var(--color2);
  border-radius: 22px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  display: flex;

  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #50555c;
}

@media only screen and (max-width: 600px) {
  /* .topmenubar-restaurant-page {
            height: 100vh;
        } */
  .topbar-restaurant-page {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .restaurant-page-container {
    position: absolute;
    top: 60px;
    left: 0;
  }
  .restaurant-cover-image {
    width: 100vw;
  }

  .restaurant-description {
    width: 100vw;
  }
  .restaurant-name-star-rating-container {
    width: 100vw;
  }

  .restaurant-main-container {
    width: 100vw;
    margin-bottom: 85px;
  }
  .offers-main-container {
    width: 100vw;
    padding: 5px;
  }
  .scrollable-container {
    width: 100vw;
    margin: 0 5px;
  }
  .restaurant-page-horizantal-line {
    width: 100vw;
  }
  .filters {
    width: 100vw;
    overflow-x: scroll;
    
    .filter-text{
      gap: 10px;

      .filter-icon{
       height: 17.84px;
       width: 19.47px;

       img{
          height: 100%;
          width: 100%;
       }
    }
    }
  }
}
