.profile-image-container{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.profile-image-container img{
    object-fit: contain;
    border: 2px solid rgb(225, 53, 53);
}