.landing-page-container {
  width: 100%;
  height: 100vh;
  background: url('./../assets/landing\ page.jpg') no-repeat;
  background-size: cover;
  position: relative;
}

.landingpage-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(44, 49, 66, 0.89) 25.52%,
    rgba(44, 49, 66, 0.5963) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.omf-logo-title-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.omf-logo-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page-quote {
  width: 308px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.3px;
  text-align: center;
  color: #a4abc4;
}

.landing-page-login-btn {
  width: 355px;
  padding: 14px;
  border-radius: 6px;
  background-color: var(--color4);
  border: none;
  color: var(--color2);
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  cursor: pointer;
}

.landing-page-signup-btn,
.landing-page-partner-btn {
  width: 355px;
  padding: 14px;
  border-radius: 6px;
  background-color: var(--color2);
  border: none;
  color: var(--blk-color5);
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  cursor: pointer;
}

.skip-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.skip-text {
  color: var(--color2);
}

/* Responsive Design */
/* @media (max-width: 768px) {
  .omf-logo-title-container {
    gap: 15px;
  }
  .omf-logo-title {
    width: 233px;
    height: 183px;
  }
} */

@media (max-width: 425px) {
  .omf-logo-title-container {
    gap: 20px;
  }
  .landing-page-container {
    padding: 20px;
  }
  .omf-logo-title-container {
    width: 100%;
  }
  .landing-page-quote {
    font-size: 20px;
    line-height: 24.3px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .landing-page-login-btn,
  .landing-page-signup-btn,
  .landing-page-partner-btn {
    width: 90%;
  }
  .skip-container {
    margin-top: 25px;
  }
}
