.main-title-container {
  display: flex;
  gap: 20px;
  align-items: center;

  background-color: var(--color2);
}

.notification-container-overall {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-title-notification {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: var(--color1);
}

.mark-all-as-read {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  color: rgb(110, 110, 249);
  cursor: pointer;
}

.notification-messages-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  height: 100%;
}

.notification-messages-main-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.notification-messages-main-container::-webkit-scrollbar-thumb {
  /* background-color: #888; Color of the scrollbar thumb */
  background: linear-gradient(transparent, #cecdcd);
  border-radius: 5px; /* Rounded corners */
}

.notification-messages-main-container::-webkit-scrollbar-thumb:hover {
  /* background-color: #555; Color of the scrollbar thumb on hover */
  background: linear-gradient(transparent, #555);
}

.title-time-and-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.svg-title-notification {
  display: flex;
  gap: 10px;
}
.time-date-notification {
  display: flex;
  gap: 10px;
}

.red-dot-in-notification {
  height: 10px;
  width: 10px;
}

.notification-title {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  color: var(--color1);
}
.time-in-notification {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  color: var(--color1);
}

.date-in-notification {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  color: var(--color1);
}

.notification-description {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  color: var(--notification-desc-color);
}

.horizantal-line-notification {
  margin-top: 10px;
  width: 100%;
  height: 1px;
  border: 1px dashed var(--notification-dashed-line-color);
}

.notification-close-icon {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .notification-messages-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    height: 400px;
  }
}
