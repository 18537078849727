.post-title-svg-icon-container {
  display: flex;
  justify-content: space-between;
}

.cross-icon-post-details path {
  fill: var(--color2);
  cursor: pointer;
}

.cross-svg-post-details {
  margin-left: 110%;
  margin-top: -20px;
}
.post-details-img {
  margin-top: 20px;
  width: 380px;
  height: 400px;
  object-fit: contain;
}

.reels-details-video {
  height: 300px;
  width: 300px;
  object-fit: contain;
}
.post-details-img-container {
  margin-left: -30px;

  height: 100%;
  /* overflow-y: scroll; */
}

.profile-and-name-posts {
  margin-top: -20px;
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.location-post-details {
  font-size: 8px;
  font-weight: 400;
}

.description-container-post-details {
  margin-top: 10px;
}
.user-description-post-details {
  /* width: 390px; */
  margin-left: 10px;
  font-size: 10px;
  font-weight: 400;
  font-family: var(--font-family);
}

.Comments-title-post-details {
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-family);
  text-align: center;
  margin-top: 10px;
}

.horizantal-line-post-details {
  margin-left: 50px;
  width: 250px;
  height: 1px;
  border: 1px solid var(--notification-dashed-line-color);
}

.horizantal-line-under-comments-post-details {
  width: 250px;
  height: 1px;
  border: 1px solid var(--notification-dashed-line-color);
  margin-top: 20px;
  margin-left: 50px;
}

/*comments*/
.comments-and-underline {
  display: flex;
  flex-direction: column;
}
.comments {
  height: 250px;
  overflow-y: scroll;
}

.comments::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.comments::-webkit-scrollbar-thumb {
  /* background-color: #888; Color of the scrollbar thumb */
  background: linear-gradient(transparent, #cecdcd);
  border-radius: 5px; /* Rounded corners */
}

.comments::-webkit-scrollbar-thumb:hover {
  /* background-color: #555; Color of the scrollbar thumb on hover */
  background: linear-gradient(transparent, #555);
}

.comments-main-container-profile {
  margin-top: 20px;
}

.profile-comments-container {
  margin-top: 10px;
  margin-left: 50px;
}
.post-details-img-container {
  display: flex;
  justify-content: space-around;
}
.profile-and-name {
  display: flex;
  gap: 20px;
  align-items: center;
}

.comment-profile-pic {
  height: 25px;
  width: 25px;
}

.user-comment-time1 {
  font-size: 10px;
}

.user-comment1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.comment-reply-like1 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.like-comment-share-svg-post-details {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.svg-post-details {
  height: 20px;
  width: 20px;
}

.svg-post-details path {
  fill: var(--blk-color5);
}

.svg-icons-and-order-btn-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.order-btn-post-details {
  border: none;
  width: 60px;
  height: 25px;
  background-color: var(--color4);
  color: var(--color2);
  border-radius: 6px;
  margin-top: 10px;
}

.mute-icon-post-details {
  position: absolute;
  left: 36%;
  bottom: 12%;
  z-index: 99;
  background-color: var(--color2);
  padding: 6px;
  border-radius: 50%;
}

.left-arrow-profile-screen {
  position: absolute;
  top: 50%;
  left: -10%;
  color: var(--color2);
  cursor: pointer;
}
.right-arrow-profile-screen {
  position: absolute;
  top: 50%;
  left: 105%;
  color: var(--color2);
  cursor: pointer;
}

/*input css */
.input-container-post-details {
  margin-top: 30px;
  width: 300px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 50px;
}
.comment-input-post-details {
  width: 200px;
  height: 30px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  border-radius: 6px;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--blk-color5);
  outline: none;
  text-indent: 10px;
}
.post-btn-post-details {
  border: none;
  color: blueviolet;
  background: none;
}

.picker-visible {
  display: block;
  margin-top: -500px;
}
.none {
  display: none;
}

.emoji-container {
  margin-top: -400px;
}

.post-btn-post-details.disabled {
  /* Styles for disabled button */
  color: rgb(180, 172, 172);
  cursor: not-allowed;
  /* Add any other styles you want for the disabled button */
}

.user-name-post-details-container {
  display: flex;
  justify-content: space-between;
}

.three-dots {
  font-size: 20px;
  margin-left: 250px;
  cursor: pointer;
}

.user-name-post-details {
  color: var(--blk-color5);
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .post-details-img-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 400px;
  }
  .user-name-desc-img-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .horizantal-line-post-details {
    width: 70%;
    height: 1px;
    border: 1px solid var(--notification-dashed-line-color);
    margin-left: 15%;
  }

  .horizantal-line-under-comments-post-details {
    width: 70%;
    height: 1px;
    border: 1px solid var(--notification-dashed-line-color);
    margin-top: 30px;
    margin-left: 15%;
  }

  .profile-comments-container {
    margin-left: 40%;
  }

  .comments {
    height: 300px;
    overflow-y: scroll;
  }
  .comments::-webkit-scrollbar {
    display: none;
  }
  .svg-icons-and-order-btn-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .input-container-post-details {
    margin-top: 30px;
    margin-left: 22%;
    width: 300px;
    display: flex;

    gap: 10px;
    align-items: center;
  }
  .emoji-container {
    margin-left: 25%;
  }
  .mute-icon-post-details {
    position: relative;
    left: -30%;
    top: -3%;
    z-index: 99;
    background-color: var(--color2);
    padding: 6px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .profile-and-name-posts {
    margin-top: -10px;
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .cross-svg-post-details {
    margin-left: 97%;
    margin-top: -20px;
  }
  .cross-icon-post-details path {
    fill: var(--blk-color5);
    cursor: pointer;
  }
  .post-details-img-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 600px;
    padding: 10px;
  }
  .user-name-desc-img-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .user-description-post-details {
    width: 400px;
    font-size: 10px;
    font-weight: 400;
    font-family: var(--font-family);
    margin-left: 55px;
  }
  .post-details-img {
    margin-top: -90px;
    width: 90%;
    height: 400px;
    object-fit: contain;
    margin-left: 20px;
  }
  .horizantal-line-post-details {
    width: 70%;
    height: 1px;
    border: 1px solid var(--notification-dashed-line-color);
    margin-left: 15%;
  }

  .horizantal-line-under-comments-post-details {
    width: 70%;
    height: 1px;
    border: 1px solid var(--notification-dashed-line-color);
    margin-top: 30px;
    margin-left: 15%;
  }
  .profile-comments-container {
    margin-left: 30%;
  }
  .svg-icons-and-order-btn-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .input-container-post-details {
    margin-top: 30px;
    margin-left: 12%;
    width: 300px;
    display: flex;

    gap: 10px;
    align-items: center;
  }
  .mute-icon-post-details {
    position: relative;
    left: 82%;
    top: -40px;
    z-index: 99;
    background-color: var(--color2);
    padding: 2px;
    border-radius: 50%;
  }
  .left-arrow-profile-screen {
    position: relative;
    top: 150px;
    left: -5px;
    color: var(--color2);
    cursor: pointer;
    background-color: var(--blk-color5);
    border-radius: 50%;
    padding: 5px;
  }

  .right-arrow-profile-screen {
    position: relative;
    top: 150px;
    left: 88%;
    color: var(--color2);
    cursor: pointer;
    background-color: var(--blk-color5);
    border-radius: 50%;
    padding: 5px;
  }
  .reels-details-video {
    height: 300px;
    width: 400px;
    object-fit: contain;
  }
  .Comments-title-post-details {
    margin-top: 20px;
  }
}
