.vendor-menu-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: var(--color1);
  height: calc(100vh - 130px);
  position: fixed;
  top: 130px;
  left: 0;
  z-index: 99;
}

.vendor-sidebar-scrollable {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
}

.vendor-sidebar-menu{
  display: flex;
  min-width: 25px;
}

.vendor-sidebar-scrollable::-webkit-scrollbar {
  width: 7px;
  display: none;
}

.vendor-sidebar-scrollable::-webkit-scrollbar-thumb {
  background: #cecdcd;
  border-radius: 5px; 
}

.vendor-omf-container {
  width: 200px;
  height: 82px;
  background-color: var(--omf-container-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.vendor-omf-svg-logo {
  width: 51.61px;
  height: 46.26px;
}
.vendor-omf-svg-title {
  width: 115.66px;
  height: 29.98px;
  margin-top: 14px;
}

.vendor-menu-items {
  display: flex;
  flex-direction: column;
}

ul li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.vendor-list-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 10px 10px 30px;
  height: 57px;

  @media screen and (max-width: 600px) {
    padding: 0;
    flex-direction: column; 
    gap: 0;
    height: none;
    width: 48px;
  }
}

.vendor-list-svg-icon {
  cursor: pointer;
  position: relative;
}

.plus-svg-icon {
  display: none;
}

.vendor-post-svg-icon {
  cursor: pointer;
}

.vendor-list-text {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  color: var(--list-text-color);
  cursor: pointer;
}

.vendor-text-container {
  width: 80%;
  margin-top: 120px;
  margin-left: 20%;
}
.vendor-text-in-sidemenu {
  width: 100%;
  overflow-wrap: break-word;
  font-family: var(--font-family);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  color: var(--text-in-sidemenu-color);
  text-align: center;
  padding: 0 20px 100px;
}

.vendor-login-svg-icon {
  width: 20.5px;
  margin-left: -16px;
  cursor: pointer;
}

.vendor-login-text {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--list-text-color);
  cursor: pointer;
}
.vendor-text-container {
  display: flex;
  justify-content: center;
  margin-left: 30px;
}

.vendor-icon-underline{
  display: none;
}

@media only screen and (max-width: 600px) {
  .vendor-login-cont {
    display: none;
  }

  .vendor-sidebar-scrollable {
    overflow-y: hidden;
  }
  .vendor-menu-container {
    width: 100%;
    height: 74px;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px -2px 7px 0px #00000033;
    position: fixed;
    top: 91%;
    left: 0;
    overflow-y: hidden;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .vendor-menu-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 0px;
    margin-top: 0;
    padding: 15px 14px;
    height: 100%;
  }
  .vendor-menu-items-mobile{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .vendor-list-svg-icon {
    height: 40px;
    width: 28px;
  }

  .vendor-post-svg-icon {
    display: none;
  }

  .vendor-plus-svg-icon {
    display: initial;
    background-color: var(--color2);
    height: 47px;
    width: 47px;
    padding: 17px;
    border-radius: 40px;
    margin-top: -4px;
  }

  .vendor-omf-container {
    display: none;
  }
  .vendor-text-container {
    display: none;
  }
  .vendor-list-text {
    font-weight: 400;
    font-size:9px;
    line-height: 13.5px;
    letter-spacing: 0%;
    text-align: center;
  }

  .vendor-post{
    display: none;
  }
}

.css-zza0ns-MuiBadge-badge{
  background-color: #e13535 !important;
}

.vendor-bottom-menu {
  display: none;
}

.menu-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.vendor-mobile-sidebar {
  position: fixed;
  top: 80px;
  left: -50%;
  width: 50%;
  height: calc(100vh - 139px);
  background-color: var(--color1);
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
  z-index: 100;
  overflow-y: scroll;
  scrollbar-width: none;
}

.vendor-mobile-sidebar.open {
  left: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: white;
  position: absolute;
  top: 15px;
  right: 25px;
}

@media only screen and (max-width: 600px) {
  .vendor-menu-container {
    display: none;
  }

  .vendor-list-container-mobile{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 10px 30px;
    height: 57px;
  }

  .vendor-list-text-mobile{
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    color: var(--list-text-color);
    cursor: pointer; 
  }
  .vendor-bottom-menu {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color1);
    padding: 10px 10px 2px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px -2px 7px 0px #00000033;
    z-index: 99;
  }
}
