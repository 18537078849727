@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&display=swap');

.form-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/web\ \ login\ background.jpg') no-repeat center
    center fixed;
  background-size: cover;
  font-family: 'Poppins', Arial, sans-serif;
}

.signup .back-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(44, 49, 66, 0.89) 25.52%,
    rgba(44, 49, 66, 0.5963) 100%
  );
  z-index: 100;
}

.form-card {
  width: 100%;
  max-width: 475px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  z-index: 101;
  border-radius: 10px;
  position: relative;
}

.form-header {
  text-align: center;
  margin-bottom: 1rem;
}

.form-header h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  font-weight: 400;
  margin-bottom: 0 !important;
}

.name-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.name-fields .form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
}

.name-fields .error-message {
  position: absolute; 
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 4px; 
  font-size: 12px;
  color: red;
}

.form-other-info .form-group {
  position: relative;
  margin: 0 0 20px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  color: #333;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1%;
}

.form-group input {
  width: 100%;
  padding: 12px 16px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  border-radius: 8px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1%;
  outline: none;
}

.form-group input.error {
  border-color: #dc3545;
}

.form-other-info .error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px; 
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 4px;
}

.password-input {
  position: relative;
}

.password-input input {
  padding: 0.75rem 2.4rem 0.75rem 0.75rem;
}

.password-toggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.password-toggle:hover {
  color: #333;
}

.signup-button {
  width: 100%;
  padding: 10px;
  background-color: #dc3545;
  color: white;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 30px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}

.signup-button:hover {
  background-color: #c82333;
}

.account-exists {
  width: 100%;
  padding: 10px;
  color: #1e293b;
  border: none;
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  margin-top: 15px;
}

.account-exists button:hover {
  text-decoration: underline;
}

.social-login {
  text-align: center;
}

.social-login p {
  font-family: 'Gabarito', serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1%;
  text-align: center;
  color: #64748b;
  margin-bottom: 10px;
  margin-top: 10px;
}

.social-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.social-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 1.7rem;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 20px;
}

.google-txt {
  font-size: 1.2rem;
}

.social-button:hover {
  background-color: #e9ecef;
}

.social-button img {
  width: 20px;
  height: 20px;
}

.mobile-signup-header {
  width: 100%;
  height: 30%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.mobile-signup-header .signup-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding-inline: 25px;
}

.signup-title .left-side-arrow {
  width: 14.71px;
  height: 25px;
}

.signup-text {
  margin-top: 20px;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  color: #a4abc4;
}

.signup-text p {
  padding-inline: 25px;
}

.signup-title h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.5%;
  text-align: center;
  color: #fff;
  margin: 0;
}

@media (max-width: 576px) {
  .form-container {
    display: flex;
    flex-direction: column;
    height: 30%;
    overflow-y: hidden;
  }
  .form-container .form-card {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin-top: 200px;
    min-height: 75vh;
    max-height: 100vh;
    position: sticky;
    top: 100%;
    border-radius: 18px 18px 0px 0px;
  }
}
