.sub-total-area {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.place-order-btn {
  margin: 40px 0 20px 0;
  width: 100%;
  height: 46.54px;
  background-color: var(--color4);
  color: var(--color2);
  font-weight: 600;

  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.instruction-btns {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin-top: 30px;
}

.cooking-inst-btn {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  color: var(--blk-color5);
  border-radius: 10px;
  border: none;
  padding: 14px;
  cursor: pointer;
}

.delivery-inst-btn {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  color: var(--blk-color5);
  border-radius: 10px;
  border: none;
  padding: 14px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .place-order-btn {
    margin-bottom: 100px;
  }

  .sub-total-area {
    display: flex;
    gap: 80px;
  }
}
