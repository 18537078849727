.star {
  cursor: pointer;
}

.food-name-and-rating .res-itm-name-and-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.res-itm-name-and-rating p{
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  color: var(--color1);
}

.close-icon{
  cursor: pointer;
}

.rate-order-model-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: var(--color2);
  padding: 20px;
  box-shadow: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rate-order-model-style .rating-item-container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rate-order-model-style .title-cross-svg-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.title-cross-svg-container .title {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: var(--color1);
}

.rating-item-container .food-heading {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: var(--color1);
}

.rating-item-container .food-name-and-rating {
  width: 100%;
  background-color: var(--bg-color2);
  padding: 10px;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 142px;
}

.delivery-rating .delivery-experience-title {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  color: var(--color1);
}

.rate-order-model-style .review-text-container{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.review-text-container .write-a-review {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: var(--color1);
}

.review-text-container .review-input {
  width: 100%;
  height: 85px;
  outline: none;
  background-color: var(--color2);
  border: none;
  padding: 10px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  border-radius: 8px;
}

.review-text-container .error-message {
  color: red;
  font-size: 10px;
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 4px;
}

.rate-order-model-style .submit-btn {
  width: 100%;
  height: 40px;
  background: var(--color4);
  color: var(--color2);
  border-radius: 6px;
  padding: 5px;
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .rate-order-model-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: var(--color2);
    padding: 15px;
    box-shadow: 24px;
  }
}
