.zoom-container {
  animation: zoomInOut 2s ease-in-out infinite alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.payment-fail-container {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.failed-order-message {
  width: 250px;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  color: var(--color1);
  text-align: center;
  margin-top: 30px;
}

.failed-span {
  font-family: Poppins;
  font-size: 29px;
  font-weight: 800;
  line-height: 42px;
  text-align: center;
  color: var(--color1);
}
.try-again-message {
  width: 356px;
  height: 50px;
  margin-top: 150px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: var(--color1);
  text-align: center;
}
.retry-btn {
  margin-top: 30px;
  width: 350px;
  height: 50px;
  border-radius: 4px;
  background-color: var(--color4);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: var(--color2);
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .payment-fail-container {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .retry-btn {
    margin-bottom: 120px;
  }
}
