.declined-main {
  border-radius: 6px;
}
/* .declined-single-order {
  display: flex;
  height: 50px;
  box-shadow: 0px 0px 5px 0px #2c314224;
  width: calc(100vw - 500px);
  border-radius: 6px;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
} */

.declined-order-details1 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}
.declined-order-id {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}
.declined-order-details2 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.declined-reason-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
}
.declined-order-details3 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.declined-orderid {
  display: flex;
  text-align: left;
  align-items: center;
  gap: 80px;
}

.declined-date-and-time {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 600px) {
  .declined-single-order {
    width: 100vw;
  }
  .declined-reason-text {
    display: none;
  }
  .declined-order-details1 {
    font-size: 16px;
  }
  .declined-order-details2 {
    font-size: 12px;
  }
  .declined-order-details3 {
    font-size: 12px;
  }
}


/* vendors orders*/




.delivered-orders-single-container {
  margin-top: 10px;
 
  background-color: #ffffff;
  
  border-radius: 6px;
  color: #2c3142;
  padding: 10px;

}
.delivered-orders-single-top1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivered-orders-order-id-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}
.delivered-orders-order-id {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}
.delivered-orders-order-date-and-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.delivered-orders-order-time {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.delivered-orders-order-date {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.delivered-orders-dotted-line {
  border: 1px #d9d9d9;
  border-style: dotted;
  margin-top: 10px;
}
.delivered-order-items-container {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  margin-left: 10%;
  justify-content: space-between;
}
.delivered-order-items {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: calc(50%);
  box-sizing: border-box;
  margin-top: 10px;
}
.delivered-order-bottom-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.delivered-order-bottom-container1 {
  display: flex;
  gap: 15px;
  align-items: center;
}

.delivered-order-status-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.delivered-orders-declined-circle {
  background-color: #e13535;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.delivered-order-price-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
}
/* .vendor-orders-declined-circle {
  background-color: #e13535;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
} */

@media screen and (max-width: 600px) {
  .delivered-orders-single-container {
    width: 100%;
    margin-right: 5px;
  }
}


