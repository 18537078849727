.instruction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46.68px;
  padding: 10px;
  border-radius: 8px;
}

.title-cross-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.instructions-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: var(--cooking-instruction-placeholder-color);
}
