.styleAddressModel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: var(--color2);
  padding: 16px;
  box-shadow: 24px;
  border-radius: 12px;
  border: none;
  outline: none;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .styleAddressModel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .styleAddressModel {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 15px;
    box-shadow: 24px;
    border-radius: 20px 20px 0 0;
  }
}
