.add-ons-container {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.add-ons-container::-webkit-scrollbar {
  display: none;
}

.add-ons-title {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
}

.items-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-and-title-container {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  position: relative;
}

.item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.plus-img {
  width: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.item-title {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

.title-price-rating-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price-and-rating-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.item-price-addons {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
}

.rating-addons {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
}

.star-img-addons {
  width: 12.94px;
  height: 12.72px;
}

.rating-container {
  display: flex;
  gap: 6px;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .add-ons-title {
    text-align: left;
  }
}
