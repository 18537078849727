.preparing-main {
  border-radius: 6px;
}
.preparing-single-order {
  display: flex;
  box-shadow: 0px 0px 5px 0px #2c314224;
  border-radius: 6px;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
}

.preparing-order-details1 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}
.preparing-order-id {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}
.preparing-order-details2 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.preparing-order-details3 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.preparing-orderid {
  text-align: left;
  align-items: left;
}
.preparing-date-and-time {
  display: flex;
  align-items: center;
  gap: 20px;
}
.push-to-pickup-btn {
  border: none;
  height: 40px;
  width: 140px;
  background-color: #2c3142;
  color: #ffffff;
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
}
.push-to-pickup-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .preparing-single-order {
    width: 100vw;
  }
  .preparing-order-details3 {
    display: none;
  }
  .preparing-order-details1 {
    font-size: 16px;
  }
  .preparing-order-details2 {
    font-size: 12px;
  }
}
