.menu-main-container-vendor {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 130px 0 0 200px;
  width: calc(100vw - 220px);
  padding: 0 20px;
  max-width: 1400px;

  @media (min-width: 1880px) {
    margin-inline: auto;
  }

  @media screen and (max-width: 600px){
    width: 100vw;
    margin: 80px 0 60px;
    padding: 0 15px;
  }
}


.back-arrow-image-vendor{
  height: 17px;
  width: 12px;

  img{
    height: 100%;
    width: 100%;
  }
}
.vendor-menu-page{
  width: 100%;
  max-width: 1200px;
}
.more-btm-menuitems {
  font-size: 10px;
  cursor: pointer;
  display: inline-block;
}

.svg-veg-non-veg-and-name-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.svg-icon-veg-and-non-veg {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

textarea {
  resize: none; 
}

.asterisk {
  color: red;
}

.menu-top-1-cont {
    display: flex;
    padding: 20px;
    background-color: #ffffff;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    position: fixed;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #2c3142;
    z-index: 9;
    right: 0;
    top: 130px;
    width: calc(100% - 200px);
    max-width: 1400px;
    left: 200px;

    @media screen and (max-width: 600px) {
      padding: 10px 10px 10px 0 ;
      right: 0;
    }
}

.menu-add-item-button {
  display: flex;
  align-items: center;
  border: 1px solid #02a750;
  background-color: white;
  cursor: pointer;
  outline: none;
  width: 110px;
  height: 35px;
  justify-content: space-between;
  border-radius: 3px 0px 0px 3px;
}

.menu-button-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-plus-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.menu-add-item-vertical-line {
  width: 1px;
  height: 35px;
  margin-right: 10px;
  background-color: #02a750; 
}

.menu-add-item-text {
  color: #464b59;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
}

.menu-starter-container-vendor {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
justify-content: space-between;
}

.menu-item {
  display: flex;
  width: calc(33.33% - 10px); 
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 0px #00000017;

  @media screen and (max-width: 980px)   {
    width: calc(50% - 10px);
  }
  @media screen and (max-width: 759px)   {
    width: 100%;
  }
}
.menu-starters-image-vendor {
  width: 40%;
  height: 200px;
  border-radius: 5px 0px 0px 5px;

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 771px)   {
    height: 150px;
  }
}

.vendor-menu-name-description{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.menu-description-container-vendor {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  /* width: 100%; */
  width: 60%;
  justify-content: space-between;
}
.heading-container {
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
}
.menu-item-head-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 700;
  color: #2c3142;
}
.menu-description {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  color: #464b59;
  width: 100%; 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; 
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  white-space: normal;
}

.menu-name-and-edit-delete-icons {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 80px;

  @media screen and (max-width: 600px)   {
    width: calc(100vw - 30px);
    padding: 10px;
  }
}

.menu-edit-and-delete-icons-container {
  display: flex;
  gap: 10px;
}

.menu-edit-icon {
  cursor: pointer;
}
.menu-delete-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.menu-more-heading {
  color: #2c3142;
  font-size: 11px;
  cursor: pointer;
}
.menu-item-bottom-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-item-price-text {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: left;
  color: #2c3142;
}
.menu-edit-and-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.menu-edit-button {
  height: 26px;
  border-radius: 3px;
  border: 1px solid #e5e8f1;
  background-color: #ffffff;
}
.menu-edit-vertical-line {
  background-color: #e5e8f1;
  width: 1px;
  height: 26px;
  margin-right: 5px;
}
.menu-edit-text {
  font-family: Poppins;
  font-size: 9px;
  font-weight: 400;
  margin-right: 5px;
  color: #464b59;
}
.menu-edit-icon {
  width: 20px;
  height: 20px;
}

.menu-toggle-switch {
  position: relative;
  width: 45px;
  height: 25px;
  background-color: #cbd5e1;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.3s ease;
}

.menu-toggle-switch-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.menu-toggle-switch-circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 19px;
  height: 19px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.menu-toggle-switch.on {
  background-color: #2c3142;
}

.menu-toggle-state-text {
  position: absolute;
  font-style: Poppins;
  top: 50%;
  transform: translateY(-50%);
  font-size: 8px;
  color: #ffffff;
  transition: 0.3s ease, left 0.3s ease;
}

.menu-toggle-state-text.off {
  left: 25px;
  color: #2c3142;
  opacity: 1;
}

.menu-toggle-state-text.on {
  left: 10px;
  opacity: 1;
}
.menu-new-div {
  display: flex;
  gap: 20px;
  top: 55px;
  width: 100%;
  /* height: 574px; */

  @media screen and (max-width: 771px)   {
    flex-direction: column;
  }
}
.menu-image-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40%;
  height: 480px;
  border-radius: 17px;
  background-color: #464b59;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 771px)   {
    width: 100%;
    height: 400px;
  }
}

.edit-image-or-video-svg-and-btn {
  position: absolute;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 2;

}

.image-or-video-preview-menuitems {
  width: 100%;
  height: 100%;
}
.menu-gallery-img {
  height: 20%;
  width: 20%;
  cursor: pointer;
}
.menu-add-item-update-photo-heading {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
}
.menu-additem-rightside-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 771px)   {
    width: 100%;
  }
}
.menu-additem-rightside-content-1 {
  display: flex;
  gap: 10px;
}
.menu-additem-food-name-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 80px;
}

.react-quill-description-ingrediants{
  height: 165px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.menu-additem-food-name-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-n800, #1e293b);
}
.menu-additem-food-name-ingridents {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-n800, #1e293b);
}
.menu-additem-food-name-inputbox {
  outline: none;
  padding: 12px 16px 12px 16px;
  border-radius: 3px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
}

.ql-container {
  height: 86px !important; 
  overflow-y: auto; 
  position: relative; 
}

.menu-additem-menu-type-inputbox {
  outline: none;
  height: 40px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  color: var(--Neutral-n400, #94a3b8);
}
.menu-additem-description-inputbox {
  height: 86px;
  padding: 12px 16px 12px 16px;
  outline: none;
  border-radius: 3px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
}
.menu-additem-btn {
  height: 50px;
  background-color: #e13535;
  color: #ffffff;
  font-family: Poppins;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  outline: none;
  border: none;
  margin-bottom: 10px;
}

.popup {
  display: flex;
  position: absolute;
  width: 318px;
  height: 302px;
  border-radius: 15px;
  z-index: 1;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: #2c3142cc 80%;
}
.edit-icon-circle {
  display: flex;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #2c3142;
  justify-content: center;
  margin-top: 15px;
}
.popup-cross-icon {
  position: absolute;
  background-color: black;
}

.no-items-message {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #878faf;
  text-align: center;
  line-height: 44px;
}

.menu-name-category {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600; 
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .menu-starter-text {
    margin-top: 0;
  }
  .menu-new-div {
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 65px;
  }
  .menu-image-container {
    width: 100%;
    height: 400px;

    @media screen and (max-width: 600px) {
      height: 250px;      
    }
  }
  .menu-gallery-img {
    height: 30%;
    width: 30%;
    cursor: pointer;
  }
  .menu-add-item-update-photo-heading {
    font-size: 16px;
    font-weight: 400;
    color: #878faf;
  }
  .menu-additem-food-name-inputbox {
    width: 100%;
    height: 40px;
  }
  .menu-additem-menu-type-inputbox {
    height: 40px;
  }
  .menu-additem-description-inputbox {
    width: 325px;
  }
  .menu-additem-btn {
    width: 325px;
  }
  .menu-additem-rightside-content {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .menu-additem-btn {
    width: 100%;
    cursor: pointer;
}
.menu-item { 
  width: 95%;
}
.menu-starter-container-vendor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin: 0 auto;
  width: calc(100vw - 30px);
}
}