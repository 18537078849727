.coupon-model-pop-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 600px;
  background-color: var(--color2);
  padding: 16px;
  box-shadow: 24px;
  border-radius: 12px;
  outline: none;
}

.coupon-model-pop-up .title-cross-container{
  display: flex;
  justify-content: space-between;
}

.coupon-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: var(--color1);
}

.coupon-input-and-btn {
  margin-top: 40px;
  display: flex;
  gap: 15px;
}

.coupon-input {
  width: 300px;
  outline: none;
  border: 1.5px solid #e2e8f0;
  height: 40px;
  text-indent: 10px;
  font-size: 14px;
  border-radius: 6px;
}

.apply-coupon-btn {
  height: 40px;
  font-family: var(--font-family);
  font-weight: 400;
  background-color: var(--color4);
  color: var(--color2);
  padding: 10px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.cross-icon path {
  fill: black;
  cursor: pointer;
}

.title-and-svg-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.coupon-modal {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;


  

}

.coupon-card-container {
  display: flex;
  gap: 10px;
  padding: 10px;
  /* border: 1px solid gray; */
  border: 1px solid #D3D4D4;
  border-radius: 8px;
}

.apply-btn-coupon {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  background-color: var(--color4);
  color: var(--color2);
  padding: 6px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.coupon-main-container {
  display: flex;
  flex-direction: column;
  height: 270px;
  overflow-x: auto;
  gap: 10px;
}

.close-in-add-offer-form {
  position: absolute;
  top:2%;
  right: 5%;
  color: #aaa;
  float: right;
  font-size: 30px;
  font-weight: bold;
  height: 90%;
  cursor: pointer;

}

.coupon-offer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.or-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: gray;
}

.offers-button {
  width: 100px;

  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  background-color: #808080;
  color: var(--color2);
  padding: 5px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.coupons-button {
  width: 100px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  background-color: #808080;
  color: var(--color2);
  padding: 5px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.offer-image {
  height: 100px;
  width: 100px;
  object-fit: fill;
}

.offer-details-containers {
  display: flex;
  flex-direction: column;
}

.offer-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  color: var(--color1);
}

.offers-percentage {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  color: var(--color1);
}


.apply-coupon-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: var(--color1);
  text-align: center;
}

.active-tab {
  /* background-color: #808080; */
  background-color: var(--color4);
  color: var(--color2);
}

.offers-and-coupon-title {
  font-family: var(--font-family);
font-weight: 600;
font-size: 16px;

}

.coupons-loader {
  margin-top: 60px;
}

.error-message-coupons-and-offers {
  color: red;
  font-size: 12px;      
}

