@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @font-face {
    font-family: Poppins;
    src: url('../Fonts/Poppins/Poppins-Regular.ttf');
} */


@font-face {
    font-family: Jost;
    src: url('../Fonts/Jost/Jost/static/Jost-Medium.ttf');
}


:root {

    --color1:#2c3142;
    --color2:#FFFFFF;
    --color3:#222736;
    --list-text-color:#cdcdcd;   /*  sidebar menu */
    --text-in-sidemenu-color:#42475a;  /*  sidebar menu */

    /* fonts */
    --font-family: Poppins;

    --bg-color: #f8f8f8;    
    --input-field-box-shadow-color:#0000001a;   /* topbar menu */
    --color7:#464b59;
    --placeholder-color:#ccc6c6;    /* topbar menu */
    --verticle-line-color:#e9e9e9;   /* topbar menu */


    

    --right-sidebar-verticle-line:#EAEAEA;   /* right side bar */
    
    --item-name-color:#616161;  /* right side bar */
    --border-color:#D9D9D9;
    --text-color:#757C94;
    --restaurant-card-border-color:#0000001A;   /* right side bar */
    --color4:#E13535;
    --border-and-bg-color:#EFEFEF;

    --review-cart-link:#F9C2C2;
    --bg-color2:#F1F1F1;
    --food-stepper-tracking:#D0D6EF;
    --blk-color5:#000000;
    --restaurant-name-color:#1D1D21;
    --bg-color3:#3D445B;
    --payment-options-border:#F5F5F5;
    --color6:#02A750;
    --color7 : #E13535;
    --color8:#1E293B;
    --color9: #464B59;
    --search-overlay-food-border:#CBD5E1;
    --notification-desc-color:#AAB2CE;
    --notification-dashed-line-color:#5E657E;
    --cooking-instruction-placeholder-color:#94A3B8;
    --rating-text-color:#878787;


    /* jost font-family */
       --font-family-jost:Jost;
}