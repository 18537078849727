.zoom-container {
  animation: zoomInOut 2s ease-in-out infinite alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.payment-success-svg-text {
  width: calc(100% - 450px);
  margin: 100px 250px 0 200px;
}

.payment-success{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-message {
  width: 350px;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  color: var(--color1);
  text-align: center;
  margin-top: 30px;
}

.successfully-placed-span {
  font-family: Poppins;
  font-size: 29px;
  font-weight: 800;
  line-height: 42px;
  text-align: center;
  color: var(--color1);
}

.thankyou-message {
  margin-top: 100px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color6);
}

.share-container {
  margin-top: 20px;
  width: 356px;
  height: 56px;
  background-color: var(--bg-color2);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.share-order-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--color1);
  cursor: pointer;
}

.success-verticle-line {
  height: 56px;
  width: 5px;
  border-right: 1px solid #e4e4e4;
}
.success-svg-icon {
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.verticle-line-and-svg {
  display: flex;
  gap: 10px;
  align-items: center;
}

.success-location-track-container {
  margin-top: 20px;
  margin-bottom: 150px;
  width: 356px;
  height: 56px;
  background-color: var(--color4);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}
.track-order-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--color2);
  cursor: pointer;
}

.success-track-verticle-line {
  height: 56px;
  width: 5px;
  border-right: 1px solid var(--border-color);
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 980px) {
  .payment-success-svg-text {
    width: calc(100% - 200px);
    margin: 100px 0 0 200px
  }
}

@media only screen and (max-width: 600px) {
  .payment-success-svg-text {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 1;
  }

  .payment-success-svg-text {
    width: 100%;
    margin: 100px 0;
  }
}
