.bloggers-main-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 55px;
  padding-left: 10px;

  @media screen and (max-width: 750px) and (min-width: 601px) {
    margin-top: 90px;
  }
}

.blogger-item {
  display: flex;
  gap: 15px;
  align-items: center;
}
.blogger-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.show-more-btn {
  height: 40px;
  color: var(--color8);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .bloggers-main-container {
    margin-left: 0px;
    margin-top: 80px;
    margin-bottom: 100px;
    gap: 10px;
    justify-content: center;
    padding-left: 0;
  }
}
