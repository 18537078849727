.offer-Model-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    width: 60%;
    height: 550px;
    overflow-y: auto;
    background-color: var(--color2);
    padding: 0 20px 20px;
    box-shadow: 24px;
    border-radius: 12px;
    min-width: 570px;
  } 

  @media screen and (max-width: 600px) {
    .offer-Model-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);  
      width: 95vw;
      height: 550px;
      overflow-y: auto;
      background-color: var(--color2);
      padding: 0 15px 15px;
      box-shadow: 24px;
      border-radius: 12px;
      min-width: 95vw;
    }     
  }