.call-and-stepper-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.call-container {
  margin-top: 40px;
  width: 370px;
  height: 60px;
  background-color: #3b4158;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 6px;
}
.delivery-boy-image {
  width: 60px;
  height: 60px;
}

.delivery-partner-img-frame {
  padding-left: 10px;
}
.delivery-boy-name {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: var(--food-stepper-tracking);
}
.delivery-partner-text {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  color: #a0a9cc;
}

.assigning-delivery-partner {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  color: var(--food-stepper-tracking);
}
.delivery-partner-img-name-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.stepper-container-1 {
  width: 370px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.stepper-and-time {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 35px;
}
.svg-bg-onload {
  margin-top: 40px;
  width: 54px;
  height: 53px;
  background-color: #3b4158;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.svg-bg-loaded {
  margin-top: 40px;
  width: 54px;
  height: 53px;
  background-color: var(--color6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.truck-svg-icon path {
  fill: #878faf;
}
.stepper-1 {
  width: 150px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  /* line-height: 22.5px; */
}
.time {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 300;
  line-height: 16.5px;
}

.stepper-onloading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 21px; */
  color: #878faf;
}
.onloading-time {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 300;
  /* line-height: 16.5px; */
  color: #aaadba;
}

.svg-bg {
  margin-top: 40px;
  width: 54px;
  height: 53px;
  background-color: #3b4158;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-svg-loading {
  cursor: not-allowed;
}

.order-getting-ready-container {
  margin-top: 40px;
  width: 370px;
  height: 60px;
  background-color: var(--color4);
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 100px;
}

.getting-ready-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: var(--color2);
}
.enjoy-reels-btn {
  height: 146px;
  height: 46px;
  background-color: var(--color2);
  padding: 10px;
  text-align: center;
  color: var(--color4);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.loading-sand-timer {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg); /* Initial rotation */
  }
  to {
    transform: rotate(360deg); /* Final rotation */
  }
}

@media only screen and (max-width: 600px) {
  .call-and-stepper-container {
    padding: 10px;
  }

  .call-container {
    width: 100%;
  }

  .stepper-container-1 {
    width: 100%;
  }

  .order-getting-ready-container {
    width: 100%;
  }
}

.stepper-dashed-1 {
  height: 35px;
  width: 1px;
  border: 1px dashed #878faf;
  display: flex;
  position: relative;
  top: 66px;
  left: -49px;
}
