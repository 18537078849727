.profile-home {
  width: calc(98vw - 200px);
  margin-left: 200px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.profile-page {
  flex: 1;
}

.skeleton-profile-follow{
  margin: auto;
  display: block;
}

.user-profile-above-data{
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0%;
}

.user-profile-below-text{
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0%;
}

.profile-Topbar-cont {
  width: 100%;
  margin-left: -200px;
}

.profile-conatiner-center{
  display: flex;
  justify-content: center;
}

.Topofpage-Main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Top-right-layout {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

@media screen and (min-width: 1100px) {
  .profile-post .video{
    margin-top: 0px !important;
    height: 100%;
    /* min-height: 673px;
    max-height: 673px; */
  }
}

.Top1-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;  
}
.Edit-button-layout {
  cursor: pointer;
}
.menuicon path {
  margin-left: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.profile-vector-icon {
  display: none;
}

.profile-container-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 20px 20px 40px;
  gap: 20px;
}
.profile-status-layout {
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 53, 53); 
  font-size: 50px;
  color: #fff;
}
.profile-photo {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  border: 2px solid rgb(225, 53, 53);
}
.profile-status-icon {
  width: 15px;
  height: 15px;
  margin-top: 78.8%;
  margin-left: 68.8%;
  position: absolute;
  background: rgba(69, 186, 67, 1);
  border-radius: 50%;
}

.profile-name-layout {
  display: flex;
  justify-content: space-between;
}

.profile-name-text {
  font-family: poppins;
  font-size: 21px;
  font-weight: 600;
  line-height: 31.5px;
  color: rgba(0, 0, 0, 1);
}
.profile-description-layout {
  width: 280px;
}
.profile-desc-line1 {
  font-family: poppins;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
}

.profile-info-layout {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
}

.Buttons-layout {
  display: flex;
  width: 100%;
  gap: 5px;
  margin-left: 10px;
}

.Posts-button {
  height: 50px;
}
.Reels-button {
  width: 33%;
  height: 50px;
}
.saved-button {
  width: 33%;
  height: 50px;
}

.posts-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.posts {
  width: 32.5%;
  height: 230px;
  cursor: pointer;
  position: relative;
}
.posts-imgs {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.reels-container {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 1px; 
}

.reels {
  width: 32.5%;
  gap: 2px;
  margin-left: 3px;
  height: 160px; 
  cursor: pointer;
  margin-top: 1px; 
  position: relative;
}

.reels-videos {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.saved-container {
  width: 100%;
  margin-top: 5px;
  gap: 5px;
  display: flex;
  flex-wrap: wrap; 
}

.saved {
  width: 32.5%;
  margin-top: 1px;
  margin-left: 1px;
  height: 230px; 
  cursor: pointer;
}

.saved-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Posts-button.activeBtn {
  background-color: #808080 !important; 
  color: #fff; 
}

.Reels-button.activeBtn {
  background-color: #808080 !important;
  color: #fff;
}
.saved-button.activeBtn {
  background-color: #808080 !important;
  color: #fff; 
}
.Posts-button.inactive {
  background-color: #393f54 !important;
}

.Reels-button.inactive {
  background-color: #393f54 !important; 
}
.saved-button.inactive {
  background-color: #393f54 !important; 
}

.likes-and-images-in-post {
  position: absolute;
  top: 0%;
  left: 0%;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: rgba(16, 16, 16, 0.5);
  width: 100%;
  height: 100%;
}

.comment-svg-post path {
  fill: var(--color2);
}
.like-svg-post path {
  fill: var(--color2);
}

.span-comment-text-post {
  color: var(--color2);
  display: flex;
  gap: 10px;
  align-items: center;
}
.span-like-text-post {
  color: var(--color2);
  display: flex;
  gap: 10px;
  margin-left: 30%;
  align-items: center;
}

.no-reels-and-images-message {
  margin-top: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.profile-main-container{
  justify-content: flex-start !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  width: 100%;
}

.profile-video-container{
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;
  width: 100% !important;
  margin-left: 10px !important;
  justify-content: flex-start;
  margin-top: 0px !important;
  z-index: 0;
}

.profile-post {
  overflow: hidden;
  max-width: calc(33% - 10px); 
  width: 100%;
}
.profile-post .video-important-reels {
  height: 100%;
}

.profile-video-container .video{
  margin-top: 0;
}

.follow-button-layout{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.follow-button{
  background: rgb(225, 53, 53);
  border: 1px solid var(--color2);
  color: var(--color2);
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  width: 100%;
}

@media screen and (min-width: 1100px) {
  .profile-post {
    height: 550px;
    max-width: 673px;
    z-index: 0;
  }
}

/* Large screens (≥1370px) - 3 in one row */
@media (min-width: 1370px) {
  .profile-post {
    max-width: calc(33% - 10px);
  }
}

/* Medium screens (800px - 1369px) - 2 in one row */
@media (max-width: 1369px) and (min-width: 800px) {
  .profile-post {
    max-width: calc(50% - 10px);
  }
}

/* Small screens (<800px) - 1 in one row */
@media (max-width: 800px) {
  .profile-post {
    max-width: 100%;
    margin: auto;
  }

  .profile-post .video-important-reels {
    height: calc(100vh - 200px) !important;
}

.profile-video-container{
  margin: 0 10px;
}
}

@media screen and (max-width: 700px) and (min-width: 601px)  {
  .profile-container-2 {
    padding: 10px;
    gap: 10px;
  }

.profile-status-layout{
  height: 100px;
  width: 100px;
}

  .profile-info-layout{
    gap: 10px;
  }
}

@media screen and (min-width: 601px) {
  .profile-dashboard {
    width: 97vw;
  }

  .menuicon path {
    fill: black;
    margin-left: 90px;
    cursor: pointer;
  }
  .profile-name-text {
    flex-grow: 1;
  }
}

@media screen and (min-width: 980px) {
  .profile-page {
    width: calc(98vw - 450px);
  }
  .reels {
    height: 270px !important;
  }
}

@media screen and (max-width: 600px) {
  .profile-home {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
  .profile-Topbar-cont {
    display: none;
  }

.Top-right-layout {
  width: 100%;
}



  .profile-post .video-important-reels {
    height: calc(100vh - 260px) !important;
}

  .video .video-important-reels{
    height: calc(100vh - 250px) !important;
  }

  .profile-conatiner-center{
    display: block;
  }

  .profile-name-text{
    color: white;
  }

  .profile-page {
    width: 100%;
    margin-top: 0;
  }
  .profile-vector-icon path {
    display: initial;
    fill: black;
    width: 16px;
    height: 27px;
  }

  .Posts-button.activeBtn{
    background-color: #FFFFFF !important;
    color: #2C3142;
  }
  
  .saved-button.inactive {
    background-color: #393f54 !important;
    color: #878FAF;
    margin-top: -1px;
  }
  .profile-post {
    max-width: 100%;
    margin: auto;
  }

  .profile-video-container{
    margin: 0 !important;
  }

  .profile-Topbar-cont {
    display: none;
  }

  .profile-container-2 {
    margin-top: 60px;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    background-color: #2C3142;
    padding: 20px 0;
  }
  
  .user-profile-below-text{
    font-size: 12px;
    line-height: 18px;
  }
  .profile-side-bar {
    display: initial;
  }

  .profile-name-layout {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-bottom: 15px;
  }

  .profile-info-layout {
    justify-content: space-evenly;
    color: white;
    padding: 0 30px;
  }

  .Buttons-layout {
    width: 100%;
    margin-left: 0;
    gap: 0;
  }

  .Reels-button {
    width: 33%;
  }
  .saved-button {
    width: 33%;
  }
  .posts-container {
    width: 100%;
    margin-left: 0%;
  }
  .saved {
    width: 33%;
    height: 170px;
    gap: 2px;
  }
  .reels-container {
    gap: 0;
  }
  .saved-container {
    width: 100%;
    height: 100%;
    margin-left: 0%;
  }
  .reels {
    width: 32.5%;
  }
  .Top1-layout {
    background-color: #2C3142;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: auto;
    display: flex;
    padding: 14px 20px;
  }
  .menuicon {
    cursor: pointer;
  }
  .menuicon path {
    display: initial;
    cursor: pointer;
  }
  .profile-vector-icon {
    border: none;
    display: initial;
  }
  .profile-vector-icon path{
    fill: white;
  }
  .span-comment-text-post {
    gap: 5px;
  }
  .span-like-text-post {
    gap: 5px;
    margin-left: 5px;
  }
}

