.error-text {
  color: #e13535;
  font-size: 12px;
  margin-top: 4px;
}

.error {
  border-color: #e13535 !important;
}




