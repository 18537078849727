.menu-editpopup-delete-container {
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  color: #2c3142;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;
}
.update-menu-items-main-container{
  margin-top: 76px;
  position: relative;

  @media screen and (max-width: 600px) {
    margin: 15px 0 0; 
  }
}
.back-btn-menuItems {
  position: fixed;
  top: 150px;
  left: 225px;
  z-index: 9;
  padding: 10px;
  background-color: #e13535;
  color: #ffffff;
  font-family: Poppins;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
cursor: pointer;
  outline: none;
  border: none;

  @media screen and (max-width: 600px) {
    left: 15px;
    top: 87px;
  }
}
.menu-editpopup-delete-icon {
  height: 33px;
  width: 33px;
}

.menu-additem-btn {
  cursor: pointer;
}

@media screen and (max-width: 600px) { 
  .menu-editpopup-delete-container {
    display: flex;
    justify-content: center;    
  }
}