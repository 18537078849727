.pop-up-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 6px;
  text-align: center;
}

.already-items-in-cart-heading {
  font-family: var(--font-family);
  color: var(--blk-color5);
  font-size: 18px;
}

.pop-up-message {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  color: var(--color1);
  font-size: 16px;
}

.btn-container-popup {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pop-up-btn-yes {
  width: 70px;
  margin-top: 20px;
  padding: 5px;
  font-family: Poppins;
  font-size: 14px;
  background-color: var(--color4);
  color: var(--color2);
  font-weight: 400;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.pop-up-btn-no {
  width: 70px;
  margin-top: 20px;
  padding: 5px;
  font-family: Poppins;
  font-size: 14px;
  background-color: var(--color4);
  color: var(--color2);
  font-weight: 400;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
