/*vendor registration form MUI*/


.stepper-container {
  width: 500px;

  margin-top: 130px;
  margin-left: 0px;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
}
.step-container-heading {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  color: #2c3142;
}

/* vendor registration form*/

.personal-information-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 0px;
  padding: 50px;
  box-shadow: 0px 0px 17px -5px #0000001a;
}

.vendor-registration-form {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 30%;
  z-index: 2;
}
.personal-info-title {
  color: var(--color2);
  font-family: var(--font-family);
  font-weight: 600;
}

/* personal information form */

.name-container-vendor {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label-text-required {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 500;
}

.label-text {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 500;
}

.label-text-required::after {
  content: "*";
  color: var(--color4);
  font-size: 14px;
  margin-left: 5px;
}

.full-name-input {
  width: 300px;
  height: 35px;
  border-radius: 8px;
  outline: none;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  text-indent: 15px;
}

.no-select {
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

/* Optional: Style to make it clear that copying is disabled */
.no-select::selection {
  background: transparent;
}

.no-select::-moz-selection {
  background: transparent;
}

::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--cooking-instruction-placeholder-color);
}

.gender-label-and-radio-btns {
  display: flex;
  gap: 8px;
}

.gender-name-and-label-container {
  display: flex;
  gap: 25px;
}

.gender-radio {
  accent-color: var(--color4);
}

.gender-category-name {
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--cooking-instruction-placeholder-color);
}

.calender-input {
  width: 300px;
  height: 35px;
  border-radius: 8px;
  outline: none;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  text-indent: 15px;
  background: url("./../assets/calender.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 95%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  font-size: 20px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: -4%;
}
input::-webkit-datetime-edit {
  position: relative;
  left: -5%;
}

/* identity details  css*/

.identity-details-main-container {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  gap: 20px;
  padding: 50px;
  box-shadow: 0px 0px 17px -5px #0000001a;
}

/* to hide up and down arrows in type="number" in input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.file-and-upload-icon-container {
  position: relative;
}
.upload-icon {
  color: #e13535;
  position: relative;
  left: -30px;
  top: 7px;
}

.choose-file[type="file"] {
  display: none;
}

.upload-img-container {
  margin-top: 10px;
  width: 300px;
  height: 150px;
  border: 1px dashed #e9e9e9;
  background-color: #f8f8f8;
  position: relative;
}

.camera-and-div-container {
  position: relative;
}

.camera-icon {
  position: relative;
  top: 25%;
  left: 40%;
}

.selected-image {
  width: 300px;
  height: 150px;
  object-fit: contain;
}

/* Enter otp css*/

.enter-otp-main-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: -10px;

  gap: 10px;
  box-shadow: 0px 0px 17px -5px #0000001a;
}

.otp-message-text {
  font-family: var((--font-family));
  font-size: 12px;
  font-weight: 400;
  color: var(--cooking-instruction-placeholder-color);
}
.plan-details-and-charges-cont {
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #e5e8f1;
  width: 400px;

  padding: 20px;
}
.plan-details-and-charges-text {
  font-family: var((--font-family));
  font-size: 18px;
  font-weight: 700;
  color: var(--Neutral-n800, #1e293b);
}
.plan-details-and-charges-data-text {
  font-family: var((--font-family));
  font-size: 15px;
  font-weight: 400;
  color: var(--Neutral-n800, #1e293b);
}
.enter-otp-bottom-main-container {
  padding: 30px;
  margin-left: 20px;
}
.point {
  height: 8px;
  width: 8px;
  background: #4caf50;
  border-radius: 50%;
}
.point-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.signiture-text-input-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.signiture-text {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  color: #878faf;
}

.signiture-input {
  width: 300px;
  height: 87px;
  border-radius: 8px;
  outline: none;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  text-indent: 15px;
}

/*KYC update message css*/

.kyc-update-message-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.success-svg-and-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .success-logo-kyc {
  margin-left: 100px;
} */

.kyc-success-msg {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.kyc-submit-msg {
  margin-top: 20px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
}

.kyc-submit-msg-2 {
  margin-top: 20px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
}

.kyc-verify-msg {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  color: var(--cooking-instruction-placeholder-color);
}

.view-reels-btn-kyc {
  width: 250px;
  height: 40px;
  border: none;
  background-color: var(--color4);
  color: var(--color2);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  /* margin-left: 90px; */
  margin-top: 40px;
  margin-bottom: 50px;
}

.switch-back-to-user {
  margin-left: 35%;
  margin-bottom: 5%;
  border: none;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  background-color: #e13535;
  color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
@media only screen and (max-width: 980px) {
  .stepper-container {
    width: 500px;
    /* box-shadow: 0px 0px 17px -5px #0000001A; */
  }
}

@media only screen and (max-width: 600px) {
  .stepper-container {
    width: 90%;
    /* margin-top: 10px; */
    margin-top: 130px;
    margin-left: 0px;
  }

  .file-and-upload-icon-container {
    display: flex;
    position: relative;
  }

  .upload-icon {
    color: #e13535;
    position: relative;
    top: 6px;
    left: -35px;
  }

  /*KYC update message css*/

  .success-svg-and-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
  }

  .kyc-success-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .kyc-submit-msg-2 {
    margin-top: 0;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
  }

  .kyc-verify-msg {
    width: 80%;
  }
  .vendor-registration-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0%;
    z-index: 2;
  }
  .personal-information-main-container {
    padding: 20px;
  }
  .identity-details-main-container {
    padding: 20px;
  }
  .enter-otp-main-container {
    margin-left: 5%;
  }
}
