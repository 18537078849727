.dashboard-vertical-line {
  width: 1px;
  height: 100vh;
  background-color: #eaeaea;
}
.dashboard-rightbar-container {
  padding-top: 15px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-rightbar-btn {
  width: 190px;
  height: 45px;
  border-radius: 10px;
  background-color: #e5e8f1;
  text-align: left;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  padding-left: 10px;
  cursor: pointer;
  border: none;
}

.dashboard-rightbar-btn.activebutton {
  width: 190px;
  height: 45px;
  border-radius: 10px;
  background-color: #2c3142;
  color: #ffffff;
}

.dashboard-rightbar-content {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  color: #2c3142;
}

/* Additional styling for Business Hours content */
.dashboard-container2 {
  display: flex;
  flex-direction: column;
  width: 190px;
  padding: 10px;
  gap: 10px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px #00000040;
}
.dashboard-business-hours-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: #444444;
}
.dashboard-days-container {
  display: flex;
  gap: 3px;
  width: 200px;
  color: #ffffff;
}
.dashboard-day {
  width: 21.68px;
  height: 21.68px;
  border-radius: 50%;
  background-color: #2c3142;
  text-align: center;
  padding-top: 6px;
}
.dashboard-day-txt {
  font-family: Poppins;
  font-weight: 400;
  font-size: 7px;
}
.dashboard-time-range-cont {
  display: flex;
  justify-content: space-around;
}

.dashboard-from-cont,
.dashboard-to-cont {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.dashboard-from-text,
.dashboard-to-text {
  font-family: Poppins;
  color: #e13535;
  font-size: 12px;
  font-weight: 600;
}

.dashboard-custom-select {
  width: 86px;
  padding: 6px;
  font-size: 11px;
  border-radius: 5px;
  outline: none;
  border: 2.5px solid var(--Neutral-n200, #e2e8f0);
  background: #ffffff;
  background-repeat: 8px;
  color: #2c3142;

  /* box-shadow: 0px 0px 5px 0px #00000040; */
}
.dashboard-submit-btn {
  width: 170px;
  height: 30px;
  border-radius: 6px;
  border: none;
  background-color: #e13535;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.dashboard-timings-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.dashboard-timings-left {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.dashboard-day-name {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  color: #2c3142;
}
.dashboard-time-text {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  color: #2c3142;
}
