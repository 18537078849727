.vendor-topbar-container {
  width: 100%;
  height: 130px;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3; 
  background-size: fill;
  background-repeat: no-repeat;
}

.vendor-turn-on-location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.vendor-name {
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: 800;
  text-align: left;
  color: var(--color2);
  text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px darkgray
}

.vendor-turn-on-location {
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.03em;
  text-align: left;
  color: var(--color2);
}
.vendor-turn-on-location-description {
  font-family: var(--font-family);
  color: var(--color2);
  font-size: 10px;
  font-weight: 300;
  letter-spacing: -0.03em;
}

.vendor-search-container {
  margin-top: 50px;
  width: 35%;
  height: 40px;
  border: 1px solid red;
  display: flex;
  gap: 10px;
  background: var(--color2);
  box-shadow: 0px 2px 4px 0px var(--input-field-box-shadow-color);
  border-radius: 8px;
  border: none;
  padding: 0 10px;
}

.vendor-input-field {
  width: 90%;
  border: none;
  background: var(--color2);
  outline: none;
}

.vendor-search-icon {
  height: 17px;
  width: 17px;
  margin-top: 12px;
  margin-left: 7px;
}

::placeholder {
  width: 100%;
  color: var(--placeholder-color);
  font-family: var(--font-family);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
}

.vendor-verticle-line {
  width: 26px;
  color: var(--verticle-line-color);
  margin-top: 10px;
  margin-left: 10px;
}

.vendor-mic-icon {
  width: 18px;
  height: 18px;
  margin-top: 12px;
  margin-left: -15px;
  color: var(--color7);
}

.vendor-notification-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.vendor-profile-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}
.vendor-fallback-profile-photo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e13535;
  color: #fff;
}

.vendor-notification-overlay {
  position: absolute;
  top: 57vh;
  left: 85%;
  transform: translate(-50%, -50%);
  min-width: 30%;
  height: 85vh;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 9999;
}

.vendor-arrow-to-point-notification {
  position: absolute;
  top: 90px;
  left: 92.5%;
  transform: rotateX(0%);
  rotate: 225deg;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid transparent;
  box-shadow: 4px px 0px 0px #0000001a;
}
.vendor-topbar-menu-icon {
  display: none;
}
.vendor-profile-and-notification {
  display:flex;
  gap: 10px;
  justify-content: center; 
  z-index: 999;
  padding-right: 30px;

  @media screen and (max-width: 600px) {
    padding-right: 0;
  }
}

.vendor-restaurant-logo-container {
  width: 100px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #e13535;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  left: 10px;
}

.vendor-restaurant-logo {
  width: 100px;
  height: 110px;
  object-fit: fill;
  border-radius: 6px;
  background-color: red;
}

.gallary-container-vendor {
  position: absolute;
  top: 75px;
  left: 65px;
  background-color: #ffffff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.gallary-img-vendor {
  height: 20px;
  width: 20px;
  margin-top: 5px;
}
.desktop-vendor-background{
  position: absolute;
  height: 100%;
  width: 100%;

  img{
    height: 100%;
    width: 100%;
  }
}
.icon-underline{
  display: none;
}

@media only screen and (max-width: 600px) {
  .vendor-topbar-container {
    height: 80px;
    padding: 20px;
    width: 100vw;
    background-color: #2c3142;
  }

  .desktop-vendor-background{
    display: none;
  }
  .vendor-turn-on-location-container {
    display: none;
  }
  .vendor-search-container {
    display: none;
  }
  .vendor-profile-icon {
    display: none;
  }
  .vendor-topbar-menu-icon {
    display: initial;
  }
  .vendor-restaurant-logo {
    width: 100px;
    height: 105px;
  
  }
  .vendor-arrow-to-point-notification {
    top: -20px;
    left: 87.65%;
  }
  .vendor-notification-overlay {
    left: 50%;
    top: 42.5vh;
    width: 350px;
  }
  .notification-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}
  .vendor-restaurant-logo-container {
    height: 70px;
    width: 70px;
    margin-top: 5px;
  }
}
