.Vendor-settings-main {
  width: 100%;
}
.vendorsettings-cont {
  margin-left: 200px;
  margin-top: 120px;
}

@media screen and (max-width: 600px) {
  .vendorsettings-cont {
    margin-left: 0;
    margin-top: 50px;
  }
}
