
.vendor-registration-topbar-container {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3;
  background-image: url("../assets/Vendorregistrationtopbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Overlay Effect */
.vendor-registration-topbar-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Light black overlay */
  z-index: -1; /* Keeps overlay behind content */
}

.vendor-registration-omf-container {
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}



/* Mobile View */
@media only screen and (max-width: 600px) {
  .vendor-registration-search-profile-container {
    display: flex;
    gap: 20px; /* Reduced gap for smaller screens */
    width: 90%; /* Adjusted width for better fit */
    margin-left: 0;
    align-items: center;
  }
}
