.offer-detail-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    outline: none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.offer-detail-popup .title-close-container{
    display: flex;
    justify-content: flex-end;
}

.title-close-container .close-icon{
    cursor: pointer;
}

.offer-detail-popup .offer-details-container{
    overflow-y: auto;
    max-height: 80vh;
    margin-top: 10px;
}