.payment-mode-text {
  margin-top: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #53565a;
  cursor: pointer;
}

.payment-container {
  width: 410px;
  height: 45px;
  border: 1px solid var(--payment-options-border);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
}
.payment-mode-container {
  width: 90%;
}
.payment-container.selected {
  border: 1px solid var(--color4);
}

.svg-and-label-text {
  display: flex;
  gap: 15px;
  align-items: center;
}
.payment-radio-btn {
  accent-color: var(--color4);
  cursor: pointer;
}
.payment-svg-icon {
  width: 17px;
  height: 19px;
}
.payment-label-text {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.6px;
  cursor: pointer;
}

/* card details*/

.card-details-title {
  margin-top: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #53565a;
}

.card-input-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 350px;
}
.card-label-input {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-label-name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  color: var(--color1);
}
.card-input-field {
  border: 1px solid #d3d4d4;
  background-color: var(--color2);
  height: 38px;
  border-radius: 4px;
  text-indent: 10px;
  outline: none;
}

.card-month-year-cvv-input-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.card-mm-yy-inputs-container {
  width: 170px;
  display: flex;
  align-items: center;
  border: 1px solid #d3d4d4;
  border-radius: 4px;
}

.card-slash-mm-yy {
  margin-top: -5px;
}

.Input-Card {
  /* width: 170px; */

  padding: 8px;
  border: 1px solid #d3d4d4;
  border-radius: 4px;
}
.Input {
  /* width: 170px; */
  width: 150px;
  padding: 8px;
  border: 1px solid #d3d4d4;
  border-radius: 4px;
}
.expiry-month-input {
  width: 40px;
  height: 38px;
  /* background-color: var(--color2); */
  border-radius: 4px;
  text-indent: 10px;
  border: none;
  outline: none;
}

.card-validity-input-container {
  margin-top: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  color: var(--color1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.make-payment-btn {
  width: 100%;
  height: 40px;
  background-color: var(--color4);
  color: var(--color2);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  border: none;
  border-radius: 14px;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 20px;
}

.save-card-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.save-card-input {
  width: 20px;
  height: 20px;
}
.save-card-text {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  color: var(--blk-color5);
}

.disabled-btn {
  background-color: #ccc; /* Gray background */
  cursor: not-allowed; /* Change cursor on hover */
}

.expiry-and-cvv-container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .payment-container {
    width: 100vw;
  }
  .card-container {
    margin-left: 20px;
  }

  .card-input-container {
    width: 90vw;
  }
  .disabled-btn {
    margin-left: 17px;
  }
  .make-payment-btn {
    margin-left: 20px;
  }
}
