.topmenubar-cart {
  display: flex;
}

.cart-mobile-header {
  display: none;
}

.cart-container {
  width: calc(100% - 450px);
  margin: 100px 250px 0 200px;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
}

.cart-box{
  max-width: 600px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-items-details{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.item-container-cart {
  display: flex;
  gap: 25px;
  /* width: 50%; */
}

.increment-container {
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 5px;
}

.count-value {
  color: var(--blk-color5);
}

.btn {
  width: 15px;
  background-color: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.content-box-sidebar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.item-container-order {
  display: flex;
  gap: 25px;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 600px) {
    gap: 15px;
    padding:0 10px 0 0;
  }
}

.item-details-order1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.item-row1 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.row1 {
  display: flex;
  align-items: center;
  gap: 3px;
}
.reddot-iten-name {
  display: flex;
  gap: 5px;
  align-items: center;
}

.order-item-row2 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 72px);
}

.desc-add-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-reddot-order {
  width: 13px;
  height: 13px;
}
.item-img-order {
  width: 95.5px;
  height: 92.15px;
  border-radius: 10px;
  object-fit: cover;
}

.item-name-order {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;
  text-transform: capitalize;
}

.item-cost-order {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.03em;
}

.star-rating-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item-star-order {
  width: 14.58px;
  height: 14.58px;
  object-fit: contain;
}

.item-rating-order {
  width: 21px;
  height: 14px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: var(--color1);
}

.item-review-order {
  width: 64.7px;
  height: 13.67px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: var(--color1);
}

.item-desc-order1 {
  width: 280px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #6d7383;
  height: auto !important;

  @media (max-width : 600px) {
    font-size: 7px;
    line-height: 10.5px;
    width: 225px;
  }
}

.item-add-btn {
  width: 70px;
  height: 27.26px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  border: 1px solid var(--color6);
  color: var(--color6);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  background-color: #FAFCFF;
}

.total {
  margin-top: 30px;
  width: 100%;
}

.address-container1 {
  width: 60%;
  background-color: var(--bg-color3);
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}

.delivery-location-home-desc-container {
  display: flex;
  gap: 10px;
}
.location-svg-icon-adress path {
  width: 24px;
  height: 30px;
}

.delivert-to-home-desc-container {
  display: flex;
  flex-direction: column;
}
.delivery-to-home-text {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: var(--color2);
}
.delivery-to-home-address {
  font-family: var(--font-family);
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  color: var(--text-color);
}

.items-andprice-container {
  margin-top: 30px;
  width: 60%;
}

.item-name-and-price-container {
  display: flex;
  justify-content: space-between;
}


.empty-cart-img {
  height: 300px;
  width: 300px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}

.continue-to-order {
  margin-top: 50px;
  width: 360px;
  height: 46.54px;
  padding: 8px;
  font-family: Poppins;
  font-size: 18px;
  background-color: var(--color4);
  color: var(--color2);
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.cart-horizantal-line {
  width: 100%;
  border: 1px solid #e5e8f1;
}

.cart-container .empty-cart-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 980px) {
  .cart-container {
    width: calc(100% - 200px);
    margin: 100px 0 0 200px;
  } 
} 

@media only screen and (max-width: 600px) {

  .cart-box{
    max-width: 600px;
    width: 100%;
  }

  .topbar-cart {
    display: none;
  }

  .cart-mobile-header {
    width: 100%;
    height: 74px;
    background: var(--color1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .cart-header-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
  }

  .review-order-heading {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: var(--color2);
    margin-bottom: 0;
  }

  .item-container-cart {
    display: flex;
    gap: 15px;
    width: 100%;
  }

  .increment-container {
    display: flex;
    align-items: center;
    gap: 0;
    margin-left: -60px;
  }

  .address-container {
    width: 90%;
  }

  .item-img-order {
    width: 80.5px;
    height: 92.15px;
    object-fit: cover;
  }

  .cart-container {
    width: 100%;
    margin: 100px 0 0 0;
  }

  .continue-to-order {
    width: 300px;
  }

  .address-container1{
    width: 100%;
  }

  .items-andprice-container {
    width: 100%;
  }  
}
