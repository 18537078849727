.declined-vertical-line {
  width: 1px;
  height: 100vh;
  background-color: #eaeaea;
}
.declined-rightsidebar-main-cont {
  padding-top: 15px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.declined-container1 {
  width: 190px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  color: #000000;
  box-shadow: 0px 0px 12px 0px #00000029;
}
.declined-container1-text-1 {
  padding-top: 15px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 32px;
}
.declined-container1-text-2 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}
.declined-container2 {
  display: flex;
  flex-direction: column;
  width: 190px;
  padding: 10px;
  gap: 10px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px #00000040;
}
.custom-range-head {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: #444444;
}
.from-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #e13535;
}
.from-cont {
  display: flex;
  flex-direction: column;
}
.from-input-container {
  display: flex;
  gap: 2px;
}
.declined-custom-select {
  padding: 3px;
  font-size: 14px;
  border-radius: 8px;
  outline: none;
  border: 2.5px solid var(--Neutral-n200, #e2e8f0);
  background: #ffffff;
  color: #2c3142;
}
.declined-submit-btn {
  margin-top: 10px;
  width: 170px;
  height: 35px;
  border-radius: 6px;
  border: none;
  background-color: #e13535;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}
