.restaurantnearyou-Main-container-11 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top:-10px; */
  margin-top: -5px;
}

.restaurantnearyou-head-container-11 {
  width: 100%;
  margin-top: 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.restaurantnearyou-head-text-111 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-left: 22px;
  /* margin-top: 10px; */
  line-height: 21px;
  /* margin-left: 30px; */
  color: rgba(0, 0, 0, 1);
}
.restaurantnearyou-card-container-111 {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 20px;
  margin-right: 30px;
  gap: 16px;
}
.restaurantnearyou-card-111 {
  position: relative;
  overflow: hidden;
  white-space: normal; /* Allow text to wrap if needed */
  border: none;
}

.restaurantnearyou-text-111 {
  /* margin-left: 10px; */
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(29, 29, 33, 1);
}
.restaurantnearyou-image-container-111 {
  border-radius: 12px;
}

.restaurantnearyou-image-111 {
  width: 220px;
  height: 150.1px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  object-fit: cover;
}
.restaurantnearyou-offer-bg-111 {
  padding: 5px 4px 7px 6px;
  position: absolute;
  top: 0;
  right: 12px;
  border-radius: 0px 0px 4px 4px;
  background: rgba(225, 53, 53, 1);
}
.restaurantnearyou-image-footer-111 {
  /* margin-left: 10px; */
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  /* width: 150px;
  height: 25px; */
}

.restaurantnearyou-star-icon-image-11 {
  color: rgba(242, 185, 13, 1);
  width: 14.72px;
  height: 13.98px;
  margin-right: 5px;
}

.restaurantnearyou-reviews-11 {
  font-family: poppins;
  font-size: 10.72px;
  font-weight: 400;
  line-height: 15.08px;
  letter-spacing: 0px;
  color: rgba(135, 135, 135, 1);
}

.restaurantnearyou-offer-percentage-111 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0%;
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 600px) {
  .restaurantnearyou-card-container-111 {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .restaurantnearyou-card-111 {
    gap: 10px;
    width: calc(50% - 20px);
  }
  .restaurantnearyou-image-container-111 {
    border-radius: 12px;
  }

  .filters-list-button-container-11 {
    margin-left: 28%;
  }
  .restaurantnearyou-head-text-111 {
    font-weight: 600;
    margin-left: 10px;
    font-size: 14px;
  }
  .restaurantnearyou-image-111 {
    width: 100%;
    height: 140.1px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    object-fit: cover;
  }
  .restaurantnearyou-card-container-111 {
    margin-left: 10px;
    margin-bottom: 0px;
  }
  .restaurantnearyou-offer-percentage-111{
    font-size: 13px;
    line-height: 16.5px;
  }
}
