.place-order-previous-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--color4);
    border-radius: 6px;
    padding: 8px;
    border: none;
    cursor: pointer;
  }
  
  .place-order-previous-btn img{
    width: 15px;
    height: 23px;
  }
  
  .place-order-previous-btn p{
    font-family: Poppins;
    font-size: 18px;
    color: var(--color2);
    font-weight: 500;
  }
  
  @media only screen and (max-width: 600px) {
    
  .place-order-previous-btn{
    display: none;
  }
  }