.coupon-Model-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 550px;
    overflow-y: auto;
    background-color: var(--color2);
    padding: 20px;
    box-shadow: 24px;
    border-radius: 12px;
  } 


  @media screen and (max-width: 600px) {
    .coupon-Model-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90vw;
      height: 550px;
      overflow-y: auto;
      background-color: var(--color2);
      padding: 15px;
      box-shadow: 24px;
      border-radius: 12px;
    } 
  }