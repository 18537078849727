.post-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin-top: 5%;
    margin-left: 30%;  */
  width: 750px;
  height: 500px;
  background-color: var(--color2);
  padding: 30px;
  box-shadow: 24px;
  border-radius: 12px;
}

@media only screen and (max-width: 900px) {
  .post-modal {
    width: 80%;
    height: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .post-modal {
    width: 100%;
  }
}
