.Offers-Main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px !important;
}
.offers-for-you-title-homepage-1 {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-left: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blk-color5);
}

.offers-heading-container {
  width: 160px;
  height: 21px;
  margin-top: 8px;
}
.head-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 1000;
  line-height: 21px;
  margin-left: 30px;
  color: rgba(0, 0, 0, 1);
}
.restaurant-card-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  position: relative;
  margin-top: 2px;
  margin-right: 20px;
}
.Offers-Main-container .offer-card-11 {
  margin-left: 0px;
  margin-right: 0px; 
  white-space: normal; 
  border: none;
}
.offer-card-1 .restaurant-name {
  width: 103px;
  margin-top: 10px !important;
  transform: rotate(0);
}
.restaurant-text {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(29, 29, 33, 1);
}
.offer-for-you-image-container {
  width: 120.1px;
  height: 120.1px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  border-radius: 17px;
  justify-content: center;
  align-items: center;
}

.offer-for-you-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  object-fit: cover;
}
.offer-for-bg {
  padding: 8px 4px 4px 5px;
  border-radius: 0px 0px 4px 4px;
  background: rgba(225, 53, 53, 1);
  position: absolute;
  top: 0;
  right: 10px;
}
.offer-image-footer-review {
  display: flex;
  align-items: center;
}

.star-icon-image {
  color: rgba(242, 185, 13, 1);
  width: 14.72px !important;
  height: 13.98px;
  margin-right: 5px;
}

.reviews {
  font-family: poppins;
  font-size: 10.72px;
  font-weight: 400;
  line-height: 15.08px;
  letter-spacing: 0px;
  color: rgba(135, 135, 135, 1);
}

.offer-for-per {
  display: flex;
  justify-content: center;
  font-family: poppins;
  font-size: 15px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 1);
}
.left-arrow1 {
  margin-top: -25px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  color: grey;
  margin-left: -40px;
}
.left-arrow1:hover {
  color: var(--blk-color5);
}
.right-arrow1 {
  width: 30px;
  height: 30px;
  margin-left: 0px;
  margin-top: -25px;
  cursor: pointer;
  position: absolute;
  color: grey;
}
.right-arrow1:hover {
  color: var(--blk-color5);
}

/* recently chages arrows */

.title-and-arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrows {
  margin-top: 20px;
  margin-right: 60px;
}

.left-arrow {
  margin-top: -26px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  color: gray;
  margin-left: -40px;
}
.left-arrow:hover {
  color: var(--blk-color5);
}
.right-arrow {
  margin-top: -26px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  color: gray;
  margin-left: 0px;
}
.right-arrow:hover {
  color: var(--blk-color5);
}

.restaurantnearyou-slider {
  margin-top: 15px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
}

.restaurantnearyou-card-11 {
  padding: 10px;
}

.offer-card-1 {
  position: relative;
  overflow: hidden;
  white-space: normal; 
  border: none;
}

@media screen and (min-width: 1165px) {
.offer-card-1 .slick-track{
  display: flex;
}
}



.restaurants-data .restaurantnearyou-star-icon-image-11{
  width: 14.72px !important;
}

.Offers-Main-container .slick-slide .star-icon-image{
  height: 15px;
}

.flex-offers-container {
  display: flex;
  gap: 45px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 20px;
}

.offer-card-1 .slider-component{
  margin: 0 10px 0 20px;
}

@media screen and (max-width: 600px) {
  .Offers-Main-container {
    width: 100%;
    margin-top: -10px;
    
  }

  .Offers-Main-container .offer-card-11{
    margin: 10px;
  }

  .offer-for-you-image-container{
    width: 100px;
    height: 100px;
    background-color: white !important;
  }

  .offers-for-you-title-homepage-1 {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--blk-color5);
  }

  .offer-for-per {
    font-size: 13px;
  }
  .Offers-image-container {
    width: 100.1px;
    height: 100.1px;
  }
  .restaurant-card-container {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 10px;
  }
  .restaurant-card-1 {
    height: 145px;
    width: 101.1px;
    margin-right: 18px;
    margin-left: 10px;
    white-space: normal; 
    border: none;
  }
  .rest-image {
    width: 100.1px;
    height: 100.1px;
  }
  .right-arrow1 {
    display: none;
  }
  .left-arrow1 {
    display: none;
  }
  .head-text {
    margin-top: 10px;
    margin-left: 7%;
    font-weight: 600;
    font-size: 14px;
  }

  .restaurant-name {
    width: 103px;
    margin-top: 10px;
    transform: rotate(0);
  }
  .flex-offers-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 18px;
  }
  .offer-card-1 .slider-component{
    margin: 0 10px;
  }
}
