@media screen and (max-width: 600px) {
  .Home {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;
  }
  .Topbar-cont {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .home-page {
    width: 100vw;
    height: auto;
    margin-top: 35px;
    margin-bottom: 50px;
    margin-right: 0;
  }
  .dashboard{
    width: 100vw;
  }
}

@media screen and (min-width: 601px) {
  /* .dashboard {
    width: 100vw;
  } */
  .Home {
    display: flex;
    flex-direction: column;
  }

  .home-page {
    width: calc(98vw - 200px);
  }

  .home-page-cont1 {
    display: flex;
    margin-top: 100px;
    margin-left: 200px;
  }
}

@media screen and (min-width: 980px) {
  .home-page {
    width: calc(98vw - 450px);
  }
}
