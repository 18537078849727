.preparing-vertical-line {
  width: 1px;
  height: 100%;
  background-color: #eaeaea;
  position: fixed;
  top: 130px;
  z-index: 9;
}
.preparing-rightsidebar-main-cont {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.preparing-container1 {
  width: 190px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  color: #000000;
  box-shadow: 0px 0px 12px 0px #00000029;
}
.preparing-container1-text-1 {
  padding-top: 15px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 32px;
}
.preparing-container1-text-2 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}

.loading-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
