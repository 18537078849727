.left-container {
  position: absolute;
  bottom: 4%;
  margin-left: 14px;
  z-index: 1;
  width: 65%;
}

.reels-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  display: flex;
  flex-direction: column;

}

.pic-username {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.profile {
  width: 32px !important;
  height: 32px !important;
  object-fit: fill;
  border-radius: 50%;
}

.user-name-and-follow-container {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.user-name-and-follow-container .user-pic-and-username{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  max-width: 75%;
}

.user-name {
  height: 15px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: var(--color2);
}

.follow-btn {
  border: none;
  padding: 3px;
  background: transparent;
  border: 1px solid var(--color2);
  color: var(--color2);
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.restaurant-name-reels {
  line-height: 21px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: var(--color2);
  border: none;
  background: none;
  text-align: left;
}

.rest-name-cont{
  display: flex;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
}

.distance-rating-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rating-container-2{
  display: flex;
    gap: 0;
    align-items: center;
}
.start-img {
  width: 16.64px;
  height: 12.46px;
  margin-top: -2px;
  text-align: left;
}
.rating {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: left;
  color: var(--bg-color);
}
.distance {
  height: 15px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: left;
  color: var(--bg-color);
}

.restaurant-description1 {
  width: 207px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color2);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.restaurant-description2 {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: justify;
  color: var(--color2);
  width: 100%;
  word-break: break-word;
  max-height: 100px;
  overflow-y: auto;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.restaurant-description2::-webkit-scrollbar {
  display: none; 
}


@media only screen and (min-width: 601px) and (max-width: 768px) {
  .left-container {
    margin-left: 10px;
  }
}