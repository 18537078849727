.media-post-reels {
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

.media-item img,
.media-item video {
  width: 300px;
  height: 400px;
  object-fit: cover;
}
.no-post{
  margin-top: 150px;
}

@media only screen and (min-width: 600px) and (max-width: 980px) {
  .media-post-reels {
    margin-top: 45px;
  }
}

@media screen and (max-width: 600px) {
  .media-post-reels{
    margin-top: 60px;
  }
}