.Offers-new-div {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  gap: 20px;
}

.menu-additem-food-name-container-offer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 180px;
}

.menu-additem-food-name-container-descriptions{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 95px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

.close-in-add-offer-form {
  position: absolute;
  top:2%;
  right: 5%;
  color: #aaa;
  float: right;
  font-size: 30px;
  font-weight: bold;
  height: 90%;
  cursor: pointer;

}

.title-and-close-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 99999;
  margin-bottom: 10px;
  padding-top: 20px;

  @media screen and (max-width : 600px) {
    
  }
}

.add-offer-title {
  text-align: center;
  font-size: 24px;
}

.Offers-main-div {
  display: flex;
  flex-direction:column;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.restaurant-Offers-image-container {
  /* display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  width: 430px;
  height: 480px;
  border-radius: 17px;
  background-color: #464b59;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center; */

  position: absolute;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 9999;
}


.menu-gallery-img {
  height: 20%;
  width: 20%;
  cursor: pointer;
}
.Offers-add-item-update-photo-heading {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
}
.Offers-additem-rightside-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width:600px) {
    width: 100%;
  }
}
.Offers-additem-rightside-content-1 {
  display: flex;
  gap: 8px;

  @media screen and (max-width:422px)  {
    flex-direction: column;
  }
}

.Offers-additem-rightside-content-1-date{
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media screen and (max-width:600px)  {
    flex-direction: row;
  }
  @media screen and (max-width:500px)  {
    flex-direction: column;
  }
}
.Offers-additem-food-name-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5px;
}
.Offers-additem-food-name-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-n800, #1e293b);
  line-height: 16px;
}
.Offers-additem-food-name-inputbox {
  outline: none;
  padding: 12px 16px 12px 16px;
  border-radius: 3px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  display: flex;
}

.Offers-additem-description-inputbox {
  padding: 12px 16px 12px 16px;
  outline: none;
  border-radius: 3px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
}
.Offers-additem-btn {
  width: 180px;
  cursor: pointer;
  height: 50px;
  background-color: #e13535;
  color: #ffffff;
  font-family: Poppins;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  outline: none;
  border: none;
}

.offers-coupons-container {
  width: 100%;
  outline: none;
  height: 40px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  color: var(--Neutral-n400, #94a3b8);
}

.offer-img-and-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40%;
  height: 450px;
  border-radius: 17px;
  background-color: #464b59;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}

.image-preview-offers {
  width: 100%;
  height: 100%;
}

.vendor-offer-main-container {
  height: 250px;
  width: 80%;
 
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.offer-card-container-vendor {
  display: flex;
  gap: 20px;
  border: 1px solid gray;
  padding: 10px;
}

.offer-img {
  width: 200px;
  height: 230px;
  object-fit: fill;
  /* border: 1px solid black; */
}

.title-and-status-container {
  display: flex;
 gap: 10px;
 margin-top: 10px;
}

.offer-title {
  font-size: 16px;
}

.offer-status {
  margin-left: 100px;
  font-size: 16px;
}
.vendor-offer-percentage {
  font-size: 16px;
}
.offer-short-desc {
  font-size: 16px;
}

.offer-desc {
  font-size: 16px;
}

.valid-from {
  font-size: 16px;
}
.valid-to {
  font-size: 16px;
}

.offers-btn-container {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

.offer-edit-btn {
  height: 30px;
  width: 70px;
  border: none;
  background-color: #e13535;
  color: #ffffff;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
}

.offer-delete-btn {
  height: 30px;
  width: 70px;
  border: none;
  background-color: #e13535;
  color: #ffffff;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
}

.no-boundries-label-and-checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.no-boundries-label {
  font-size: 16px;
}

.no-boundries-checkbox {
  height: 20px;
  width: 20px;
}

.Offers-additem-btn-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) and (max-width: 980px) {
  .Offers-additem-rightside-content-1 {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .Offers-new-div {
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .Offers-image-container {
    width: 100%;
    height: 150px;
  }
  .Offers-gallery-img {
    height: 30%;
    width: 30%;
    cursor: pointer;
  }
  .Offers-add-item-update-photo-heading {
    font-size: 16px;
    font-weight: 400;
    color: #878faf;
  }
  .Offers-additem-food-name-inputbox {
    width: 100%;
    height: 40px;
  }
  .Offers-additem-description-inputbox {
    width: 100%;
  }
  .Offers-additem-btn {
    width: 175px;
  }
  .offer-card-container-vendor {
    width: 90vw;
    display: flex;
    gap: 20px;
    border: 1px solid gray;
    padding: 10px;
  }

  .offer-img-and-container {
    width: 99.5%;
    height: 300px;
    margin-left: 0.25%;
  }
  .Offers-additem-rightside-content {
    margin-left: 0;
  
  }
}
