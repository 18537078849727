.right-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  z-index: 1;
  width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex-direction: column;
}

.icons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 55px;
}

.svg-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.like-svg-icon {
  width: 31.47px;
  height: 30px;
  color: var(--color2);
  cursor: pointer;
}

.comment-svg-icon {
  width: 28.84px;
  height: 31px;
  cursor: pointer;
}

.share-svg-icon {
  width: 30px;
  height: 29px;
  cursor: pointer;
}

.paragraph-color {
  color: var(--color2);
  font-size: 12px;
  line-height: 12px;
}


.order-btn {
  width: 100%;
  height: 40.54px;
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color2);
  margin-bottom: 28px;
  background-color: var(--color4);
  border-radius: 6px;
  cursor: pointer;
}

.mute {
  margin-bottom: 7px;
  border-radius: 50%;
  cursor: pointer;
  height: 35px;
  width: 35px;
}

.restaurant-tittle-container{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 15px;
}

.restaurant-tittle-container .restaurant-name-rating{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.res-star-rating {
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-restaurant-name {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.star-img-order {
  height: 16px;
  width: 16px;
  object-fit: contain;

  @media screen and (max-width: 600px) {
    width: 14.41px;
    height: 10.41px;
  }
}

.filters-scroll {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media screen and (max-width: 600px) {
    scrollbar-width: none;
  }
}

.order-filters-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  width: 100%;
}

.order-filters-scroll::-webkit-scrollbar {
  height: 2px; 
}

.order-filters-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.order-filters-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

.order-filters-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.img-rating-restaurant {
  display: flex;
  gap: 5px;
  align-items: center;
}

.rating-order {
  font-family: Poppins;
  margin-right: 13px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
}

.reviews-restaurant {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
}

.rest-description {
  width: 96.7%;
  font-family: var(--font-family);
  font-size: 9px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: left;
  margin-top: 10px;
  color: #6d7383;
}

.filters {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  width: max-content;
  padding: 0 15px;
  height: 54px;
  background-color: #fafcff;
  border-top: 1px solid #e5e8f1;
  border-bottom: 1px solid #e5e8f1;
  margin-top: 10px !important;
}

.bg-css {
  height: 29px;
  background-color: var(--color2);
  border-radius: 22px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  padding: 7px 14px;
}

.active-filter{
  background-color: var(--color7);
  color: var(--color2);
}

.filter-icon {
  margin-right: 10px;
  height: 18px;
  width: 20px;
}

.scrollable-container {
  height: 386px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
}

.scrollable-container::-webkit-scrollbar {
  width: 5px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #cecdcd);
  border-radius: 5px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, #555);
}

.item-container-order {
  margin-top: 15px;
  display: flex;
  gap: 25px;
  width: 100%;
}

.item-details-order {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 5px;
  width: 100%;
}

.item-row1 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.reddot-iten-name {
  display: flex;
  gap: 5px;
  align-items: center;
}

.item-row2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.desc-add-item {
  display: flex;
  gap: 10px;
}

.item-reddot-order {
  width: 12.57px;
  height: 12.57px;
}

.item-img-order {
  width: 95.5px;
  height: 92.15px;
}

.items-name-order {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.item-cost-order {
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.03em;
  text-align: left;
}

.star-rating-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item-star-order {
  width: 14.58px;
  height: 14.58px;
  object-fit: contain;
}

.item-rating-order {
  width: 21px;
  height: 14px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: var(--color1);
}

.item-review-order {
  width: 64.7px;
  height: 13.67px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: var(--color1);
}

.item-desc-order {
  width: 154.14px;
  height: 32.67px;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 10.5px;
  color: #6d7383;
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 84px;
  height: 29px;
  border: 1px solid var(--color7);
  background-color: #ffeeee;
  border-radius: 6px;
  padding: 1px 10px;
  margin-left: 60px;

  @media (max-width: 400px) {
    margin-left: 0;
  }

}

.quantity-controls span {
  background: none;
  border: 1px 0px 0px 0px;
  color: var(--color7);
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
}

.quantity-controls span:hover {
  opacity: 0.7;
}

.quantity-number {
  color: var(--color7);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.item-add-btn {
  width: 70px;
  height: 27.26px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  border: 1px solid var(--color6);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

#horizantal-line-order {
  width: 230px;
  height: 1px;
  border-bottom: 1px dashed var(--blk-color5);
}

.red-box-review-cart {
  height: 60px;
  width: 100%;
  background-color: var(--color4);
  color: var(--color2);
  padding: 5px 10px;
  border-radius: 0px 0px 12px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 0 30px;
}

.red-box-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.review-cart-link {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: var(--review-cart-link);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.cart-arrow {
  width: 10px;
  height: 17px;
}

.style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 530px;
  background-color: var(--color2);
  box-shadow: 24px;
  border-radius: 12px;
  border: none;
  outline: none;
}

.more-btn {
  font-family: var(--font-family);
  font-size: 9px;
  font-weight: 600;
  background: none;
  cursor: pointer;
  border: none;
}

.cross-icon-order {
  position: relative;
  top: -30px;
  left: 20px;
  cursor: pointer;
}

.cross-icon-order path {
  fill: black;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .order-btn {
    width: 90.52px;
    height: 30.54px;
    margin-left: -60px;
  }

  .style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .scrollable-container {
    width: 100%;
    height: 366px;
  }

  .item-container-order {
    gap: 5px;
  }

  .border {
    border-top: 1px dashed #D9D9D9;
    margin: 10px 0;
    width: 100%;

  }

  .border:last-of-type {
    border-top: none;
  }


  .item-details-order {
    margin: 0;
  }

  .item-img-order {
    width: 60.5px;
    height: 60.15px;
    object-fit: contain;
  }

  .style {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 100%;
    height: 500px;
    padding: 15px 0 0;
    box-shadow: 24px;
    border-radius: 20px 20px 0 0;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
  }

  .style.animate {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
    transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .style.close {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
  }

  .filters {
    width: 450px;
  }

  .filters-scroll {
    overflow-x: auto;
  }

  .bg-css {
    font-size: 10px;
    font-weight: 400;
  }

  .red-box-review-cart {
    height: 74px;
    border-radius: 14px 14px 0 0;
  }
}