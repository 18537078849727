.menu-header .vendor-top-menubar-container {
    width: 100%;
    height: 130px;
    background-color: var(--bg-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: fixed;
    top: 0;
    z-index: 3;
    /* background-image: url("../../../assets/vendortopbackground.png"); */
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  /* location container css */
  .vendor-top-menubar-container .vendor-turn-on-location-container {
    width: 200px;
    margin-top: 24px;
    display: flex;
    gap: 10px;
  }
  
  
  .vendor-turn-on-location-container .vendor-turn-on-location {
    width: 90px;
    height: 90px;
  }

  .vendor-turn-on-location img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .vendor-turn-on-location-container .vendor-turn-on-location-description {
    font-family: var(--font-family);
    color: var(--color2);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.03em;
  }
  
  .vendor-top-menubar-container .vendor-search-container {
    margin-top: 50px;
    width: 35%;
    height: 40px;
    border: 1px solid red;
    display: flex;
    gap: 10px;
    background: var(--color2);
    box-shadow: 0px 2px 4px 0px var(--input-field-box-shadow-color);
    border-radius: 8px;
    border: none;
    padding: 0 10px;
  }
  
  .vendor-search-container .vendor-input-field {
    width: 90%;
    border: none;
    background: var(--color2);
    outline: none;
  }
  
  .vendor-search-container .vendor-search-icon {
    height: 17px;
    width: 17px;
    margin-top: 12px;
    margin-left: 7px;
  }
  
  ::placeholder {
    width: 100%;
    color: var(--placeholder-color);
    font-family: var(--font-family);
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
  }
  
  .vendor-search-container .vendor-verticle-line {
    width: 26px;
    color: var(--verticle-line-color);
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .vendor-search-container .vendor-mic-icon {
    width: 18px;
    height: 18px;
    margin-top: 12px;
    margin-left: -15px;
    color: var(--color7);
  }  
  
  @media only screen and (max-width: 600px) {
    .menu-header .vendor-top-menubar-container {
      height: 175px;
      margin-top: 0px;
      border-radius: 0px;
    }
    .vendor-top-menubar-container .omf-container {
      display: initial;
    }
    .vendor-top-menubar-container .vendor-turn-on-location-container {
      display: initial;
    }
    .vendor-top-menubar-container .vendor-search-container {
      display: flex;
      margin-top: 10px;
    }
    .vendor-topbar-menu-icon {
      display: initial;
    }
    .vendor-restaurant-logo {
      width: 70px;
      height: 70px;
    }
  }
  