.cross-svg-cook-inst {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.cook-inst-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cook-inst-main-container .textarea-container{
  width: 100%;
  position: relative;
}

.textarea-container textarea{
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.cook-inst-title {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.cook-inst-title .close-icon{
  cursor: pointer;
}

.title-cooking-inst {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  color: #444444;
}

.input-cooking-inst {
  width: 100%;
  height: 119px;
  border-radius: 8px;
  border: 1.5px solid var(--Neutral-n200, #e2e8f0);
  padding: 10px;
  outline: none;
}

.input-cooking-inst::placeholder {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: var(--cooking-instruction-placeholder-color);
}

.submit-btn-cooking-inst {
  margin-top: 15px;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  background-color: var(--color4);
  color: var(--color2);
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .cross-svg-cook-inst {
    margin-left: 93%;
    margin-top: 0px;
  }

  .title-cooking-inst {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #444444;
  }
}
