.Follow_To_Watch_Reels_Container {
  width: 100%;
  position: relative;
  display: flex;
  gap: 5px; 
  flex-direction: column;
}

.follow_To_Watch_Reels {
  font-family: poppins;
  /* font-size: 14px; */
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-left: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blk-color5);
}
.Button_text {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color2);
}
.botton-text-container {
  /* width: 29px;
height: 29px; */
  /* width: 75px;
  height: 29px;
  top: 280px;
  left: 31px; */
  margin-top: 5px;
}
.Follow_Button {
  /* width: 54px;
height: 21px; */
  /* width: 75px;
  height: 25px; */
  /* top: 281px; */
  margin-left: 5px;
  border-radius: 6px;
  cursor: pointer;
  background: #ff9d01;
  border: none;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 12px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0%;
  text-align: center;
}

.person_image {
  /* width:60px;
height: 60px; */
  width: 105px;
  height: 105px;
  top: 186px;
  left: 18px;
  object-fit: cover;
  border-radius: 50px;
  cursor: pointer;
}
.person-cards-container {
  /* width: 100%; */
  display: flex;
  /* margin-left:20px;
    margin-right:20px; */
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  position: relative;
}

.person-card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  width: 105px;
  justify-content: space-between;
  white-space: normal; /* Allow text to wrap if needed */
}
.channel-name {
  color: rgba(29, 29, 33, 1);
  margin-top: 10px;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 600;

  line-height: 15px;
  letter-spacing: 0%;
  min-height: 15px;
  text-align: center;
}
.followers-count {
  color: rgba(29, 29, 33, 1);
  font-family: Poppins;
  margin-top: 5px;
  /* margin-left: 5px; */
  font-size: 10px;
  font-weight: 600;
  /* line-height: 15px;
  letter-spacing: 0em;
  text-align: left; */
}

.title-and-arrows {
  /* margin-left:86%;
    display: flex;
    position: relative;
    width:auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrows {
  margin-top: 20px;
  margin-right: 60px;
}

.left-arrow-homepage {
  margin-top: -26px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  color: gray;
  margin-left: -40px;
}
.left-arrow-homepage:hover {
  color: var(--blk-color5);
}
.right-arrow-homepage {
  margin-top: -26px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  color: gray;
  margin-left: 0px;
}
.right-arrow-homepage:hover {
  color: var(--blk-color5);
}

.slider-container {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 20px;
}

.slick-slide {
  display: flex;
  justify-content: center;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  cursor: pointer;
  border-radius: 50%;
}

.disabled-arrow {
  color: grey;
  opacity: 0.5;
  pointer-events: none;
}

.custom-prev {
  left: 0%;
}

.custom-next {
  right: 0%;
}

.custom-arrow svg {
  transition: all 0.3s ease;
  height: 34px;
  width: 34px;
}

.slick-initialized .slick-slide{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.Follow_To_Watch_Reels_Container .slick-slide{
  height: 200px;
}

.slick-slider .slick-list{
  margin: 0 34px;
}

.slick-slide img{
  margin-top: 0;
}

.flex-followers-container {
  display: flex;
  gap: 45px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 34px;
}

@media screen and (max-width: 600px) {
  .Follow_To_Watch_Reels_Container .slick-slide{
    height: 150px;
  }
  .slick-initialized .slick-slide{
    justify-content: flex-start;
  }
  .Follow_To_Watch_Reels_Container {
    width: 100%;
    /* height: 190px; */
    margin-left: 0;
    background: var(--color1);
    gap: 1px;
    border-radius: 0 0 10px 10px;
  }

  .header-top-border {
    width: 100%;
  }

.header-top-border::before {
  content: ""; 
  position: absolute;
  left: 50%; 
  width: calc(100vw - 10vw); 
  height: 1px; 
  background-color: #41475D; 
  transform: translateX(-50%); 
}

  .follow_To_Watch_Reels {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color2);
    padding-top: 10px;
  }
  .person_image {
    width: 60px;
    height: 60px;
  }

  .right-arrow-homepage {
    display: none;
  }
  .left-arrow-homepage {
    display: none;
  }
  .channel-name {
    font-size: 10px;
    margin-top: 4px;
    color: rgba(203, 213, 225, 1);
  }
  .followers-count {
    margin-top: 3px;
    color: rgba(203, 213, 225, 1);
  }
  .person-card {
    width: 60px;
    margin-top: 10px;
    margin: 10px;
  }
  .Botton-text-container {
    width: 29px;
    height: 29px;
  }
  .Follow_Button {
    font-size: 8px;
  line-height: 15px;
  margin-top: 4px;
    /* width: 54px;
    height: 21px; */
  }
  .slick-slider .slick-list{
    margin: 0;
  }
  .flex-followers-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 18px;
  }
  .slider-container{
    padding: 10px 10px;
  }
}
