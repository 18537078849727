.edit-popup {
  position: absolute;
  height: 370px;
  width: 400px;
  background-color: #ffffff;
}
.menu-edit-popup-cross-icon {
  margin-left: 370px;
  z-index: 1;
  cursor: pointer;
}

.edit-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.edit-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1; /* Ensure it's above the blurred background */
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c3142cc;
}
.edit-popup-edit-icon-background {
  border-radius: 50%;
  background-color: #2c3142;
  width: 75px;
  height: 75px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.edit-head1-text {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 40.5px;
  text-align: center;
  color: #444444;
}
.edit-popup-fieldset {
  display: flex;
  flex-direction: row;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #e13535 !important;
}
.edit-popup-next-btn {
  width: 360px !important;
  height: 60px !important;
  background-color: #e13535 !important;
  font-family: Poppins !important;
  font-size: 25px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.root-MuiButton-root {
  color: #ffffff  !important;
}

@media screen and (max-width: 600px) {
  .edit-popup {
    width: 90vw;
  }
  .edit-popup-next-btn {
    width: 80vw !important;
  }
  .menu-edit-popup-cross-icon {
    margin-left: 83vw;
  }
}
