@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.dashboard {
    display: flex;
    flex-direction: row;
    position: relative;
    /* position: sticky;
    top: 0;
    width: 100%; */
}

.vendor-common-container{
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
}

.right-side-bar {
    position: absolute;
    top: 17%;
    left: 80%;

}

.loading {
    --speed-of-animation: 0.9s;
    --gap: 6px;
    --first-color: #4c86f9;
    --second-color: #49a84c;
    --third-color: #f6bb02;
    --fourth-color: #f6bb02;
    --fifth-color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    gap: 6px;
    height: 200px;
    margin-top: 15%;
    margin-left: 40%;
}

.loading span {
    width: 4px;
    height: 50px;
    background: var(--first-color);
    animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
    background: var(--second-color);
    animation-delay: -0.8s;
}

.loading span:nth-child(3) {
    background: var(--third-color);
    animation-delay: -0.7s;
}

.loading span:nth-child(4) {
    background: var(--fourth-color);
    animation-delay: -0.6s;
}

.loading span:nth-child(5) {
    background: var(--fifth-color);
    animation-delay: -0.5s;
}

@keyframes scale {

    0%,
    40%,
    100% {
        transform: scaleY(0.05);
    }

    20% {
        transform: scaleY(1);
    }
}

@media only screen and (max-width: 600px) {
    .dashboard {
        display: flex;
        height: 100vh;
        flex-direction: column-reverse;
        justify-content: space-between;
    }

    .loading {
        --speed-of-animation: 0.9s;
        --gap: 6px;
        --first-color: #4c86f9;
        --second-color: #49a84c;
        --third-color: #f6bb02;
        --fourth-color: #f6bb02;
        --fifth-color: #2196f3;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        gap: 6px;
        height: 200px;
        margin-top: 60%;
        margin-left: 25%;
    }

    .loading span {
        width: 4px;
        height: 50px;
        background: var(--first-color);
        animation: scale var(--speed-of-animation) ease-in-out infinite;
    }

    .loading span:nth-child(2) {
        background: var(--second-color);
        animation-delay: -0.8s;
    }

    .loading span:nth-child(3) {
        background: var(--third-color);
        animation-delay: -0.7s;
    }

    .loading span:nth-child(4) {
        background: var(--fourth-color);
        animation-delay: -0.6s;
    }

    .loading span:nth-child(5) {
        background: var(--fifth-color);
        animation-delay: -0.5s;
    }

    @keyframes scale {

        0%,
        40%,
        100% {
            transform: scaleY(0.05);
        }

        20% {
            transform: scaleY(1);
        }
    }
}


.active .fill-red path,
.active .fill-red,
.active h3{
    fill: #e13535 !important;
}

.active .stroke-red path {
    stroke: #e13535 !important;
}

.active .list-text,
.active .vendor-list-text,
.active .vendor-list-text-mobile{
    color: #e13535 !important;
}

.fill-red path,
.fill-red,
h3 {
    fill: #FFFFFF !important;
}

.stroke-red path {
    stroke: #FFFFFF !important;
}

.list-text ,
.vendor-list-text,
.vendor-list-text-mobile{
    color: #FFFFFF !important;
}

.fill-green {
    fill: #FFFFFF !important;
}

.active .fill-green {
    fill: #02A750 !important;
}

.icon-underline{
    display: none;
}

@media screen and (max-width: 600px) {
    .active .icon-underline{
        display: block;
        background-color: #F00808;
        height: 4px;
        width: 48px;
        border-radius: 28px 28px 0 0;
    }
}

.fill-green path{
    fill: #02A750 !important;
}

.no-item-found{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}