.order-card-main-container {
  /* width: 500px;
    border-radius: 8px;
    margin-top: 110px;
    margin-left: 25%;
    padding-bottom: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
  width: calc(100% - 450px);
  margin: 110px 250px 0 200px;
  padding-left: 10px;
}
.previous-orders-heading {
  font-family: poppins;
  font-size: 18px;
  font-weight: 1000;
  line-height: 35px;
  margin-left: 22px;
  margin-top: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blk-color5);
}

.restaurant-img {
  width: 70px;
  height: 70px;
}

.restaurant-name-delivered-container {
  display: flex;
  align-items: center;
}

.restaurant-title {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  /* color: var(--color7); */
}

.restaurant-area {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #6d7383;
}

.delivered-container {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 110px;
}

.restuarant-item-container {
  margin-top: 30px;
}

.price-and-btns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.two-btns {
  display: flex;
  gap: 8px;
}

.btn-rate-order {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  color: var(--color1);
  background-color: var(--color2);
  border: none;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
}

.btn-reorder {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  color: var(--color2);
  background-color: var(--color4);
  border: none;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
}

.no-orders-to-load {
  margin-top: 50px;
  text-align: center;
  font-family: var(--font-family);
}
.loader-infinite-scroll {
  text-align: center;
  font-family: var(--font-family);
}

.order-card-container .restaurant-information-container{
  width: 100%;
}

.restaurant-name-delivered-container .delivered-status{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.order-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px; /* Space between cards */
  max-width: 1000px;
  width: 100%;
  margin-top: 15px;
}

.order-card-container {
  width: calc(50% - 20px); /* Two cards per row */
  max-width: 450px;
  border-radius: 8px;
  display: flex;
  gap: 15px;
  padding: 10px;
  background-color: var(--bg-color2);
  box-sizing: border-box;
  cursor: pointer;
}

.order-card-container .restaurant-img{
  border-radius: 12px;
}

/* Responsive Design */
@media only screen and (max-width: 980px) {
  .order-card-main-container {
    width: calc(100% - 200px);
    margin: 110px 0 0 200px;
  } 
} 
@media only screen and (max-width: 768px) {
  .order-card-container {
    width: 100%; 
  }
}

@media only screen and (max-width: 600px) {
  .order-card-main-container {
    width: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin-left: 0;
    margin: 0;
    padding: 15px;
    padding-bottom: 150px;
  }

  .order-card-container {
    width: 100%;
    border-radius: 8px;
    display: flex;
    gap: 15px;
    padding: 10px;
    margin-top: 20px;
    background-color: var(--bg-color2);
  }

  .delivered-container {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: 40px;
  }

  .price-and-btns-container {
    display: flex;
    margin-top: 20px;
  }
}
