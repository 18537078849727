@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&display=swap');

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('../../assets/web\ \ login\ background.jpg') no-repeat center
    center/cover;
  position: relative;

  .background {
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(44, 49, 66, 0.89) 25.52%,
      rgba(44, 49, 66, 0.5963) 100%
    );
    z-index: 100;
  }

  .login-container {
    position: relative;
    z-index: 101;
    background: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    max-width: 450px;
    width: 100%;
    text-align: center;

    .heading {
      margin: 10px 0 20px;
      font-size: 25px;
      color: #363636;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.5%;
    }

    .form-group {
      position: relative;
      margin: 0 0 20px;

      .label-text {
        display: block;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: 1%;
      }

      input {
        width: 100%;
        padding: 12px 16px;
        border: 1.5px solid var(--Neutral-n200, #e2e8f0);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 1%;
      }

      .password-container {
        position: relative;
        width: 100%;
        height: auto;

        input {
          padding: 0.75rem 2.4rem 0.75rem 0.75rem;
        }

        .toggle-password {
          position: absolute;
          top: 30%;
          right: 10px;
          cursor: pointer;
          color: #888;
        }
      }

      .input-error {
        border: 1px solid red;
      }

      .error-message {
        color: red;
        font-size: 12px;
        position: absolute;
        bottom: -20px; 
        left: 0;
        width: 100%;
        text-align: left;
        padding-left: 4px;
      }
    }

    .form-options {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      letter-spacing: 1.25%;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 1.5rem;

      .forgot-password {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 1.25%;
        border: none;
        outline: none;
        background-color: #fff;
        color: #3c9afb;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .login-btn,
    .signup-btn {
      display: block;
      width: 100%;
      padding: 11px 0;
      /* margin: 22px 0; */
    }

    .login-btn {
      background-color: #e13535;
      color: #fff;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0%;
      text-align: center;
      cursor: pointer;
      border: none;
      border-radius: 6px;
      margin-top: 34px;
    }

    .signup-btn {
      background-color: #f1f1f1;
      color: #1e293b;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0%;
      text-align: center;
      cursor: pointer;
      border: none;
      border-radius: 6px;
      margin-top: 22px;
    }

    .or-login-with {
      margin-top: 34px;
      margin-bottom: 41px;

      p {
        margin-bottom: 20px;
        color: #64748b;
        font-family: "Gabarito", serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 1%;
        text-align: center;
      }

      .social-buttons {
        display: flex;
        justify-content: center;

        .google-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0.5rem;
          border: none;
          border-radius: 5px;
          font-size: 0.9rem;
          margin: 0 0.5rem;
          cursor: pointer;
          background-color: #db4437;
          color: white;
          gap: 10px;

          .icon {
            font-size: 20px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .terms {
      font-size: 10px;
      font-family: var(--font-family);
      color: #1e293b;
      line-height: 15px;
      font-weight: 500;
      padding-inline: 10px;

      a {
        color: #007bff;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.mobile-login-header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
  min-height: 25vh;
}
.mobile-login-header .login-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  padding-inline: 25px;
}

.login-title .left-side-arrow {
  width: 14.71px;
  height: 25px;
}

.login-text {
  margin-top: 35px;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 18.6px;
  letter-spacing: 0%;
  color: #a4abc4;
}

.login-text p {
  padding-inline: 25px;
}

.login-title h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.5%;
  text-align: center;
  color: #fff;
  margin: 0;
}

@media (max-width: 576px) {
  .login-page {
    display: flex;
    flex-direction: column;
    height: 30%;
  }
  .login-page .login-container {
    width: 100%;
    max-width: 100%;
    border-radius: 18px 18px 0px 0px;
    position: sticky;
    top: 50%;
    min-height: 75vh;
    height: 100%;
  }
}
