@media screen and (min-width: 980px) {
  .vendor-registration-right-bar-main {
    font-family: Poppins;
    position: absolute;
    height: 100%;
    width: 250px;
    margin-top: 130px;
    top: 0;
    right: 0;
    border-left: 1px solid #eaeaea;
    text-align: left;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: none;
  }
  .vendor-registration-right-bar-content1 {
    font-size: 13px;
    font-weight: 700;
  }
  .vendor-registration-right-bar-content {
    font-size: 13px;
    font-weight: 400;
  }
}
@media screen and (max-width: 980px) {
  .vendor-registration-right-bar-main {
    display: none;
  }
}
