.topbar-container {
  width: calc(100% - 200px);
  margin: 0 0px 0 200px;
  height: 82px;
  background-color: var(--bg-color);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 22px; 
  position: fixed;
  top: 0;
  z-index: 2;
  gap: 10px;
}

.back-icon {
  display: none;
}

.user-modal-web{
  display: block;
  width: 75px;
}

.user-modal-mobile {
  display: none;
}

.location-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.location-svg-icon {
  width: 19px;
  height: 23px;
}

.turn-on-location-container {
  margin-left: 5px;
  gap: 5px;
  display: flex;
  align-items: center;
}

.turn-on-location {
  font-family: var(--font-family);
  font-size: 0.8rem; 
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.03em;
  text-align: left;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-down-arrow{
  width: 25px;
}

.filters-topbar1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  background-color: #f0f0f0;
  margin-left: -10px;
  padding: 10px;
}
.filter-options-cont {
  display: flex;
  gap: 1px;
  margin-left: 40%;
}

.filter {
  z-index: 99;
  padding: 8px 16px;
  width: 30%;
  position: initial;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filter.selected {
  background-color: #007bff;
  color: red;
}

.search-container {
  align-items: center;
  width: 40%;
  height: 45px;
  border: 1px solid red;
  display: flex;
  gap: 10px;
  background: var(--color2);
  box-shadow: 0px 2px 4px 0px var(--input-field-box-shadow-color);
  border-radius: 8px;
  border: none;
  padding: 0 10px;
  /* margin-left: -195px; */
}

.search-container .search-tab{
  display: flex;
    width: 100%;
    gap: 10px;
}
.search-container .search-tab-mobile{
  width: 100%;
  display: none;
}

.input-field {
  width: 90%;
  border: none;
  background: var(--color2);
  outline: none;
}

.search-icon {
  height: 20px;
  width: 20px;
}

::placeholder {
  width: 100%;
  color: var(--placeholder-color);
  font-family: var(--font-family);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
}

.verticle-line {
  color: var(--verticle-line-color);
}

.mic-icon {
  width: 20px;
  height: 20px;
  display: flex;
  color: var(--color7);
  cursor: pointer;
}

.qr-code {
  display: none;
}

.notification-icon {
  width: 31.67px;
  height: 28.28px;
  cursor: pointer;
}

.three-dot-menu{
  display: none;
}

.globe-icon {
  width: 31.67px;
  height: 28.28px;
  margin-top: 26px;
  margin-left: 10px; 
  cursor: pointer;
  position: relative;
}

.languages-list {
  width: 80px;
  height: 70px;
  background-color: var(--color2);
  position: absolute;
  top: 60px;
  left: 1165px;
  z-index: 1;
  text-align: center;
  border-radius: 8px;
  transition: all 0.2s;
  display: none;
}

.languages-list.active {
  display: initial;
}

.language-btn {
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: 600;
}

.language-btn:hover {
  background-color: rgb(220, 146, 10);
  color: var(--color2);
  border-radius: 5px;
}

.notification-profile{
  display: flex;
  gap: 10px;
  width: 100%;
}

.topbar-container1 {
  display: none;
}

.arrow-to-point-search {
  position: absolute;
  top: 73px;
  left: 78%;
  transform: rotateX(0%);
  rotate: 225deg;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid transparent;
  box-shadow: 4px px 0px 0px #0000001a;
}

.overlay-popup-container-search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 450px;
  background-color: #ffffff;
  z-index: 9999;
  margin-top: 265px;
  margin-left: 130px;
  border-radius: 4px;
  box-shadow: 8px 0 8px rgba(0, 0, 0, 0.5),
    -8px 0 8px rgba(0, 0, 0, 0.5),
     0 8px 8px rgba(0, 0, 0, 0.5);
}

.notification-overlay {
  position: absolute;
  top: 82px;
  right: 0;
  min-width: 30%;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 15px;
  z-index: 9999; 
  height: calc(100vh - 180px);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 600px) {
    top: 50px;
    min-width: 100%;
    height: calc(100vh - 195px);
    border-radius: 15px 15px 0 0;
  }
}

.arrow-to-point-notification {
  display: none;
}

.mic-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.mic-icon {
  transition: color 0.3s ease;
  z-index: 10; 
}

.listening .mic-icon {
  color: red; 
}

.wave-container {
  position: absolute;
  top: -10%;
  left: -3px;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.wave {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid rgba(255, 0, 0, 0.5);
  opacity: 0;
  animation: waveEffect 2s infinite;
}

.wave:nth-child(2) {
  animation-delay: 0.5s;
}

.wave:nth-child(3) {
  animation-delay: 1s;
}

@keyframes waveEffect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.select-location-heading {
  font-size: 16px;
  font-family: var(--font-family);
  text-align: center;
  
}

.cross-icon-location {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.cross-icon-location path {
  fill: black;
}

.location-gps-container {
  margin-top: 20px;
  padding: 10px 0;
  border: 1px solid black;
  width: 100%;
  height: 80px;
  display: flex;
 justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.location-gps-container:hover .location-text {
  color: red;
}

.get-location-text-container {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  align-items: center;
  cursor: pointer;
}

.location-text {
  font-size: 14px;
  font-family: var(--font-family);
}

.gps-text {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 600;
}

.three-dot-menu{
  display: none;
}

.location-popup-container{
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.location-popup{
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 550px;
  height: 500px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
  .globe-icon {
    display: none;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .topbar-container{
    width: calc(100% - 200px);
    gap: 10px; 
    margin: 0 0 0 200px;
    height: 82px;
    justify-content: space-between;
  }

  .back-icon {
    display: none;
  }

  .location-container {
    display: flex;
    align-items: center;
  }

  .turn-on-location {
    font-size: 0.6rem;
  }
  .turn-on-location-description {
    font-size: 0.5rem;
  }

  .globe-icon {
    display: none;
  }

  .arrow-to-point-search {
    position: absolute;
    top: 71px;
    left: 73%;
    transform: rotateX(0%);
    rotate: 225deg;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid #ffffff;
    border-left: 15px solid transparent;
    box-shadow: 4px px 0px 0px #0000001a;
  }

  .overlay-popup-container-search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 450px;
    background-color: #ffffff;
    z-index: 9999;
    margin-top: 260px;
    margin-left: 75px;
    box-shadow: 0px 4px 4px 0px #0000001a;
    border-radius: 4px;
  }
}

@media screen and (min-width: 601px) {
  .location-popup-container{
    top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .topbar-container {
    width: 100%;
    margin: 0;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    gap: 12px;
    background-color: #2C3142;
    padding: 0 10px;
  }

  .location-search-icon{
    background-color :#e13535;
    height: 50px;
    width: 50px;
    padding: 10px;
    position : absolute;
    right: 0;
    top: 17%;
    padding: 5px;
    cursor: pointer;
    display:flex;
    align-items: center;
   justify-content:center;
  }

  .wide{
    width: 90% !important;
    margin-left: 10px;
  }
  .back-icon {
    display: initial;
    width: 16px;
    height: 27px;
  }

  .location-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .turn-on-location-container{
    display: none;
  }

  .location-svg-icon {
    width: 18.42px;
    height: 23px;
    margin-top: 0;
    margin-left: 12px;
  }

  .turn-on-location {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 600px;
    line-height: 18px
  }

  .location-popup-container{
    bottom: 75px;
    height: 500px;
    transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
  }
  .location-popup{
    padding: 15px;
    width: 100%;
    height: 100%;
    border-radius: 14px 14px 0 0;
  }

  .location-gps-container{
    margin-top: 10px;
  }
  
  .search-container {
    width: 80%;
    height: 44px;
  }

  .search-container .search-tab{
    display: none;
  }
  .search-container .search-tab-mobile{
    display: flex;
  }
  .search-icon-mobile{
    display: none;
  }

  .three-dot-menu{
    display: block;
    width: 25.83px;
    height: 30.6px;
  }
  
  .globe-icon {
    display: none;
  }

  .overlay-popup-container-search {
    width: 350px;
    height: 500px;
    margin-top: 310px;
    margin-left: 0px;
  }

  .notification-profile{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mobile-notification-profile-common{
    display: flex;
    gap: 20px;        
    padding: 10px 40px;
    margin: 0 40px;
  }

  .mobile-notification-profile-text{
    color: var(--color2);
    text-transform: uppercase;
  }

  .modal-container {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    background-color: #161821;
    width: 100%;
    box-shadow: 24px;
    border: none;
    border-radius: 0 0 20px 20px;
    opacity: 0;
    transition: transform 0.5s ease-in-out;
}

.modal-container.animate {
    transform: translateX(-50%) translateY(-80px);
    opacity: 1;
}

.modal-container.close {
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
}

.user-modal-web{
  display: none;
}

.user-modal-mobile {
  display: block;
}
}


.title-and-cross-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 20px; */
}