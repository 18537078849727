/* FilterButton.css */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: var(--color4);
}
.css-10d9dml-MuiTabs-indicator {
  background-color: var(--color4) !important;
}
.filters-apply-btn {
  background-color: var(--color4) !important;
}

.filter-button-container {
  position: relative;
  display: inline-block;
}
.checkbox--1 {
  display: flex;
  flex-direction: column;
  /* display: block !important; */
}
.filter-button {
  padding: 8px 16px;
  background-color: #f9f9f9;
  color: #333;
  border: 1px solid #ddd;
  cursor: pointer;
}

.filter-options {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.filter-option {
  display: block;
  padding: 8px 16px;
  color: #333;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.filter-option:last-child {
  border-bottom: none;
}

.filter-button-container:hover .filter-options {
  display: block;
}
.css-ahj2mt-MuiTypography-root {
  font-family: Poppins !important;
}

.css-mhc70k-MuiGrid-root .MuiGrid-item {
  padding-left: 60px;
  padding-top: 25px;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  margin: 0;
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 1.25rem;

  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.width-of-dialog-box {
  width: 500px;
  max-width: 500px;
  min-width: 500px;
  max-height: 300px;
  min-height: 300px;
}

@media (max-width: 600px) {
  /* .Filters-main-container { */
    /* Set the width to 90% of the viewport width */
    /* margin: 0 auto !important; 
    margin-left: 45% !important; */
  /* } */
  .MuiTabs-root {
    margin-left: -20px;
    width: 90px; /* Set the width of the tabs */
  }

  /* Reduce the font size of the tab labels */
  .MuiTab-root {
    font-size: 12px; /* Set the font size of the tab labels */
  }

  /* Decrease the content area width */
  .MuiDialogContent-root {
    max-width: 100%; /* Set the maximum width of the content area */
  }
  .css-mhc70k-MuiGrid-root .MuiGrid-item {
    padding-left: 14px;
    padding-top: 25px;
  }
  .checkbox--1 {
    display: flex;
    flex-direction: column;
  }
  .width-of-dialog-box {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    max-height: 400px;
    min-height: 400px;
  }

  .css-mhc70k-MuiGrid-root {
    margin-left: 0;
  }
}
