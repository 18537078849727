.restaurantnearyou-Main-container-11 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top:-10px; */
  margin-top: -5px;
  z-index: 1;
}

.restaurantnearyou-head-container-11 {
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.restaurantnearyou-head-text-11 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 1000;
  line-height: 21px;
  margin-left: 30px;
  color: rgba(0, 0, 0, 1);
}
.restaurantnearyou-card-container-11 {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 1%;
  margin-right: 30px;
  gap: 10px;
}
.restaurantnearyou-card-11 {
  position: relative;
  overflow: hidden;
  /* transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s; */
  height: 220px;
  width: 240px;
  white-space: normal; /* Allow text to wrap if needed */
  border: none;
}
.restaurantnearyou-name-11 {
  width: 153px;
  /* margin-top: 45px; */
}
.restaurantnearyou-text-11 {
  margin-left: 10px;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(29, 29, 33, 1);
}
.restaurantnearyou-image-container-11 {
  width: 220px;
  /* width: 100%; */
  /* height: 120.1px; */
  border-radius: 12px;
}

.restaurantnearyou-image-11 {
  margin-top: 8px;
  margin-left: 10px;
  width: 220px;
  height: 150.1px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  object-fit: cover;
}
.restaurantnearyou-offer-bg-11 {
  width: 80px;
  height: 28px;
  margin-left: 150px;
  margin-top: -153px;
  border-radius: 12px;
  /* border-top-right-radius: 12px;
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0; */
  /* border-top-left-radius: 0%;
border-top-right-radius: 0%; */
  background: rgba(225, 53, 53, 1);
  position: absolute;
}
.restaurantnearyou-image-footer-11 {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  width: 150px;
  height: 25px;
}

.restaurantnearyou-star-icon-image-11 {
  color: rgba(242, 185, 13, 1);
  width: 14.72px;
  height: 13.98px;
  margin-right: 5px;
}

.restaurantnearyou-reviews-11 {
  font-family: poppins;
  font-size: 10.72px;
  font-weight: 400;
  line-height: 15.08px;
  letter-spacing: 0px;
  color: rgba(135, 135, 135, 1);
}

.restaurantnearyou-offer-percentage-11 {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  font-family: poppins;
  font-size: 15px;
  /* font-weight: 770; */
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 1);
}

.Food-search-restaurant-footer {
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.Food-search-restaurant-dist {
  font-family: Poppins;
  font-size: 10.72px;
  font-weight: 400;
  line-height: 16.08px;
  text-align: left;
  color: #878787;
  cursor: pointer;
}

.Food-dist-layout {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Food-search-rest-star-icon-image {
  width: 14.72px;
  height: 13.98px;
  object-fit: contain;
}
.Food-search-rest-reviews {
  color: #878787;
  font-family: Poppins;
  font-size: 10.72px;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .restaurantnearyou-card-container-11 {
    width: 100%;
    margin-top: 15px;
    gap: 25px;
    margin-left: 0;
    margin-right: 0;
  }
  .restaurantnearyou-card-11 {
    width: 320px;
    height: 155px;
    display: flex;
    margin-left: 10px;
    gap: 10px;
  }
  .restaurantnearyou-image-container-11 {
    width: 320px;
    height: 140.1px;
    border-radius: 12px;
  }

  .filters-list-button-container-11 {
    margin-left: 28%;
  }
  .restaurantnearyou-head-text-11 {
    font-weight: 600;
    margin-left: 10px;
    font-size: 14px;
  }
  .restaurantnearyou-image-11 {
    margin-top: 8px;
    margin-left: 10px;
    width: 170px;
    height: 140.1px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    object-fit: cover;
  }
  .restaurantnearyou-card-container-11 {
    margin-left: 5px;
  }
  .restaurantnearyou-offer-bg-11 {
    width: 70px;
    height: 45px;
    margin-left: 100px;
    margin-top: -143px;
    border-radius: 4px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    background: rgba(225, 53, 53, 1);
    position: absolute;
  }
}
