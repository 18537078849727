.payment-main-container {
  width: calc(100% - 450px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-left: 200px;
}

.have-a-coupon-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #53565a;
  margin-top: 10px;
}

.coupon-bg {
  width: 410px;
  height: 56px;
  border-radius: 6px;
  background-color: #ffaa00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}

.text-and-svg {
  display: flex;
  align-items: center;
  gap: 10px;
}
.apply-coupon-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--color1);
}

.delivery-pickup-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.delivery-container {
  width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #d3d4d4;
  border-radius: 6px;
  cursor: pointer;
}
.delivery-container.selected {
  width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--color6);
}
.pickup-container {
  width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #d3d4d4;
  border-radius: 6px;
  cursor: pointer;
}

.pickup-container.selected {
  width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--color6);
}

input {
  accent-color: var(--color6);
}
.input-radio {
  height: 16px;
  width: 50px;
  cursor: pointer;
}

.text {
  font-family: Poppins-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--color1);
  cursor: pointer;
}

/* bill summary */

.bill-container {
  margin-top: 30px;
}

.bill-summary-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #53565a;
}

.bill-bg {
  margin-top: 10px;
  width: 410px;
  /* height: 160px; */
  background-color: var(--color1);
  border-radius: 6px;
}

.remove-btn {
  padding: 6px;
  height: 30px;
  border-radius: 4px;
  background-color: var(--color2);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  color: black;
  border: none;
  cursor: pointer;
}

.price-saving-total-container {
  padding: 20px;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-text {
  font-family: Poppins;
  font-size: 14.51px;
  font-weight: 600;
  color: var(--color2);
}
.amount-text {
  font-family: Inter;
  font-size: 14.51px;
  font-weight: 400;
  color: var(--color2);
}
.horizantal-line {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #41485d;
}

.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.payment-form-component-container {
  margin-bottom: 150px;
}

@media only screen and (max-width: 980px) {
  .payment-main-container {
    width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 600px) {
  .payment-main-container {
    width: 100%;

    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    position: absolute;
    top: 5%;
    left: 0;
    z-index: 1;
    margin-bottom: 150px;
    overflow-y: scroll;
  }

  .payment-mode-text {
    margin-left: 7px;
  }

  .payment-form-component-container {
    width: 90vw;
    margin-bottom: 100px;
  }
  .have-a-coupon-text {
    margin-left: 15px;
  }

  .bill-summary-text {
    margin-left: 18px;
  }
  .coupon-bg {
    width: 90vw;
    margin-left: 15px;
  }

  .delivery-pickup-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .delivery-container {
    width: 40vw;
  }
  .delivery-container.selected {
    width: 40vw;
  }
  .pickup-container {
    width: 40vw;
  }

  .pickup-container.selected {
    width: 40vw;
  }

  .bill-bg {
    width: 90vw;
    margin-left: 18px;
  }

}
