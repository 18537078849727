.google-map-container {
  height: 400px;
  width: 60%;
  margin-left: 18%;
  margin-top: 10%;
}

@media only screen and (max-width: 600px) {
  .google-map-container {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1;
    height: 400px;
    width: 100%;
    margin-left: 0;
  }
}
