.main-container-search-home {
  margin-top: 50px;
  width: 100%;
  margin-left: -30px;
}

@media only screen and (max-width: 980px) {
  .main-container-search-home {
    margin-left: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .main-container-search-home {
    margin-left: 0;
    margin-top: 150px;
  }
}
