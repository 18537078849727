.new-orders-main {
  border-radius: 6px;
}
.single-order {
  display: flex;
  box-shadow: 0px 0px 5px 0px #2c314224;
  border-radius: 6px;
  justify-content: space-between;
  margin-top: 20px;
  padding: 15px 15px 15px 10px;
  align-items: center;
  cursor: pointer;
}

.order-details1 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}
.order-id {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}
.order-details2 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.order-details3 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.orderid {
  text-align: left;
  align-items: left;
}
.date-and-time {
  display: flex;
  align-items: center;
  gap: 15px;
}

.new-orders-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.new-orders-popup-cross-icon {
  margin-left: 60%;
  z-index: 1;
  cursor: pointer;
}
.new-orders-popup {
  width: 62%;
  height: 400px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  z-index: 1;
}
.new-orders-blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c3142cc;
}
.new-orders-popup-top1-background {
  width: 100%;
  height: 70px;
  padding: 15px;
  padding-left: 25px;
  border-radius: 6px 6px 0px 0px;
  background-color: #2c3142;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new-orders-popup-order-id-text {
  color: #ffffff;
  font-family: Poppins;
  font-size: 23px;
  font-weight: 400;
}
.new-orders-popup-order-id {
  font-family: Poppins;
  font-size: 23px;
  font-weight: 600;
}
.new-orders-popup-top1-right {
  justify-content: space-around;
  display: flex;
  gap: 40px;
}
.new-orders-popup-time {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.new-orders-popup-top2 {
  background-color: #ffffff;
  padding: 15px;
  padding-left: 25px;
}
.new-orders-popup-headings-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.new-orders-popup-item-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-align: left;
}
.new-orders-popup-qty-container {
  display: flex;
  width: 20%;
  justify-content: space-between;
  margin-right: 60px;
}

.new-orders-popup-qty-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #e13535;
}
.new-orders-popup-price-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.new-orders-popup-horizontal-line {
  border: 1px dotted #444b62;
  margin-top: 3px;
}
.new-orders-popup-item-id {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  color: #2c3142;
}
.new-orders-popup-item-qty {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  color: #e13535;
  text-align: left;
}
.new-orders-popup-item-price {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  text-align: right;
  color: #2c3142;
}
.new-orders-popup-item-container {
  display: flex;
  justify-content: space-between;
}
.new-orders-popup-instructions-heading {
  margin-top: 3px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  color: #2c3142;
}
.new-orders-popup-instructions-content {
  margin-top: 3px;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-n400, #94a3b8);
}
.new-orders-popup-btns-container {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.new-orders-popup-decline-btn {
  width: 239px;
  height: 74px;
  border-radius: 6px;
  background-color: #e13535;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.new-orders-popup-accept-order-btn {
  cursor: pointer;
  width: 555px;
  height: 74px;
  border-radius: 6px;
  background-color: #34a853;
  border: none;
  color: #ffffff;
}
.second-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.second-popup {
  width: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  align-items: center;
}
.second-popup-heading {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  color: #2c3142;
}
.new-orders-second-popup-cross-icon {
  margin-left: 380px;
  z-index: 1;
  cursor: pointer;
}
.second-popup-minus-plus-btn {
  width: 80.85px;
  height: 60.84px;
  border-radius: 5px;
  background-color: #878faf;
  border: none;
  color: #ffffff;
}
.second-popup-time-btn {
  width: 119.29px;
  height: 60.84px;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  background-color: #2c3142;
}
.second-popup-settime-btn {
  width: 300px;
  height: 60.04px;
  border-radius: 6px;
  background-color: #02a750;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.second-popup-btns-container2 {
  display: flex;
  gap: 5px;
}

.no-data-lazy-loading-text-infinite-scroll {
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  margin-top: 40px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #fff; /* Optional: Keeps it visually distinct */
  position: sticky;
  bottom: 0;
}

.view-new-order-button-vendor{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 80px;
  background-color: #e13535;
  color: #ffffff;
  font-size: 14px;
}


@media screen and (max-width: 600px) {
  .single-order {
    width: 98vw;
  }
  .new-orders-popup {
    width: 94vw;
  }
  .new-orders-popup-cross-icon {
    margin-left: 80vw;
  }
  .second-popup {
    width: 90vw;
  }
  .new-orders-second-popup-cross-icon {
    margin-left: 80vw;
  }
  .new-orders-popup-order-id-text {
    font-size: 14px;
  }
  .new-orders-popup-order-id {
    font-size: 16px;
  }
  .new-orders-popup-time {
    font-size: 12px;
  }
  .new-orders-popup-instructions-content {
    font-size: 10px;
  }
  .dashboard-order-count {
    font-size: 30px;
  }
  .order-details1 {
    font-size: 16px;
  }
  .order-details2 {
    font-size: 12px;
  }
  .order-details3 {
    font-size: 12px;
  }
}
