.Prev-orders-Main-container {
  width: 100%;
  position: relative;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.previous-orders-title-homepage {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-left: 22px;
  color: var(--blk-color5);
}

.order-cards-container {
  /* display: flex; */
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  position: relative;
}

.order-card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 105px;
  margin-top: 15px;
  cursor: pointer;
}

.order-card .previous-order-image{
  width: 100px;
  height: 100px;
}

.item-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 8px;
}

.items-name {
  color: rgba(29, 29, 33, 1);
  margin-top: 10px;
  font-family: poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 13.81px;
  letter-spacing: 0px;

}

.Order-Button {
  /* width: 75px;
  height: 25px; */
  border-radius: 6px;
  cursor: pointer;
  background: rgba(225, 53, 53, 1);
  font-family: poppins;
  border: none;
  color: #ffffff;
  font-size: 12px;
  line-height: 23px;
  font-weight: 400;
  padding: 3px 12px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  cursor: pointer;
  border-radius: 50%;
}

.custom-prev {
  left: 0%;
}

.custom-next {
  right: 0%;
}

.custom-arrow svg {
  color: #e13535 ;
  transition: all 0.3s ease;
  height: 34px;
  width: 34px;
}

.order-card .button-text-container{
  margin-top: 5px;
}

.flex-orders-container {
  display: flex;
  gap: 45px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 20px;
}

.order-cards-container .slider-component{
  margin: 0 10px 0 20px;
}

@media screen and (max-width: 600px) {
  .Prev-orders-Main-container {
      width: 100%;
      margin-left: 0;
  }

  .previous-orders-title-homepage {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      /* color: var(--color2); */
  }

  .order-card {
      width: 60px;
      margin-top: 5px;
      margin-left: 10px;
  }

  .order-card .previous-order-image{
    width: 71px;
    height: 71px;
  }

  .items-name{
    font-size: 10px;
    margin-top: 7px;
  }

  .Order-Button {
      font-size: 8px;
      line-height: 17px;
  }
  .flex-orders-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 18px;
  }
  .order-cards-container .slider-component{
    margin: 0 10px;
  }
}
