.video {
  position: relative;
  width: 100%;
  object-fit: contain;
  scroll-snap-align: start;
  margin-top: 12px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 100px);
  max-height: 673px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    height: calc(100vh - 154px);
  }
}

.video::before {
  content: "";
  display: block;
  box-shadow: o -33px 48px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
}

.player {
  top: 0;
  left: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.progress-bar {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: rgb(51, 49, 49);
  border-radius: 30px;
  cursor: pointer;
  z-index: 1;
}

.progress {
  height: 100%;
  background-color: gold;
}

.heart-container {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 45%;
}

.heart {
  position: absolute;
  color: white;
  font-size: 40px;
  opacity: 0;
  z-index: 2;
}
.heart.heart-active {
  animation: animate 0.8s linear forwards;
}
@keyframes animate {
  30% {
    font-size: 80px;
    opacity: 1;
  }
  50% {
    opacity: 1;
    font-size: 60px;
  }
  70% {
    font-size: 70px;
  }
  80% {
    font-size: 60px;
    opacity: 1;
  }
  90% {
    font-size: 60px;
    opacity: 1;
  }
}

.play-overlay,
.pause-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.play-overlay svg,
.pause-overlay svg {
  width: 48px;
  height: 48px;
  fill: rgba(255, 255, 255, 0.8);
}

.pause-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.play-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  width: 100%;
  height: 100%;
}

.pause-overlay-all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0.9;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.video-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background: linear-gradient(180deg, rgba(70, 75, 89, 0) 42.16%, #2C3142 100%);
  pointer-events: none;
  /* z-index: 9; */
}
