.post-module-popup{
  width: 747px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;

  @media screen and (max-width: 750px) {
    width: 95%;
  }
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 8px !important;
}

.css-1wc848c-MuiFormHelperText-root{
  margin: 0 !important;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.select-posts-btn {
  display: block;
  margin: 20px 0;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.preview-title-create-post {
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
}

.next-btn-create-post {
  padding: 6px 40px;
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--color2);
  background-color: var(--color4);
  border-radius: 6px;
  cursor: pointer;
  outline: none;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 8px !important;
}

.back-btn-create-post {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 90.52px;
  height: 30.54px;
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--color2);
  background-color: var(--color4);
  border-radius: 6px;
  cursor: pointer;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-popup {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-filters {
  margin-top: 30px;
}
.carousel-last {
  margin-top: 0px;
  padding-top: 50px !important;
}
.carousel div {
  width: 90%;
  display: flex;
  justify-content: center;
}

.img-filters-modal {
  width: 60%;
  background-color: #ffffff;
  border: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
}

.post-detail-model {
  width: 60%;
  background-color: #ffffff;
  border: 1px;
  position: absolute;
  padding: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
}

.filters-container-create-post {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.tags {
  margin-bottom: 16px;
}

.css-13cymwt-control {
  padding: 7px 5px !important;
}

.your-modal-class {
  overflow: visible !important;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  left: 0;
  bottom: -16px;
  font-size: 10px;
  font-family: var(--font-family);
  font-weight: 400;
  color: red !important;
}

.post-module-popup .create-post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.create-post-header .close-icon{
  cursor: pointer;
}

.create-post-header .create-post-title {
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  font-family: var(--font-family);
  font-weight: 500;
}

.post-module-popup .create-new-post {
  display: flex;
  gap: 25px;
  width: 100%;
  margin-top: 20px;
}

.create-new-post .select-img-container-first-popup {
  width: 50%;
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.select-img-container-first-popup .img-video-select-container {
  border: 1px solid #f1f1f1;
  background-color: #fbfbfb;
  border-radius: 15px;
  width: 100%;
  height: 364px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.img-video-select-container .select-img-video-btn {
  cursor: pointer;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
}

.image-upload-part {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-upload-part .video-container{
  position: relative;
  width: 100%;
  height: 100%;
}

.video-container video{
  width: 100%;
  height: 100%;
  background-color: black;
}

.image-upload-part .upload-section {
  width: 51%;
}

.image-upload-part p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family);
  color: #a1a1a1;
}

.image-upload-part img {
  width: 100%;
  height: 100%;
  background-color: black;
  object-fit: cover;
  border-radius: 15px;
}

.image-upload-part .play-btn{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-new-post .post-details-1 {
  width: 50%;
}

.post-details-1 .post-details-box{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.post-details-1 .post-details-name {
  text-align: start;
  font-size: 18px;
  line-height: 22px;
  font-family: var(--font-family);
  font-weight: 500;
  margin-bottom: 15px;
}

.description-box .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 14px 15px;
}

.description-box textarea {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
}

.description-box .css-1wc848c-MuiFormHelperText-root {
  font-size: 10px;
  font-family: var(--font-family);
  font-weight: 400;
}

.description-box .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  font-family: var(--font-family);
  font-weight: 400;
  color: #3F4C79 !important;
  margin: 0 -3px;
}

.post-details-1 .post-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.post-details-1 .tag-box-container {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.tag-box-container .tags-label {
  position: absolute;
  top: -10px;
  left: 12px;
  background-color: #fff;
  padding: 0 4px;
  color: #3f4c79;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  z-index: 99;
}

.tag-box-container .tags-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  border: 1px solid #e5e5e5;
  padding: 9px 15px;
  border-radius: 8px;
  max-height: 75px;
  overflow-y: auto;
  margin-bottom: 25px;
}

.tags-box .css-1py3fkn-MuiButtonBase-root-MuiChip-root {
  height: 22px;
  margin: 0;
}

.tags-box .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-family: var(--font-family);
  font-size: 12px;
  color: #3f4c79;
  font-weight: 400;
  padding: 0;
}

.tags-box .css-6od3lo-MuiChip-label {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
  color: #3f4c79;
}

.tags-box .css-1py3fkn-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  font-size: 18px;
}

.common-restaurant-box
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #3f4c79;
}

.common-restaurant-box .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 15px;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
}

.select-img-container-first-popup .image-slider-container {
  width: 100%;
  height: auto; 
}

.image-slider-container .slider-container {
  height: auto;
  padding: 0;
}

.image-slider-container .slider-container .slick-slider {
  height: auto;
}

.image-slider-container .slider-container .slick-slider .slick-prev,
.image-slider-container .slider-container .slick-slider .slick-next {
  background: #00000080;
  color: #ffffff;
  border: 0.4px solid #ffffff;
  backdrop-filter: blur(10px);
}

.image-slider-container .slider-container .slick-slider .slick-list {
  height: auto;
  margin: 0;
}

.image-slider-container
  .slider-container
  .slick-slider
  .slick-list
  .slick-slide {
  padding: 0 2.5px;
  border: none;
  outline: none;
}

.image-slider-container
  .slider-container
  .slick-slider
  .slick-list
  .image-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 58px;
  border: none;
  outline: none;
}

.image-slide .selected-filter-label{
 /* color: #e13535 !important; */
 background-color: var(--color4) !important;
}

.css-19m5e5t-MuiTypography-root{
  position: absolute ! important;
  bottom: -16px;
  color: red ! important;
  font-size: 10px !important;
  font-family: var(--font-family) !important;
}

.image-slider-container
  .slider-container
  .slick-slider
  .slick-list
  .image-slide
  img {
  width: 90px;
  height: 58px; 
  object-fit: cover; 
  border-radius: 10px; 
}

.original {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.brightness {
  filter: brightness(150%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.contrast {
  filter: brightness(100%) contrast(150%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.saturation {
  filter: brightness(100%) contrast(100%) saturate(150%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.hue {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(180deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.blur {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(5px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}

.sepia {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(100%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.grayscale {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(100%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.invert {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(100%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.opacity {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(50%)
    drop-shadow(rgb(0, 0, 0) 0px 0px 0px);
}
.sharpen {
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(0deg)
    blur(0px) sepia(0%) grayscale(0%) invert(0%) opacity(100%)
    drop-shadow(rgb(0, 0, 0) 1px 0px 0px);
}

.image-slider-container
  .slider-container
  .slick-slider
  .slick-list
  .image-slide
  .label {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-family: var(--font-family);
  font-weight: 500;
  color: #fff;
  padding: 3px;
  border-radius: 5px;
  margin-top: 5px;
  background: #00000033;
  backdrop-filter: blur(4px);
}

.image-slider-container .custom-arrow {
  height: 25px;
  width: 25px;

  img {
    width: 100%;
    height: 100%;
  }
}

.image-slider-container .left-arrow {
  z-index: 99;
  left: -9px;
  top: 50%;
  margin: 0;
}

.image-slider-container .right-arrow {
  z-index: 99;
  right: -10px;
  top: 50%;
  margin: 0;
}

.change-img-btn{
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.create-post-header-mobile{
  display: none;
}

.create-post-line{
  width: 100%;
  height: 1px;
  background-color: #000000;
  opacity: 5%;
}

@media only screen and (max-width: 600px) {

  .post-module-popup {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  .post-module-popup .create-post-header {
    display: none;
  }

  .post-module-popup .create-post-header-mobile{
    display: flex;
    height: 70px;
    background-color: #2C3142;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
  }

  .create-post-header-mobile .back-post{
    display: flex;
    gap: 25px;
  }

  .back-post .create-post-title-mobile{
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .create-post-line {
    display: none;
  }

  .post-module-popup .create-new-post {
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 70px);
    padding: 0 16px 16px 16px;
    margin-top: 16px;
    width: 100%;
  }

  .create-new-post .select-img-container-first-popup {
    width: 100%;
  }  

  .select-img-container-first-popup .image-slider-container{
    width: 100%;
  }

  .create-new-post .post-details-1 {
    width: 100%;
  }
  
  .post-details-1 .post-details-box {
    overflow-y: visible;
  }

  .post-details-1 .post-btn{
    margin-top: 0px;
  }

  .next-btn-create-post{
    width: 100%;
  }
  
}
