.orders-page{
  margin: 20px 0;
  display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-top1-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-new-orders-container {
  height: 60px;
  width: 50%;
  display: flex;
  align-items: center;
  border-radius: 11px;
}
.dashboard-new-orders-count-container {
  padding: 10px 20px;
  height: 100%;
  background-color: #02a750;
  color: #ffffff;
  border-radius: 11px 0px 0px 11px;
}
.dashboard-new-orders-count {
  font-family: Poppins;
  font-size: 29px;
  font-weight: 700;
}
.dashboard-new-orders-and-manage-orders {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #d3f0e1;
  height: 100%;
  align-items: center;
  border-radius: 0px 11px 11px 0px;
}
.dashboard-new-orders-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #2c3142;
}

.order-filter-apply-container{
  display: flex;
  gap: 20px;
}

.dashboard-manage-orders-btn {
  border-radius: 5px;
  border: 1px solid #abd1bd;
  background-color: #ffffff;
  color: #464b59;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
}

.dashboard-toggle-switch {
  position: relative;
  width: 80px; /* Increased width to fit text */
  height: 30px;
  background-color: #cbd5e1;
  border-radius: 15px;
  cursor: pointer;
}

.dashboard-toggle-switch-circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* transition: transform 0.7s ease; */
  transition: left 7s ease, right 7s ease, transform 7s ease; /* Smooth movement */
}
.dashboard-toggle-switch-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}
.dashboard-toggle-switch.open {
  background-color: #2c3142;
}

.dashboard-toggle-state-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px; /* Adjusted font size */
  transition: opacity 0.3s ease;
  width: 100%; /* Ensure text takes the full width */
  text-align: center; /* Center the text */
  color: #ffffff;
  transition: 0.7s ease, left 0.7s ease;
}

.dashboard-toggle-state-text.close {
  left: 10px;
  color: #2c3142;
  opacity: 1;
}

.dashboard-toggle-state-text.open {
  right: 10px;
  opacity: 1;
}

.dashboard-bottom-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.dashboard-bottom-single-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 150px;
  border-radius: 25px;
  background-color: #ffffff;
  text-align: center;
  align-items: center;
  gap: 15px;
  box-shadow: 0px 4px 25px 0px #0000001a;
}

.dashboard-order-img {
  width: 43px;
  height: 43px;

  img{
    height: 100%;
    width: 100%;
  }
}

.dashboard-order-count-and-type{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dashboard-order-count {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  color: #2c3142;
  line-height: 30px;
}
.dashboard-order-type {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  color: #2c3142;
}


@media screen and (max-width: 600px) {
  .orders-page{
    margin: 10px 0;
  }
  
  .dashboard-new-orders-count{
    font-size: 20px;
  }

  .dashboard-top1-container {
    flex-direction: column;
    gap: 10px;
  }
  .dashboard-new-orders-container {
    width: 100%;
    height: 50px;
  }
  .dashboard-bottom-single-container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    text-align: center;
    align-items: center;
    gap: 10px;
  }

  .dashboard-order-img {
    width: 30px;
    height: 30px;
  
    img{
      height: 100%;
      width: 100%;
    }
  }

  .dashboard-order-count{
    font-size: 16px;
    line-height: 24px;
  }
  .dashboard-order-type{
    font-size: 16px;
    line-height: 24px;
  }
  .dashboard-bottom-container {
    /* margin-left: 3vw; */
    width: 100%;
    gap: 10px;
  }

}
