.food-detail-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  outline: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.food-detail-popup .title-close-container {
  display: flex;
  justify-content: flex-end;
}

.title-close-container .close-icon {
  cursor: pointer;
}

.food-detail-popup .food-details-container {
  overflow-y: auto;
  max-height: 80vh;
  margin-top: 10px;
}

.food-details-container .food-item-name-btn {
  display: flex;
  justify-content: space-between;
}

.food-item-name-btn .order-popup-btn {
  padding: 6px 40px;
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--color2);
  background-color: var(--color4);
  border-radius: 6px;
  cursor: pointer;
  outline: none;
}
