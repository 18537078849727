.offer-delete-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 33%;
    height: 200px;
    overflow-y: auto;
    background-color: var(--color2);
    padding: 30px;
    box-shadow: 24px;
    border-radius: 12px;
  } 


  .delete-modal-content {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .btns-delete-modals {
    display: flex;
    justify-content: center;
   align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .offer-text {
    font-size: 20px;
    font-weight: bold;
    color: var(--color1);
    margin-bottom: 10px;
  }

  .confirm-delete-btn {
    height: 35px;
    width: 70px;
    border-radius: 6px;
    cursor: pointer;
    background-color:#e13535;
    color: #ffffff;
    border: none;
  
  }

  .cancel-delete-btn {

    height: 35px;
    width: 70px;
    border-radius: 6px;
    cursor: pointer;
    background-color:#e13535;
    color: #ffffff;
    border: none;

  }