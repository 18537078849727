.tabs-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
}

.icon-and-tabs-container {
  width: calc(100% - 450px);
  margin: 0 250px 0 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: fixed;
  top: 80px;
  background-color: #ffffff;
  padding: 10px;
  /* height: 70px; */
  z-index: 1;
}

.tab-content-search-overlay-fake {
  width: calc(100% - 450px);
  margin: 0 250px 0 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-and-text-container-search {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  border: 1px solid var(--search-overlay-food-border);
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #ffffff;
  justify-content: center;
}

.icon-and-text-container-search:hover {
  background-color: #eeedeb;
}

.food-icon-search-overlay-search {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.text-search-overlay-tab {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  color: var(--color1);
  cursor: pointer;
}

@media only screen and (max-width: 980px) {
  .tabs-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-and-tabs-container {
    width: calc(100% - 200px);
    margin: 0 0 0 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .tab-content-search-overlay-fake {
    width: calc(100% - 200px);
    margin: 0 0 0 200px
  }
}

@media only screen and (max-width: 600px) {
  .icon-and-tabs-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    margin-top: -10px;
    gap: 11px;
  }

  .tab-content-search-overlay-fake {
    width: 100%;
    margin-left: 0vw;
  }
  .icon-and-text-container-search {
    /* width: 100px; */
    width: calc(33% - 10px);
    display: flex;
    gap: 5px;
    align-items: center;
    border: 1px solid var(--search-overlay-food-border);
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  .food-icon-search-overlay-search {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .text-search-overlay-tab {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 500;
    color: var(--color1);
    cursor: pointer;
  }
}
