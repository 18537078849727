.delivered-main {
  border-radius: 6px;
}

.delivered-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.delivered-popup-cross-icon {
  margin-left: 550px;
  z-index: 1;
  cursor: pointer;
}

.delivered-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;

}

.delivered-popup {
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}


.delivered-single-order {
  display: flex;
  height: 50px;
  box-shadow: 0px 0px 5px 0px #2c314224;
  border-radius: 6px;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
}

.delivered-order-details1 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
}
.delivered-order-id {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}
.delivered-order-details2 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.delivered-order-details3 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.delivered-orderid {
  text-align: left;
  align-items: left;
}
.delivered-date-and-time {
  display: flex;
  align-items: center;
  gap: 20px;
}
.status-btn {
  border: none;
  height: 50px;
  width: 140px;
  color: #ffffff;
  background: #02a750;
  font-size: 14px;
  border-radius: 6px;
  align-items: center;
  margin-top: -10px;
}
.status-btn.ready {
  background-color: #2c3142;
}

.status-btn.delivered {
  background-color: #02a750;
}
.status-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.no-data-message {
  height: calc(100vh - 270px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 35px;
}
@media screen and (max-width: 600px) {
  .delivered-order-details3 {
    display: none;
  }
  .delivered-order-details1 {
    font-size: 16px;
    font-weight: 400;
  }
  .delivered-order-details2 {
    font-size: 12px;
  }
}

.delivered-orders-single-container {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  color: #2c3142;
  padding: 10px;
}
.delivered-orders-single-top1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivered-orders-order-id-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}
.delivered-orders-order-id {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}
.delivered-orders-order-date-and-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.delivered-orders-order-time {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.delivered-orders-order-date {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}
.delivered-orders-dotted-line {
  border: 1px #d9d9d9;
  border-style: dotted;
  margin-top: 10px;
}
.delivered-order-items-container {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  margin-left: 10%;
  justify-content: space-between;
}
.delivered-order-items {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: calc(50%);
  box-sizing: border-box;
  margin-top: 10px;
}
.delivered-order-bottom-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.delivered-order-bottom-container1 {
  display: flex;
  gap: 15px;
  align-items: center;
}

.delivered-order-status-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.delivered-orders-delivered-circle {
  background-color: #02a750;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.delivered-order-price-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
}
/* .vendor-orders-declined-circle {
  background-color: #e13535;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
} */

@media screen and (max-width: 600px) {
  .delivered-orders-single-container {
    width: 100%;
    margin-right: 5px;
  }
}

