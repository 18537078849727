.add-button {
    background-color: #e13535;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    font-size: 14px;
    outline: none;
  }
  