/* omf logo and title css  */

.vendor-register-menu-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: var(--color1);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.vendor-register-sidebar-scrollable {
  overflow-y: scroll;
  scrollbar-width: none;
}

.vendor-register-omf-container {
  width: 200px;
  height: 82px;
  margin-top: 250px;
  background-color: var(--omf-container-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
}
.vendor-register-omf-svg-logo {
  width: 45.61px;
  height: 41.26px;
}
.vendor-register-omf-svg-title {
  width: 100px;
  height: 29.98px;
  margin-top: 14px;
}

/* menu list (home,reels,post,cart) css */

.vendor-register-menu-items {
  display: flex;
  flex-direction: column;
  margin-top: 140px;
}

ul li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.vendor-register-list-container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  height: 50px;
}

.vendor-register-list-svg-icon {
  margin-left: 20px;
  cursor: pointer;
  position: relative;
}

.vendor-register-list-text {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  color: var(--list-text-color);
  cursor: pointer;
}

@media only screen and (min-width: 601px) {
  .active {
    background-color: var(--color3);
    /* height: 50px; */
    border-right: 5px solid #e13535;
  }
  
}

@media only screen and (max-width: 600px) {
  .vendor-sidebar-scrollable {
    overflow-y: hidden;
  }
  .vendor-register-menu-container {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .vendor-register-menu-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 0px;
    margin-top: 0;
    padding: 15px;
    margin-left: -20px;
  }

  .vendor-register-text-container {
    display: none;
  }
  .vendor-register-list-text {
    display: none;
  }

  .vendor-register-active {
    background: none;
  }
  .vendor-registration-right-bar-main {
    display: none;
  }
}
