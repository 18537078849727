.reel-share-container{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-horizantal-line {
  display: none;
}
.share-style {
  width: 400px;
  background-color: var(--color2);
  padding: 20px;
  box-shadow: 24px;
  border-radius: 12px;
}

.share {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.button {
  width: 40px;
  height: 40px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.img {
  width: 100%;
  height: 100%;
}

.copy-text-container {
  width: 100%;
  border: 1px solid lightgray;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  border-radius: 4px;
}
.copy-input {
  width: 70%;
  height: 50%;
  margin-top: 10px;
}
.copy-button {
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .share-style {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 0;
    width: 100%;
    height: 197px;
    padding: 15px;
    border-radius: 20px 20px 0 0;
  }
}
