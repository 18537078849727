.menu-deletepopup-delete-text {
  font-family: Poppins;
  font-size: 27px;
  font-weight: 600;
  color: #444444;
  text-align: center;
}
.menu-deletepopup-small-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  color: #464b59;
  text-align: center;
}
.menu-deletepopup-btns-container {
  display: flex;
  gap: 20px;
}
.menu-deletepopup-cancel-btn {
  background-color: #f1f1f1 !important;
  width: 180px !important;
  height: 60px !important;
  color: #1e293b !important;
}
.menu-deletepopup-delete-btn {
  background-color: #e13535 !important;
  width: 180px !important;
  height: 60px !important ;

  color: #ffffff !important;
}

.menu-deletepopup-btn.active {
  background-color: none;
}

@media screen and (max-width: 600px) {
  .menu-deletepopup-cancel-btn {
    width: 40vw !important;
  }
  .menu-deletepopup-delete-btn {
    width: 40vw !important;
  }
}
