@media only screen and (max-width: 600px) {
  .style1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 530px;
    background-color: var(--color2);
    padding: 15px 0 0;
    box-shadow: 24px;
    border-radius: 12px;
    transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;

  }

  .style1.animate {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }

  .style1.close {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
  }

  .css-mhc70k-MuiGrid-root{
    padding-left: 50px;
  }

  .css-ahj2mt-MuiTypography-root{
    width: 200px;
  }
}