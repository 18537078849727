.cooking-inst-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: var(--color2);
  padding: 16px;
  box-shadow: 24px;
  border-radius: 12px;
}

@media only screen and (max-width: 600px) {
  .cooking-inst-modal {
    width: 90%;
    background-color: var(--color2);
    box-shadow: 24px;
    border-radius: 20px;
  }
}
