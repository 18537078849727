.qr-code-main-container {
  background-color: var(--blk-color5);
  height: 100vh;
  overflow-y: hidden;
}

.qr-top-container {
  background-color: var(--color1);
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.qr-back-svg-and-text {
  display: flex;
  gap: 20px;
}
.scan-qr-code-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--color2);
}

.qr-reader {
  width: 430px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  margin-top: 10%;
}

.qr-reader video {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: 50px;
  padding: 20px;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.qr-cancel-btn {
  background-color: var(--color4);
  width: 90%;
  height: 47px;
  color: var(--color2);
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  margin-left: 5%;
  margin-top: 80px;
}

.gallery-img-svg path {
  fill: #ffffff;
}
.gallery-img-svg {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
}
/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}
