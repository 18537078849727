.menu-Model-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color2);
  padding: 20px;
  box-shadow: 24px;
  border-radius: 12px;
} 

.add-menu-popup {
  position: absolute;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
  background-color: white;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  height: 145px;
}

.menu-add-menu-popup-cross-icon {
  margin-left: 370px;
  z-index: 1;
  cursor: pointer;
}

.add-edit-menu-close-icon{
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.menu-add-menu-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c3142cc;
}

.add-menu-name-input{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.add-menu-field-txt {
  font-family: Poppins;
  text-align: left;
  color: #444444;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}
.add-menu-popup input,
.add-menu-popup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #e13535 !important;
}
.add-menu-popup-next-btn {
  height: 50px !important;
  background-color: #e13535 !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.add-menu-popup textarea {
  height: 80px;
  resize: vertical;
  outline: none;
}
@media screen and (max-width: 600px) {
  .add-menu-popup {
    width: 90vw;
  }

  .menu-add-menu-popup-cross-icon {
    margin-left: 83vw;
  }

  .menu-Model-container {
    width: 90vw;
    padding: 0px;  
  } 

  .menu-add-menu-popup-cross-icon {
    margin-top: 10px;
    margin-left: 380px;
    z-index: 1;
    cursor: pointer;
  }
}
