.comments-main-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
}

.comment-and-crossIcon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cross-icon {
  cursor: pointer;
}

.comment-input-container {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.comment-profile-icon {
  width: 30px;
  height: 30px;
}

.comment-input {
  outline: none;
  border: none;
  background-color: var(--bg-color2);
  height: 30px;
  padding: 5px;
  border-radius: 20px;
  text-indent: 10px;
  width: 100%;
}
.comment-post-button {
  height: 30px;
  min-width: 80px !important;
}

.no-comment{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-comments-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
}

.user-comments-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-comment-profile-pic {
  width: 30px;
  height: 30px;
}

.user-profile-and-name {
  display: flex;
  gap: 16px;
  align-items: center;
}
.user-name-verified {
  display: flex;
  gap: 2px;
}
.user-comment-time {
  font-size: 11px;
  color: rgb(190, 184, 184);
}
.user-comment {
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-comment-text {
  font-family: poppins;
  font-size: 13px;
  background-color: var(--bg-color2);
  border-radius: 4px;
  width: fit-content;
  text-align: left;
  padding: 4px;
  margin-right: 10px;
}
.user-comment-reply {
  width: 35px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  background-color: var(--bg-color2);
  border-radius: 4px;
  border: none;
  outline: none;
}
.comment-like-svg {
  width: 20px;
  height: 13px;
}
.comment-reply-like {
  display: flex;
  gap: 7px;
  align-items: center;
}

.no-comment-messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.no-comments {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
}

.start-conversation {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
}

.replies {
  margin-top: 380px;
  margin-left: 50px;
}
.replies::-webkit-scrollbar {
  display: none;
}

.user-profile-and-name .three-dot{
  cursor: pointer;
}

.comment-title{
  font-family: var(--font-family) !important;
}

@media only screen and (max-width: 600px) {
  .comments-main-container{
    padding: 16px;
  }

  .comment-title{
    width: 100%;
  }

  .comment-box{
    padding: 0;
  }
}