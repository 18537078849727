.food-container-fake {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 70px;
}
.Food-Nearby-restaurants-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 10px;
}
.Food-search-restaurants-card {
  display: flex;
  gap: 10px;
  flex-direction: column;
  /* border: 2px solid var(--color1); */
  width: 200px;
}
.Food-search-restaurants-image {
  height: 150px;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
}

.Food-search-restaurants-content{
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.Food-search-restaurants-text {
  font-family: Poppins;
  font-size: 14.51px;
  font-weight: 600;
  line-height: 15.01px;
  text-align: left;
  color: var(--restaurant-name-color);
  cursor: pointer;
}
.Food-search-restaurant-footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Food-search-restaurant-dist {
  font-family: Poppins;
  font-size: 10.72px;
  font-weight: 400;
  line-height: 16.08px;
  text-align: left;
  color: #878787;
  cursor: pointer;
}
.Food-dist-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.Food-search-rest-star-icon-image {
  width: 14.72px;
  height: 13.98px;
  object-fit: contain;
}
.Food-search-rest-reviews {
  color: #878787;
  font-family: Poppins;
  font-size: 10.72px;
  font-weight: 400;
  cursor: pointer;
}

.no-data-found {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  color: var(--color1);
  margin-top: 100px;
  text-align: center;
  width: 100%;
}

/* offers*/
.img-and-offer-percentage {
  cursor: pointer;
  position: relative;
  /* z-index: -1; */
}
.offer-percentage {
  width: 100px;
  position: absolute;
  top: 0px;
  right: 0;
  background-color: var(--color4);
  color: var(--bg-color2);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  border-top-right-radius: 8px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .food-container-fake {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .food-container-fake {
    margin-top: 70px;
    margin-left: 0;
    margin-bottom: 100px;
  }

  .Food-Nearby-restaurants-cards-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 10px;
    padding: 0 15px 0 15px;
  }

  .Food-search-restaurants-card {
    display: flex;
    gap: 8px;
    border: none;
    width: calc(50% - 5px);
  }
  .Food-search-restaurants-image {
    height: 130px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
  }
  .Food-search-restaurants-text {
    font-size: 12.51px;
  }

}
