.banner-img-background {
  margin-top: 30px;
  height: 150px;
  width: 100%;
 
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1; /* Ensure the picker is visible */
  pointer-events: auto; /* Allow clicking */
}

.css-14kxyr {
  padding-top: 0 !important;
}

.Tabs-container {
  display: flex;
  width: 100%;
}

/* edit profile */
.editprofile-main-c {
  display: flex;
  flex-direction: column;
  margin-left: 3.5%;
  width: 100%; /* Adjusted width to take up remaining space */
}

.edit-profile-photo{
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e13535;
  font-size: 50px;
  color: #fff;
}

.edit-profile-upload{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.edit-profile-main-c{
  width: 100%;
}

.edit-profile-main-c .edit-profile-bottom{
  margin-top: 10px;
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.edit-profile-top1 {
  background: var(--color2);
  display: flex;
  align-items: center;
}
.edit-profile-text-layout {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.edit-profile-text {
  font-family: Poppins;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: var(--blk-color5);
  font-weight: 600;
  letter-spacing: -0.03em;
}
.msg-icon {
  display: none;
}
.profile-gallary-container {
  width: 130px;
  height: 130px;
  display: flex;
  top: 10px;
}
.profile-photo {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
}
.gallary-container {
  width: 43px;
  height: 43px;
  margin-top: 85px;
  margin-left: 102px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  background-color: var(--color2);
  position: absolute;
}
.gallary-img {
  width: 18px;
  height: 17.14px;
  cursor: pointer;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root p{
  position: absolute;
  left: -10px;
  bottom: -19px;
}


.fall-back-letter-vendor {
  

  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color:#ffffff;
  font-weight: 600;
  letter-spacing: -0.03em;
  text-align: center;



}

/* Restaurant Profile image css*/

.restaurant-data-container {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.restaurant-profile-gallary-container {
  width: 130px;
  height: 130px;
  margin-top: -80px;
  left: 35%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  background-color: #e13535;
  border-radius: 6px;
  color: #ffffff;

}



.restaurant-edit-profile-photo{
  width: 130px;
  height: 150px;
  border-radius: 6px;
  object-fit: cover;
}

.restaurant-gallary-container {
  width: 35px;
  height: 35px;
  margin-top: 90px;
  margin-left: 90px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  background-color: var(--color2);
  position: absolute;

}
.restaurant-gallary-img {
  width: 18px;
  height: 17.14px;
  margin-left: 7px;
  margin-top: 7px;
  cursor: pointer;
}


.restaurant-save-changes-layout{
    margin-bottom: 20px;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.banner-gallary-container {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  background-color: var(--color2);
  position: absolute;
  top: 10px;
  left: 90%;
  z-index: 1;
}
.banner-gallary-img {
  width: 18px;
  height: 17.14px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.person-data-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 15px;
}
.name-container {
  width: 100%;
  display: flex;
  gap: 2%;
}
.firstname-container {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.profile-head-text {
  height: 22px;
  font-family: Poppins;
}

.lastname-container {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.email-container {
  height: 90px;
  width: 96%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.password-container {
  height: 90px;
  width: 96%;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dob-container {
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.username-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.save-changes-button {
  background-color: var(--color4);
  color: #FFFFFF;
  width: 100%;
  height: 50px;
  left: 0%;
}
/*change language*/
.change-language-txt {
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: var(--blk-color5);
  font-weight: 600;
  letter-spacing: -0.03em;
}
/*switch account */

.switch-account-txt {
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: var(--blk-color5);
  font-weight: 600;
  letter-spacing: -0.03em;
}
.MuiRadio-root.Mui-checked {
  color: var(--color4) !important;
}
/* Change password */
.Update-Password-txt-layout {
  width: 100%;
  text-align: center;
}
.ChangePassword-main {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.Update-Password-txt {
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: var(--blk-color5);
  font-weight: 600;
}
.Previous-password-text {
  height: 22px;
  font-family: poppins;
}
.forgot-password-text {
  font-family: poppins;
  font-size: 12px !important;
  color: #3c9afb;
  cursor: pointer;
}
.custom-input:focus {
  outline: none;
  border-color: red;
}
/* Update addresses */
.edit-address-text-layout {
  width: 100%;
  text-align: center;
}
.edit-address-text {
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: var(--blk-color5);
  font-weight: 600;
}
.Saved-Address-text {
  font-family: Poppins;
  font-size: 17px;
  height: 20.4px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: poppins;
}
.dotted-line {
  border-top: dotted 1px;
  color: #5e657e;
  margin-bottom: 20px;
}
.address-cards-container {
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  height: 400px;
}
.address-card {
  margin-bottom: 20px;
  display: flex;
}
.Address-container {
  display: flex;
  gap: 15px;
}
.address-text-container {
  display: flex;
  flex-direction: column;
}
.address-icon {
  width: 17px;
  height: 21.3px;
  margin-left: 21px;
  gap: 0px;
  opacity: 0px;
  margin-top: 0px;
}
.address-name-text {
  width: 240.08px;
  height: 18px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
}
.address-text-layout {
  width: 277px;
  height: 33px;
}
.address-text {
  margin-top: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;

  text-align: left;
  /* color:#AAB2CE; */
  color: #020210;
}
.addr-buttons-layout {
  display: flex;
  width: 122.04px;
  height: 21.96px;
  gap: 10px;
  margin-top: 10px;
}

.addr-button {
  width: 58.61px;
  height: 21.96px;
  border-radius: 6px;
  background-color: var(--bg-color2);
  cursor: pointer;
  border: none;
}
.add-address-button-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}
.add-address-button-container {
  margin-top: 80px;
}
/* edit addr */
.editaddr-layout {
  height: 20.92px;
  margin-left: 108.89px;
  margin-top: 15px;
}
.editaddr-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}
.currlocation-main {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.currlocation-icon {
  width: 18.06px;
  height: 18.06px;
  margin-left: 20px;
}
.use-currloc-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: var(--color6);
}
.addr-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 15px;
}
.DeliveryName-container {
  width: 100%;
}
.DeliveryName-text {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  text-align: left;
}
.textfield-mui {
  min-height: 20px;
  width: 309.92px;
}
.left-panel {
  width: 200px;
  height: auto;
  top: 82px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color:  var(--color2);
  position: fixed;
  height: calc(100vh - 82px);
}

.left-panel-vendor {
  width: 200px;
  height: auto;
  top: 132px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color:  var(--color2);
  position: fixed;
  z-index: 99;
  height: calc(100vh - 82px);
}

.UpdateAddress-main-cont {
  padding: 3%;
  width: 100%;
  margin-left: 16px;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1em !important; /* Set the desired height */
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1em !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root,
.css-10d9dml-MuiTabs-indicator {
  background-color: var(--color4) !important;
  /* background-color: #808080 !important;
    color: #fff; */
}
.css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--color4) !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: 0;
  outline-color: red !important;
  outline-style: initial;
  outline-width: 0px;
}

.popup-layout-editaddr {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: var(--color2) !important;
  border-radius: 15px !important;
  width: 347px !important;
  height: 546px !important;
}
/* .save-changes-layout{
    margin-top: 90px;
} */

.change-password-container {
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;
  text-align: center;

  .heading {
    margin: 10px 0 20px;
    font-size: 25px;
    color: #363636;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5%;
  }

  .form-group {
    margin: 0 0 20px;
    position: relative;

    .label-text {
      display: block;
      text-align: left;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      letter-spacing: 1%;
    }

    input {
      width: 100%;
      padding: 12px 16px;
      border: 1px solid #ddd;
      border-radius: 8px;
      font-size: 14px;
      font-weight:400;
      line-height: 22px;
    }

    .password-container {
      position: relative;
      width: 100%;
      height: auto;

      input{
        padding: 0.75rem 2.4rem 0.75rem 0.75rem;
      }

      .toggle-password {
        position: absolute;
        top: 30%;
        right: 10px;
        cursor: pointer;
        color: #888;
      }
    }

    .input-error {
      border: 1px solid red;
    }
  
    .error-message {
      color: red;
      font-size: 12px;
      position: absolute;
      bottom: -20px; 
      left: 0;
      width: 100%;
      text-align: left;
      padding-left: 4px;
    }
  }

  .change-password-btn {
    display: block;
    width: 100%;
    padding: 11px 0;
    margin-top: 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    line-height: 30px;
    background-color: #E13535;
    color: #fff;
  }

}

.editprofile-main {
  width: calc(100% - 200px);
    margin-left: 200px;
}

@media screen and (min-width: 600px) {
  .switchacc-top1-layout {
    margin-top: -100px;
  }
  .Switch-acc-main {
    margin-top: 100px;
  }
  
  .change-language-main {
    padding-left: 38%;
  }
  .change-language-txt {
    margin-bottom: 80px;
  }
  .sidebar {
    display: none;
  }
  .top-bar {
    display: none;
  }
  .editprofile-main {
    overflow-y: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
  }
  .profile-settings-main {
    width: 100%;
  }
  .profile-settings-sidebar {
    display: initial;
  }
  .left-panel {
    width: 200px;
  }
  .profile-settings-topbar-and-home {
    display: flex;
    flex-direction: column;
  }
  .profile-settings-topbar {
    display: initial;
  }
  .profile-settings-home-and-rightbar {
    display: flex;
    margin-left: 200px;
    margin-top: 82px;
    width: calc(100vw - 200px);
  }
  .profile-settings-home {
    width: 100%;
  }

  .profile-settings-home-vendor{
    width: 100%;
    max-width: 1400px;
  }
  .profile-settings-rightbar {
    display: initial;
    margin-top: 52px;
  }
  .msg-icon {
    display: none;
  }

  .tab-icon-img {
    width: 22px;
    height: 22px;
    object-fit: contain;
  }
  .UpdateAddress-main-cont {
    margin-right: 3.5%;
    padding: 0;
  }
  /*tabs*/
  .left-tabs1 {
    justify-content: flex-start !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 12px !important;
    margin-top: 30px !important;
    color: var(--color1) !important;
    font-size: 12px !important;
    font-family: Poppins !important;
    text-transform: capitalize !important;
    width: 200px !important;
    height: 35px !important;
    min-height: 35px !important;
  }
  .vector-ic {
    display: none;
  }
  .vector-ic1 {
    display: none;
  }
  .vector-ic2 {
    display: none;
  }
  .cross-icon-img path {
    fill: black;
    cursor: pointer;
  }
  .topofmodal {
    display: flex;
    gap: 70px;
  }
  .editaddr-layout {
    display: flex;
    gap: 20px;
  }
  .ico1 {
    display: none;
  }
}
@media screen and (min-width: 980px) {
  .profile-settings-home {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    justify-content: center !important;
    text-align: center !important;
  }
  .MuiTabs-root.MuiTabs-vertical.Tabs-css.css-1g4ac1o-MuiTabs-root {
    width: 100%;
    align-items: center;
  }
  .edit-address-text {
    margin-left: 25%;
    text-align: left;
  }
  .profile-gallary-container {
    margin-left: -10px;
  }
  .top-bar {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
  }
  .css-aym9vq-MuiButtonBase-root-MuiTab-root :active {
    background-color: #3c9afb !important;
  }
  .switch-account-txt {
    margin-left: 20%;
  }

  .Update-Password-txt{
    margin: 20px 0;
  }
  .change-password-container{
    padding: 15px;
  }
  .switchacc-top1-layout {
    display: flex;
    margin-left: 10px;
    margin-top: 140px;
    align-items: center;
    text-align: center;
  }

  .add-address-button-container {
    width: 80% !important;
    margin-left: 10% !important;
  }
  .cross-icon-img path {
    fill: black;
    cursor: pointer;
  }

  .edit-profile-text-layout {
    margin: 20px 0;
  }
  .topofmodal {
    display: flex;
    gap: 70px;
  }
  .editaddr-layout {
    display: flex;
    gap: 20px;
  }
  
  .edit-profile-top1{
    padding: 0 15px;
  }
  .profile-settings-topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .top-bar {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
  }
  .profile-settings-sidebar {
    display: initial;
  }

  .Tabs-css {
    border-right: none;
  }

  .left-panel {
    width: 100vw; /* Occupy full viewport width */
    max-width: 100%; /* Ensure it doesn't exceed viewport width */
    padding-bottom: 5px;
    display: flex;
    align-items: center !important;
    overflow-y: scroll;
  }

  .edit-profile-text {
    color: var(--blk-color5);
    margin-left: -50px;
  }

  .edit-profile-main-c {
    margin-top: 70px;
    width: 100%;
  }
  .profile-settings-home {
    margin-bottom: 100px;
    margin-top: 15px;
  }
  .update-pass-top {
    display: flex;
    align-items: center;
    padding: 0 15px4;
  }
  .change-lang-top1 {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .update-password-cont {
    margin-top: 70px;
  }
  .edit-address-top-layout-1 {
    display: flex;
    align-items: center;
    margin-top: -10px;
  }
  .editaddr-layout {
    padding: 0;
  }

  .change-language-main {
    margin-bottom: 90px;
    margin-top: 140px;
  }
  .fieldset1 {
    padding-top: 10px;
    margin-left: 110px;
  }
  .Switch-acc-main {
    margin-bottom: 90px;
    padding-left: 110px;
    margin-top: 5%;
    width: 355px;
    display: flex;
    flex-direction: column;
  }
  .UpdateAddress-main-cont {
    /* margin-bottom: 90px; */
    margin-top: 70px;
    width: 100%;
    margin-left: 0;
  }
  .left-panel {
    margin-top: 0px;
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color2);
  }

  .change-language-txt {
    margin-left: 60px;
  }
  .tab-icon-img {
    width: 22px;
    height: 22px;
  }
  .left-tabs1 {
    justify-content: flex-start !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 20px !important;
    color: var(--color1) !important;
    font-size: 16px !important;
    font-family: Poppins !important;
    text-transform: capitalize !important;
    min-height: 35px !important;
    border-top: 1px dotted var(--border-color) !important; 
    max-width: 100% !important;
    padding: 15px 30px 15px 65px !important;
  }
  
  .confirm-but {
    width: 70% !important;
  }
}
