.menu-main-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 130px;
  }

  .menu-main-container .all-menu-item{
    padding: 16px;
  }

  .restaurant-common-filter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .menu-main-container .vendor-filter-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }

  .vendor-filter-container .vendor-filter{
    border-radius: 22px;
    text-transform: none;
    font-weight: bold;
    background-color: #FFFFFF;
    color: #50555C !important;
    height: 35px;
    border: #000;
    box-shadow: 0px 0px 4px 0px #0000001A;
    width: 90px;
  }

  .menu-bottom-container{
    margin-bottom: 20px;
  }
  
  .menu-bottom-container .menu-starter-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #2c3142;
  }
  .menu-bottom-container .menu-starter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
    justify-content: flex-start;
  }
  .menu-starter-container .menu-item {
    display: flex;
    min-width: calc(33% - 10px);
    height: 150px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px 0px #00000017;
    cursor: pointer;
  }
  .menu-item .menu-starters-image {
    width: 30%;
    border-radius: 5px 0px 0px 5px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px 0px 0px 5px;
    }
  }
  .menu-item .menu-description-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 70%;
    overflow: hidden;
  }
  .menu-description-container .heading-container {
    display: flex;
    gap: 10px;
    text-align: center;
    align-items: center;
  }
  .heading-container .menu-item-head-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 700;
    color: #2c3142;
  }
  .menu-description-container .menu-description {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    color: #464b59;
    display: flex;
    flex-direction: column;
  }
  .menu-description-container .menu-more-heading {
    color: #2c3142;
    font-size: 11px;
    cursor: pointer;
  }
  .menu-description-container .menu-item-bottom-container {
    display: flex;
    justify-content: space-between;
  }
  .menu-item-bottom-container .menu-item-price-text {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: -0.03em;
    text-align: left;
    color: #2c3142;
  }
  
  @media screen and (max-width: 600px) {
    .menu-bottom-container .menu-starter-text {
      margin-top: 0;
    }
    .menu-main-container{
      margin-top: 175px;
    }
  }

  @media screen and (max-width: 1116px) {
    .menu-starter-container .menu-item { 
      min-width: calc(50% - 10px);
    }
  }
  
  @media screen and (max-width: 758px) {
    .menu-starter-container .menu-item { 
      min-width: calc(100% - 10px);
    }
  }