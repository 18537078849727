.address-title{
  font-family: var(--font-family) !important;
}

.address-container-in-model {
  width: 100%;
  height: 48px;
  background-color: var(--bg-color3);
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}
.button-container {
  display: flex;
  gap: 12px; /* Space between Edit and Delete buttons */
  margin-left: auto; /* Push buttons to the right */
}
/* Edit Button - Professional Green */
.edit-btn {
  padding:  8px;
  border: none;
  cursor: pointer;
  background-color: #28a745; /* Green */
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.edit-btn:hover:not(:disabled) {
  background-color: #218838; /* Darker Green on hover */
}

.edit-btn:disabled {
  background-color: #98c9a3; /* Dull Green */
  cursor: not-allowed;
  opacity: 0.6;
}

/* Delete Button - Professional Red */
.delete-btn {
  padding: 3px 8px;
  border: none;
  cursor: pointer;
  background-color: #dc3545; /* Red */
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.delete-btn:hover:not(:disabled) {
  background-color: #c82333; /* Darker Red on hover */
}

.delete-btn:disabled {
  background-color: #e6a6a6; /* Dull Red */
  cursor: not-allowed;
  opacity: 0.6;
}

.button-box{
  justify-content: space-between;
}

.delivery-location-home-desc-container {
  display: flex;
  gap: 10px;
}
.location-svg-icon-adress path {
  width: 24px;
  height: 30px;
}

.delivert-to-home-desc-container {
  display: flex;
  flex-direction: column;
}
.delivery-to-home-text {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: var(--color2);
}
.delivery-to-home-address {
  font-family: var(--font-family);
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  color: var(--text-color);
}

.current-location-icon-text {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.location-svg-adress-list path {
  width: 14px;
  height: 14px;
  fill: black;
}
.current-location-text {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: var(--color6);
}

.save-address-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.address-list-main-container {
  background-color: var(--bg-color2);
  padding: 8px;
  border-radius: 10px;
  margin-top: 10px;
  height: 300px;
}
.address-list-title-container {
  display: flex;
  justify-content: space-between;
}

.saved-address {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.add-new-address-btn {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  background-color: var(--bg-color2);
  border: none;
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.address-list-scrollable {
  overflow-y: auto;
  min-height: 250px;
  height: 100%;
}

.address-type-container {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.address-checkbox {
  width: 25px;
  height: 25px;
  border-radius: 1px;
}
.address-type {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
}

.address-type-address {
  font-family: var(--font-family);
  font-size: 11px;
  font-weight: 400;
}

.update-delivery-address {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  background-color: var(--color4);
  color: var(--color2);
  border: none;
  border-radius: 6px;
  padding: 6px;
  /* position: absolute;
bottom: 20px; */

  margin-top: 10px;
  width: 100%;

  cursor: pointer;
}
.update-delivery-address:disabled {
  background-color: #b0b0b0; /* Ash/Dull Grey */
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.6;
}
input[type="checkbox"]:checked {
  accent-color: var(--color6);
}

.cart-component {
  display: none;
}
