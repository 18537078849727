.title-cross-svg-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.add-new-address-title {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: var(--color1);
}

.delivery-name-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
}

.name-label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
}

.add-address-input {
  height: 40px;
  outline: none;
  border: 1px solid #d3d4d4;
  border-radius: 4px;
  text-indent: 5px;
}

.add-address-btn {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  background-color: var(--color4);
  color: var(--color2);
  border: none;
  border-radius: 6px;
  padding: 6px;
  /* position: absolute;
    bottom: 20px; */

  margin-top: 30px;
  width: 100%;

  cursor: pointer;
}

.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px; 
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 4px;
}

@media only screen and (max-width: 600px) {
  .add-new-address-title {
    margin-left: 80px;
  }
}
