.tabs-content-and-carousel-container {
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0 0 200px;
  width: calc(100% - 200px);

  @media screen and (min-width : 981px) {
    margin: 100px 250px 0 200px;
    width: calc(100% - 450px);
  }

  @media screen and (max-width: 600px) {
    margin: 82px 0 10px 0;
    width: 100%;    
  }
}

.particular-menu-item-image{
  height: 300px;
  width: 500px;

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  @media screen and (max-width: 700px) and (min-width: 601px) {
    height: 300px;
    width: 400px;    
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    padding: 0 10px;    
  }
}
.tabs-and-content-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.tabs {
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.tab1 {
  width: 180px;
  height: 60px;
  background-color: #393f54;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #878faf;
  border: none;
  cursor: pointer;
  border-radius: 16px 0 0 0;
}
.tab-active1 {
  width: 180px;
  height: 60px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--color2);
  color: var(--color1);
  cursor: pointer;
  border-radius: 16px 0 0 0;
}

.tab2 {
  width: 180px;
  height: 60px;
  background-color: #393f54;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #878faf;
  border: none;
  cursor: pointer;
  border-radius: 0 16px 0 0;
}
.tab-active2 {
  width: 180px;
  height: 60px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--color2);
  color: var(--color1);
  cursor: pointer;
  border-radius: 0 16px 0 0;
}

.svg-and-title {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.svg-icon-classs{
  height: 20px;
  width: 20px;

  img{
    height: 100%;
    width: 100%;
  }
}

.food-name {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  color: var(--color1);
}

.food-description {
    width: 90%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.ingridents-heading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: var(--color1);
}

.ingrediants-description{
  width: 90%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.star-rating-particular-food{
  display: flex;
  gap: 5px;
  align-items: center;
}

.star-img-food-desc {
  width: 19px;
  height: 16px;
}
.star-rating-container {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
}

.food-desc-rating-text {
  font-family: Poppins;
  font-weight: 400;
  color: var(--color1);
}

.food-desc-reviews {
  font-family: Poppins;
  font-weight: 500;
  color: var(--color1);
}

.tab-content{
  display: flex;
  justify-content: center;
}